import { defineStore } from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import CountryData from "@/store/country-data";
import { useLK } from "@/controllerLK/LKstore";

const countryCrud = new Crud(
  environment.appConfig.baseApiUrl + ":requestType/byCountry/:countryCode",
  "id",
  "",
  {}
);

const countryData = new CountryData();

export const useCountryMilitaryTradeStore = defineStore({
  id: "countryMilitaryTrade",
  state: () => ({
    ...countryCrud.state,
    ...countryData.state,
  }),
  getters: {
    ...countryCrud.getters,
    ...countryData.getters,
  },
  actions: {
    ...countryCrud.actions,
    ...countryData.actions,
    async loadTrade() {
      const LKStore = useLK();
      return await this.loadCountryData({
        requestType: "MilitaryTrade",
        countryCode: LKStore.project.countryId,
      });
    },
  },
});
