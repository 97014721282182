export async function createSelectData(data){
    console.log(data)
    const keys = Object.keys(data)
    const optionsGlobalType = [];
    const optionsCurrentType = {};
    for(let i = 0;i<keys.length;i++){
        optionsGlobalType.push({label:keys[i], value:keys[i]})
        optionsCurrentType[keys[i]] = []
        const currents = Object.keys(data[keys[i]])
        console.log(data[keys[i]])
        for(let j = 0;j< currents.length;j++){
            // console.log({label:data[keys[i]][currents[j]], value: currents[j] })
            console.log(currents[j], currents)
            optionsCurrentType[keys[i]].push({
                label:   data[keys[i]][currents[j]].translatedFieldName, 
                value:   data[keys[i]][currents[j]].fieldName, 
                reverse: data[keys[i]][currents[j]].revertColor})
        }
    }
    return {
        optionsGlobalType,
        optionsCurrentType,
    }
}