<template>
  <div class="h-100 p-3">
    <CountryDashboardFilters />
  </div>
</template>

<script>
import { computed } from "vue";
import { useCountryNewsStore } from "@/store/country-news";
import { useCountryNewsRubricsStore } from "@/store/country-news-rubrics";
import { routesGlobus as router } from "@/router/globus-router";
import CountryDashboardFilters from "@/components/MapPage/CountryDashboardView/CountryDashboardFilters.vue";

export default {
  name: "CountryDashboardNewsBlock",
  props: ["quantityNews"],
  emits: ["selectNews"],
  components: { CountryDashboardFilters },

  setup() {
    const newsStore = useCountryNewsStore();
    const rubricsStore = useCountryNewsRubricsStore();
    const newsSortByTime = computed(() => {
      const sortedArray = [...newsStore.dashboardNews];
      return sortedArray.sort((a, b) => {
        return b.date - a.date;
      });
    });

    function gotoNews(newsItem) {
      rubricsStore.selectedId = newsItem.rubricId;
      // router.push({ name: "Country.News", query: { id: newsItem.id } });
      router.push('/news');
    }

    return {
      gotoNews,
      newsSortByTime,
    };
  },
};
</script>

<style scoped></style>
