<script setup>
import { defineProps } from "vue";

const props = defineProps({
  modelValue: Array,
  value: String,
});
</script>
<template>
  <slot>
    <input
      @change="$emit('update:modelValue', $event.target.value)"
      type="checkbox"
      class="inp"
      :value="props.value"
    />
  </slot>
  <span class="checkbox"></span>
</template>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.inp {
  width: 0;
  height: 0;
  border: none;
  opacity: 0;
}

.inp:checked + .checkbox {
  border: none;
  color: $white;
  background: $blue-main;
  font-size: 0.556vw;

  &::after {
    font-family: "icomoon", sans-serif !important;
    content: "\e903";
    line-height: 0;
    position: relative;
    //top: 0.069vw;
  }
}

.checkbox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  background: $white;
  flex: 0 0 16px;
}
</style>