import { defineStore } from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import CountryData from "@/store/country-data";

// 1021303170
const crud = new Crud(
  environment.appConfig.baseApiUrl + "Stats/regions/",
  "id",
  "",
  {}
);
const countryData = new CountryData();

export const useCountryStatRubricsStore = defineStore({
  id: "countryStatRegionsStore",
  state: () => ({
    ...crud.state,
    ...countryData.state,
  }),
  getters: {
    ...crud.getters,
    ...countryData.getters,
  },
  actions: {
    ...crud.actions,
    ...countryData.actions,
    async loadStat(rubricId) {
      this.endpoint = environment.appConfig.baseApiUrl + "Stats/regions/",
      this.endpoint += rubricId
      return await this.loadCountryData({
        clear: true,
      });
    },
  },
});
