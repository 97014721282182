<template>
    <div>

    </div>
</template>
<script setup>
import Feature from 'ol/Feature.js';
import Polygon from 'ol/geom/Polygon.js';
import MultiPolygon from 'ol/geom/MultiPolygon.js';
import { Fill, Stroke, Style} from 'ol/style.js';
import { onMounted, defineEmits, defineProps } from 'vue';
import { useCountryStatDemographyStore } from "@/store/country-stat-demography";
const props = defineProps({
  data: {
    type:Object,
  },
  type:{
    type:String
  }
}); 
const useStoreDemography = useCountryStatDemographyStore()
const emit = defineEmits(['set-feature','set-change-feature'])
onMounted(()=>{
// console.log(props.data.geometry.coordinates.length)
const feature = new Feature({
  geometry: props.data.geometry.coordinates.length === 1 ? new Polygon(props.data.geometry.coordinates) : new MultiPolygon(props.data.geometry.coordinates),
  name:props.data.id,
});
function getColor(){
  if(useStoreDemography.activeViewGroupColor){
    if(props.data.properties.COLOR === useStoreDemography.activeViewGroupColor) return props.data.properties.COLOR 
    return 'white'
  } else {
    return props.data.properties.COLOR ? props.data.properties.COLOR  : 'white'
  }
}
feature.setStyle( new Style({
      stroke: new Stroke({
        color: 'rgba(174, 174, 174, 1)',
        width: 1,
      }),
      fill: new Fill({
        color: getColor() ,
      }),
    }),)
if(props.type === 'change'){
  emit('set-change-feature', feature)
} else {
  emit('set-feature', feature)
}

})

</script>

<style>

</style>