<script setup>
import { useRubricsStore } from "@/store/NewsFeed/rubrics";
import { ref } from "vue";
import ListItem from "@/components/Chunks/ListItem.vue";
import { useNewsFeedStore } from "@/store/NewsFeed/newsFeed";

const newsFeedStore = useNewsFeedStore();
const rubricsStore = useRubricsStore();
const isOpenList = ref(false);

const getNews = async (rubric) => {
  newsFeedStore.clearNewsFeed();
  rubricsStore.currentRubric = rubric;
  isOpenList.value = false;
  await newsFeedStore.getRubricNews();
};
</script>

<template>
  <div class="rubrics-news" v-click-outside="() => (isOpenList = false)">
    <div @click="isOpenList = !isOpenList" class="rubrics-news__container br">
      <template v-if="rubricsStore.currentRubric?.name">
        {{ rubricsStore.currentRubric.name }}
      </template>

      <span class="icon-new-arrow"></span>
    </div>

    <ul v-if="isOpenList" class="rubrics-news__list br">
      <li
        v-for="rubric in rubricsStore.items"
        @click="getNews(rubric)"
        :key="rubric.id"
        class="rubrics-news__item"
      >
        <ListItem :data="rubric.name" />
      </li>
      <li>
        <button class="rubrics-news__button button">К рубрикам</button>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";

.rubrics-news {
  position: relative;
  font-size: 16px;

  &__container {
    background: $white;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 15px;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.3s;
    font-size: 16px;
    &:hover {
      background: $grey-light;
    }

    & > span {
      font-size: 8px;
      color: #c4c4c4;
    }
  }

  &__list {
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    background: $white;
    z-index: 5;
    box-shadow: 1px 2px 10px 0 #131e2726;
    padding: 6px;
  }

  &__item {
    cursor: pointer;
    margin: 0 0 4px 0;
  }

  &__button {
    width: 100%;
    text-align: center;
    height: 38px;
  }
}
</style>
