<template>
  <div class="charts-sections country-analytics-main" :key="LK.project.id">
    <section class="news-tone row-section">
      <div class="rubrics-tone half-panel">
        <domain-blocks-stat-bar />
      </div>

      <div class="main-tone half-panel">
        <domain-rubrics-stat-bar />
      </div>
    </section>

    <section class="rubrics-dynamic row-section">
      <div class="panel h-auto">
        <blocks-dynamic-chart />
      </div>
    </section>

    <section class="news-tone row-section">
      <div class="rubrics-tone panel">
        <rubric-stat-regions/>
      </div>
    </section>

    <section class="news-tone row-section">
      <div class="rubrics-tone panel">
        <analytics-regions-rubrics/>
      </div>
    </section>

    <section class="rubrics-dynamic row-section">
      <div class="panel h-auto">
        <rubric-dynamic-chart />
      </div>
    </section>

    <section class="news-tone row-section">
      <div class="rubrics-tone half-panel">
        <common-blocks-stat-bar />
      </div>

      <div class="main-tone half-panel">
        <common-rubrics-stat-bar />
      </div>
    </section>
  </div>
</template>

<script>
import RubricDynamicChart from "@/components/MapPage/CountryAnalyticsView/RubricsDynamicChart";
import BlocksDynamicChart from "@/components/MapPage/CountryAnalyticsView/BlocksDynamicChart";
import CommonRubricsStatBar from "@/components/MapPage/CountryAnalyticsView/CommonRubricsStatBar";
import CommonBlocksStatBar from "@/components/MapPage/CountryAnalyticsView/CommonBlocksStatBar";
import DomainBlocksStatBar from "@/components/MapPage/CountryAnalyticsView/DomainBlocksStatBar";
import DomainRubricsStatBar from "@/components/MapPage/CountryAnalyticsView/DomainRubricsStatBar";
import RubricStatRegions from '@/components/MapPage/CountryAnalyticsView/RubricsStatRegions'
import AnalyticsRegionsRubrics from '@/components/MapPage/CountryAnalyticsView/AnalyticsRegionsRubrics'
import { useLK } from "@/controllerLK/LKstore.js";

export default {
  name: "CountryAnalyticsMain",
  components: {
    DomainRubricsStatBar,
    DomainBlocksStatBar,
    CommonBlocksStatBar,
    CommonRubricsStatBar,
    BlocksDynamicChart,
    RubricDynamicChart,
    RubricStatRegions,
    AnalyticsRegionsRubrics
  },
  props: ["countryCode"],
  setup() {
    const LK = useLK();

    return{
      LK
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
