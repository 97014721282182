<script setup>
import { ref, watch, defineProps, defineEmits, onMounted, computed } from 'vue'
import CustomCheckbox from "@/components/Chunks/CustomCheckbox.vue";

const props = defineProps({
    modelValue: {
        type: [Array, String, Number],
        required: true
    },
    items: {
        type: Array,
        required: true
    },
    activeItems: {
        type: Array,
        required: false
    },
    activeItem: {
        type: String,
        required: false
    },
    isActive: {
        type: Boolean,
        required: true
    },
    title: {
        type: String,
        required: false,
        default: 'Выбор'
    },
    type: {
        type: String,
        required: false
    }
})

const emit = defineEmits(['update:modelValue', 'update:isActive'])

let checkedItems = ref();

const toggleDropdown = () => {
    emit('update:isActive', !props.isActive);
};

const closeDropdown = () => {
    emit('update:isActive', false);
};

const handleClickSingleItem = (el) => {
    emit('update:modelValue', el.value);
}

const isSelected = computed(() => {
    if(props.type === 'single') {
        return props.modelValue
    } else {
        return props.modelValue.length
    }
})

watch(checkedItems, (newCheckedItems) => {
    emit('update:modelValue', newCheckedItems);
});

onMounted(() => {
    if (Array.isArray(props.modelValue)) {
        checkedItems.value = [...props.modelValue]
    }
    if (typeof props.modelValue === 'string') {
        checkedItems.value = props.modelValue
    }
})
</script>

<template>
    <div class="dropdown"
        :class="{active: props.isActive, selected: isSelected}"
        v-click-outside="closeDropdown" 
    >
        <div class="dropdown__title" @click="toggleDropdown">
            {{ props.title }}
            <span class="icon-arrow"></span>
        </div>
        <div class="dropdown__list"
            v-if="props.type === 'single'"
        >
            <div class="dropdown__list-item single" v-for="(item, index) in items" :key="index"
                :class="{active: activeItem === item.value}"
                @click="handleClickSingleItem(item)"
            >
                <div class="text">
                    {{ item.text }}
                </div>
            </div>
        </div>
        <div class="dropdown__list"
            v-else
        >
            <label class="dropdown__list-item" v-for="(item, index) in items" :key="index"
                :class="{active: props.activeItems.includes(item.text)}"
            >
                <CustomCheckbox>
                    <input
                        v-model="checkedItems"
                        :value="item"
                        type="checkbox"
                        class="hide-inp inp"
                    />
                </CustomCheckbox>
                <div class="text">
                    {{ item.text }}
                </div>
            </label>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.dropdown {
    width: fit-content;
    position: relative;
    min-width: 200px;
    z-index: 1;
    & .icon-arrow {
        transition: .2s;
        font-size: 15px;
    }
    &.active {
        & .icon-arrow {
            transform: rotate(180deg);
            color: #1f9aff;
        }
        & .dropdown__title {
            border-radius: 8px 8px 0 0;
        }
        & .dropdown__list {
            display: block;
        }
    }
    &.selected {
        &:not(.active) {
            & .dropdown__title {
                border-color: #1f9aff;
            }
        }
    }
    &__title {
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid #E0E0E0;
        padding: 10px 16px;
        font-size: 16px;
        background: #fff;
        cursor: pointer;
        justify-content: space-between;
        max-height: 45px;
    }
    &__list {
        background: #fff;
        // box-shadow: 1px 2px 10px 0 rgba(19, 30, 39, 0.15);
        border: 1px solid #E0E0E0;
        border-radius: 0 0 8px 8px;
        position: absolute;
        left: 0;
        top: calc(100% - 1px);
        width: 100%;
        display: none;
        max-height: 150px;
        overflow-y: auto;
        padding: 10px 8px;
    }
    &__list-item {
        font-weight: 400;
        font-size: 14px;
        line-height: 157%;
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        white-space: nowrap;
        gap: 10px;
        border-radius: 8px;
        &.single {
            &.active {
                background: #E0F0FF;
            }
        }
        &.active {
            color: #1f9aff;
        }
        & .text {
            white-space: wrap;
            text-align: left;
            font-size: 14px;
        }
    }
}

.hide-inp {
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
}
</style>
