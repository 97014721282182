import { defineStore } from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import CountryData from "@/store/country-data";
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import { useLK } from "@/controllerLK/LKstore";

const toaster = createToaster({});
const crud = new Crud(
  environment.appConfig.baseApiUrl + "Files/list/:projectId/Reports",
  "id",
  "",
  {}
);
const countryData = new CountryData();
export const useCountryReportFilesStore = defineStore({
  id: "countryReportFiles",
  state: () => ({
    ...crud.state,
    ...countryData.state,
  }),
  getters: {
    ...crud.getters,
    ...countryData.getters,
  },
  actions: {
    ...crud.actions,
    ...countryData.actions,
    async loadCountryReportFiles() {
      const LKStore = useLK();
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      this.cancelTokenSource = axios.CancelToken.source();
      let url = this.endpoint.replace(":projectId", LKStore.project.id);

      const items = (
        await axios.get(url, {
          cancelToken: this.cancelTokenSource.token,
        })
      ).data;

      this.addItems(items, true);
      this.clearLoading();
      // return await this.loadCountryData({ projectId });
    },
    async uploadFile(partition, projectId, files) {
      console.log(partition);
      const url = `/globus/api/Files/upload/${projectId}/${partition}`;
      await axios
        .post(url, files, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((err) => {
          if (err.response.status === 413) {
            toaster.error(`Слишком большой размер файла`);
          }
          return console.log("Ошибка", err);
        });
    },
    async deleteFile(partition, projectId, fileName) {
      console.log(partition, projectId, fileName);
      const url = `/globus/api/Files/${projectId}/${partition}/${fileName}`;
      console.log(url);
      await axios
        .delete(url)
        .then(() => {
          this.clearData();
        })
        .catch((err) => console.error("Ошибка удаления файла", err));
    },
  },
});
