<template>
  <tr class="report-row">
    <td>
      <div class="photo d-flex gap-1">
        <img
          class="img-fluid"
          width="48"
          height="48"
          src="/img/file-picture.png"
        />
        <div class="name">
          {{ row.originalFilename }}
        </div>
      </div>
    </td>
    <td class="time">
      {{ createdTime }}
    </td>
    <td class="">
      <div class="button-column">
        <a class="load-button" :href="row.uri" target="_blank">
          <i class="icon-download"></i>
          Скачать
        </a>
        <a @click.prevent="deleteItem" class="load-button" target="_blank">
          <i class="icon-trash"></i>
          Удалить
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import { getNewsTime } from "@/tools/date-time-tools";
import { computed } from "vue";
import { useCountryReportFilesStore } from "@/store/country-report-files";
import { useLK } from "@/controllerLK/LKstore";
export default {
  name: "CountryAnalyticsReportTableRow",
  props: ["row"],
  setup(props) {
    const reportFilesStore = useCountryReportFilesStore();
    const LK = useLK()
    const createdTime = computed(() => {
      return getNewsTime(props.row.createdAt);
    });
    const deleteItem = async () => {
      const result = confirm("Удалить отчёт");
      if (result) {
        await reportFilesStore.deleteFile(
          "Reports",
          LK.project.id,
          props.row.filename
        );
        return await reportFilesStore.loadCountryReportFiles({
          projectId: LK.project.id,
        });
      }
      return;
    };
    return { createdTime, deleteItem };
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/vars";
.load-button {
  cursor: pointer;
  font-family: $font-roboto-regular;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid $button-border-color;
  background-color: white;
  color: #808080;
  text-decoration: none;
  min-height: 34px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px;
  &:not(:last-child) {
    margin: 0px 10px 0px 0px;
  }
  &:hover {
    text-decoration: none;
    color: #808080;
  }
  i {
    display: inline-block;
    font-size: 14px;
  }
}
.report-row {
  border-bottom: 1px solid $button-border-color;
  td {
    padding: 10px;
    vertical-align: middle;
  }
}

.button-column {
  text-align: center;
  display: flex;
  height: 100%;
  align-items: center;
}

.time {
  font-size: 14px;
}

.name {
  font-size: 14px;
  padding-left: 5px;
  word-break: break-all;
}

.img-fluid {
  height: fit-content;
}

.photo {
  min-width: 450px;
  align-items: center;
}
</style>
