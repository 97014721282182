<template>
  <div class="filter" ref="filter">
    <div @click="isOpenList = !isOpenList" class="filter__container">
      <img
        src="@/assets/icons/filter-burger.svg"
        alt="icon menu"
        class="filter__menu"
      />
      <span class="filter__text">{{ currentFilter.name }}</span>
      <img
        src="@/assets/icons/arrow.svg"
        alt="icon arrow"
        class="filter__arrow"
      />
    </div>
    <div v-if="isOpenList" class="filter__dropdawn">
      <div class="filter__dropdawn-title">
        <img
          src="@/assets/icons/calendar.svg"
          alt="icon menu"
          class="filter__menu"
        />
        По дате добавления
      </div>
      <ul class="filter__list">
        <li
          v-for="item in props.list"
          :key="item.id"
          class="filter__item"
          :class="{ active: item.id === props.currentFilter.id }"
          @click="
            emit('setFilterRule', item.id);
            isOpenList = !isOpenList;
          "
        >
          <img
            v-if="item.id === props.currentFilter.id"
            src="@/assets/icons/arrow-blue.svg"
            alt=" icon"
          />
          <img v-else src="@/assets/icons/circle.svg" alt=" icon" />
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref } from "vue";

const props = defineProps({
  list: Array,
  currentFilter: Object,
});
const filter = ref(null);
const emit = defineEmits(["setFilterRule"]);
const isOpenList = ref(false);
// const closeModal = (e) => {
//   if (!filter.value.contains(e.target)) {
//     isOpenList.value = false;
//   }
// };
</script>

<style lang="scss" scoped>
@import "src/assets/styles/vars";
@import "@/assets/style/variables.scss";

.filter {
  position: relative;
  display: flex;
  cursor: pointer;

  &__container {
    flex: 1 1 auto;
    display: flex;
  }

  &__menu {
  }

  &__text {
    flex: 1 1 auto;
    text-align: center;
  }

  &__arrow {
  }

  &__dropdawn {
    width: 100%;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    z-index: 1;
    background: white;
    box-shadow: 1px 2px 10px 0px #131e2726;
    padding: 6px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
  }

  &__dropdawn-title {
    background: $blue-another;
    border-radius: 8px;
    min-height: 30px;
    display: flex;
    align-items: center;
    padding: 0px 14px;
    color: $social-link-color;
    margin: 0px 0px 4px 0px;
    font-size: 14px;
  }

  &__menu {
    margin: 0px 4px 0px 0px;
  }

  &__list {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  &__item {
    cursor: pointer;
    min-height: 30px;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 35px;
    color: $social-link-color;
    font-size: 14px;

    &:not(:last-child) {
      margin: 0px 0px 4px 0px;
    }

    & > img {
      flex: 0 0 16px;
      width: 16px;
      height: 16px;
      margin: 0px 7px 0px 0px;
    }

    &.active {
      color: $transparent-btn-color;
    }
  }
}
</style>
