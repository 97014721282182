export const createLegendPlugin = (refValue, style = {}) => {
  return {
    id: "htmlLegend",
    afterUpdate(chart) {
      const ul = getOrCreateLegendList(refValue);
      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }
      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      items.forEach((item, key) => {
        const li = document.createElement("li");
        for (const liItem in style.li) {
          li.style[liItem] = style.li[liItem];
        }
        li.onclick = () => {
          const { type } = chart.config;
          if (type === "pie" || type === "doughnut") {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(
              item.datasetIndex,
              !chart.isDatasetVisible(item.datasetIndex)
            );
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement("span");
        for (const boxSpanItem in style.boxSpan) {
          boxSpan.style[boxSpanItem] = style.boxSpan[boxSpanItem];
          boxSpan.style.background = colorList[key];
        }

        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + "px";
        // Text
        const textContainer = document.createElement("p");
        for (const textItem in style.textContainer) {
          textContainer.style[textItem] = style.textContainer[textItem];
        }
        textContainer.style.textDecoration = item.hidden ? "line-through" : "";
        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);
        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };
};
const getOrCreateLegendList = (refValue) => {
  const legendContainer = refValue;
  let listContainer = legendContainer.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.classList.add("list-legend-theme");
    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export const colorList = [
  "#4ECDC4",
  "#C7F464",
  "#B8E7FC",
  "#75D7A4",
  "#F9C442",
  "#B8E7FC",
  "#FD6A6A",
  "#468FFD",
  "#655AEE",
  "#CD46FD",
  "#9876F9",
  "#5BC0F3",
  "#F58866",
];

export const colorDisabled = "#626262";
