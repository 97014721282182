import Map from 'ol/Map';
import View from 'ol/View';
import { Vector as VectorSource} from 'ol/source.js';
import { Vector as VectorLayer} from 'ol/layer.js';


export async function initMap(ref, feats){

  const view =  new View({
    center: [0, 0],
    zoom: 20,
    maxZoom:28,
    minZoom:20,
  })
  const layer = getLayer(feats)
  const map = new Map({
    layers: [layer],
    target: ref,
    view: view,
  });
  map.removeControl(map.getControls().array_[0])
  map.removeControl(map.getControls().array_[0])
  map.removeControl(map.getControls().array_[0])
  return {map, view, layer}
}

export function getLayer(feats){
  const source = new VectorSource({
    features: feats,
  });
  const layer = new VectorLayer({
    source: source,
  
  });
  return layer
}