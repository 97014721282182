<template>
  <render-panel>
    <div ref="chartJS" :class="{ 'container-theme': chartCustomLegend }">
      <Line
        :key="index"
        :data="chartData"
        :options="chartOptions"
        :plugins="plugins"
      />
      <div :class="{ legend: chartCustomLegend }" ref="legendPanel"></div>
    </div>
  </render-panel>
</template>

<script>
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import RenderPanel from "@/components/MapPage/shared/RenderPanel";
import { createLegendPlugin } from "@/tools/chartCustomLergend";

// import { default as autoColors } from "chartjs-plugin-autocolors";
import { onMounted, ref } from "vue";
import chartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  PointElement,
  LinearScale
);

export default {
  name: "LineChart",
  components: { RenderPanel, Line },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },
    disableDataLabels: {
      type: Boolean,
      default: true,
    },
    chartCustomLegend: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const index = ref(0);
    const legendPanel = ref(null);
    const plugins = ref([]);
    const chartJS = ref();

    const pluginsCollect = () => {
      if (!props.disableDataLabels) {
        plugins.value.push(chartDataLabels);
      }
      if (props?.chartCustomLegend) {
        plugins.value.push(
          createLegendPlugin(legendPanel.value, props.chartCustomLegend)
        );
      }
      index.value = 1;
    };

    onMounted(pluginsCollect);

    return { plugins, legendPanel, chartJS, index };
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.container-theme {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  flex: 1 1 auto;

  & > canvas {
    flex: 0 1 68.89vw !important;
    width: 68.89vw !important;
  }
}

.legend {
  opacity: 1 !important;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
