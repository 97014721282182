import { defineStore } from "pinia";
import axios from "axios";

import environment from "@/environment";
import Crud from "@/store/crud";
import CountryData from "@/store/country-data";


const crud = new Crud(
  environment.appConfig.baseApiUrl + "Rubrics/news/:countryCode",
  "id",
  "",
  {}
);

const countryData = new CountryData();

export const useCountryNewsStore = defineStore({
  id: "countryNews",
  state: () => ({
    ...crud.state,
    ...countryData.state,
    newsPage: 0,
    dashboardNews: []
  }),
  getters: {
    ...crud.getters,
    ...countryData.getters,
  },
  actions: {
    ...crud.actions,
    ...countryData.actions,
    async loadCountryNews({ params }) {

      try {

        const url = `${environment.appConfig.baseApiUrl}Rubrics/news/${params.rubric_ids}/${params.skip}/${params.take}`
        const items = await axios.get(url, null, {})
        
        if(items.data) {
          if(params.dashboardNews) {

            items.data.forEach(el => {
              el.rubricId = params.rubric_ids
            })
            this.addDashboardItems(items.data)
          } else {
            this.addItems(items.data)
          }
        }

        return items
      } catch(err) {
        console.error(err)
      }
        return null
    },
    addDashboardItems(items) {
      this.dashboardNews = this.dashboardNews.concat(items)
    },
    clearDashBoardItems() {
      this.dashboardNews.length = 0
    }
  },
});
