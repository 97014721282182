import { defineStore } from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import CountryData from "@/store/country-data";
import { useLK } from "@/controllerLK/LKstore";

const crud = new Crud(
  // environment.appConfig.baseApiUrl + "Avl/news/rubrics/:countryCode",
  environment.appConfig.baseApiUrl + `Rubrics/project/:projectId`,
  "id",
  "",
  {}
);

const countryData = new CountryData();

export const useCountryNewsRubricsStore = defineStore({
  id: "countryNewsRubrics",
  state: () => ({
    ...crud.state,
    ...countryData.state,
  }),
  getters: {
    ...crud.getters,
    ...countryData.getters,
  },
  actions: {
    ...crud.actions,
    ...countryData.actions,
    async loadCountryNewsRubrics() {
      const LKStore = useLK();
      console.log(LKStore);
      return await this.loadCountryData({
        countryCode: "LKStore.project.id",
        clear: true,
      });
    },
  },
});
