export function getTimeAgo(dateTimeString) {
  if (typeof dateTimeString === "number") {
    return formatTimeDifference(dateTimeString);
  }
  const now = new Date();
  const pastDate = new Date(dateTimeString);
  const diffMs = now - pastDate;
  const diffSeconds = Math.floor(diffMs / 1000);

  if (diffSeconds < 60) {
    return "меньше минуты назад";
  } else if (diffSeconds < 3600) {
    const diffMinutes = Math.floor(diffSeconds / 60);
    return `${diffMinutes} ${getNoun(
      diffMinutes,
      "минуту",
      "минуты",
      "минут"
    )} назад`;
  } else if (diffSeconds < 86400) {
    const diffHours = Math.floor(diffSeconds / 3600);
    return `${diffHours} ${getNoun(diffHours, "час", "часа", "часов")} назад`;
  } else if (diffSeconds < 2592000) {
    const diffDays = Math.floor(diffSeconds / 86400);
    return `${diffDays} ${getNoun(diffDays, "день", "дня", "дней")} назад`;
  } else if (diffSeconds < 31104000) {
    const diffMonths = Math.floor(diffSeconds / 2592000);
    return `${diffMonths} ${getNoun(
      diffMonths,
      "месяц",
      "месяца",
      "месяцев"
    )} назад`;
  } else {
    const diffYears = Math.floor(diffSeconds / 31104000);
    return `${diffYears} ${getNoun(diffYears, "год", "года", "лет")} назад`;
  }
}

function getNoun(number, one, few, many) {
  const absNumber = Math.abs(number);
  if (absNumber % 10 === 1 && absNumber % 100 !== 11) {
    return one;
  } else if (
    absNumber % 10 >= 2 &&
    absNumber % 10 <= 4 &&
    (absNumber % 100 < 10 || absNumber % 100 >= 20)
  ) {
    return few;
  } else {
    return many;
  }
}

function formatTimeDifference(timestamp) {
  const now = Math.floor(Date.now() / 1000); // текущее время в секундах
  const timeDifference = now - timestamp;

  const minute = 60;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day;
  const year = 365 * day;

  if (timeDifference < minute) {
    return "только что";
  } else if (timeDifference < hour) {
    const minutes = Math.floor(timeDifference / minute);
    return `${minutes} ${getEnding(
      minutes,
      "минуту",
      "минуты",
      "минут"
    )} назад`;
  } else if (timeDifference < day) {
    const hours = Math.floor(timeDifference / hour);
    return `${hours} ${getEnding(hours, "час", "часа", "часов")} назад`;
  } else if (timeDifference < week) {
    const days = Math.floor(timeDifference / day);
    return `${days} ${getEnding(days, "день", "дня", "дней")} назад`;
  } else if (timeDifference < month) {
    const weeks = Math.floor(timeDifference / week);
    return `${weeks} ${getEnding(weeks, "неделю", "недели", "недель")} назад`;
  } else if (timeDifference < year) {
    const months = Math.floor(timeDifference / month);
    return `${months} ${getEnding(months, "месяц", "месяца", "месяцев")} назад`;
  } else {
    const years = Math.floor(timeDifference / year);
    return `${years} ${getEnding(years, "год", "года", "лет")} назад`;
  }
}

function getEnding(number, one, two, many) {
  const absNumber = Math.abs(number);
  if (absNumber % 10 === 1 && absNumber % 100 !== 11) {
    return one;
  } else if (
    [2, 3, 4].includes(absNumber % 10) &&
    ![12, 13, 14].includes(absNumber % 100)
  ) {
    return two;
  } else {
    return many;
  }
}

export function getTime(date) {
  if (typeof date === "number") {
    return convertUnixTimestampToTime(date);
  }
  // Создаем объект Date из строки
  const dateTimeObj = new Date(date);

  // Извлекаем часы и минуты из объекта Date
  const hours = dateTimeObj.getHours();
  const minutes = dateTimeObj.getMinutes();

  // Форматируем часы и минуты в нужный вид
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  // Получаем строку времени в нужном формате (часы:минуты)
  return `${formattedHours}:${formattedMinutes}`;
}

function convertUnixTimestampToTime(unixTimestamp) {
  // Создаем новый объект Date, используя Unix-метку времени
  const date = new Date(unixTimestamp * 1000);

  // Получаем часы, минуты и секунды из объекта Date
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Собираем время в формате "часы:минуты"
  const time = `${hours}:${minutes}`;

  return time;
}

export function storageSetItem(key, data) {
  const jsonString = JSON.stringify(data);
  window.localStorage.setItem(key, jsonString);
}

export function storageGetItem(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

export async function loadExternalScript(src) {
  try {
    await new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script ${src}`));
      document.head.appendChild(script);
    });
    console.log("Success");
  } catch (err) {
    console.error(err);
  }
}

export function convertTimestampToData(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Преобразуем Unix timestamp в миллисекунды

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы в JavaScript начинаются с 0
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}`;
}
