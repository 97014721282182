import { getOrCreateTooltip } from "./chartCustomTooltips";

export const tooltipChart = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.dataPoints;
    const tableHead = document.createElement("thead");
    tableHead.classList.add("custom-tooltip-thead");
    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 1;

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    tableBody.classList.add("custom-tooltip-tableBody");

    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];
      const span = document.createElement("span");
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.classList.add("custom-tooltip-box");

      const spanText = document.createElement("span");
      spanText.classList.add("custom-tooltip-text");
      if (body.dataset.label !== "") {
        spanText.innerText = body.dataset.label;
      } else {
        spanText.innerText = body.label;
      }
      const spanValue = document.createElement("span");
      spanValue.innerText = body.formattedValue;
      spanValue.classList.add("custom-tooltip-value");

      tableBody.appendChild(span);
      tableBody.appendChild(spanText);
      tableBody.appendChild(spanValue);
    });
    // const footerText = document.createElement("span");
    // footerText.classList.add("custom-tooltip-text");
    // footerText.innerText = `Общий оборот`;
    // tableBody.appendChild(footerText);
    // const footerValue = document.createElement("span");
    // footerValue.classList.add("custom-tooltip-value");
    // footerValue.innerText = "$ 999";
    // tableBody.appendChild(footerValue);
    const tableRoot = tooltipEl.querySelector("table");
    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }
    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
};

export const stylesChart = {
  heightBarChart: '250px',
  heightAreaChart: '400px',
  heightDonutChart: '400px'
}