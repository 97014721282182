<template>
    <div ref="INFOREF" class="info-panel">
        <template v-if="watchId" > 
            <div class="name">
                <p :style="`color:${data[id].color === 'white' ? 'black' : data[id].color}`" class="panel-desc">{{data[id].name}}</p>
                <div :style="`border-bottom: 1px dashed ${data[id].color === 'white' ? 'black' : data[id].color};`" class="stat-border-line"></div>
                <p :style="`color:${data[id].color === 'white' ? 'black' : data[id].color}`" class="panel-desc" style="margin-left:0;">{{data[id].num}}</p>
            </div>
            <p class="panel-desc" style="margin-left: 2vh;">
                {{ `${data[id].position} место в мире` }}
            </p>
        </template>
    </div>
</template>

<script>
import { ref } from 'vue';
import { SetControl, delControl } from './SetControl'
export default {
    props:['MAP','data', 'id'],
    setup(){
        const INFOREF = ref()
        const CONTROL = ref()
        return {
            INFOREF,
            CONTROL
        }
    },
    mounted(){
        this.CONTROL = SetControl(this.MAP, this.INFOREF)
        
    },
    computed:{
        watchId(){
            console.log(this.data, this.id)
            if(this.data[this.id]){
                return this.id
            }  else {
                return ''
            }
        }
    },
    unmounted(){
        delControl(this.MAP, this.CONTROL)
    }

}

</script>

<style scoped>
.name{
    width:100%;
    height:50%;
    display:flex;
    align-items: center;
    justify-content: space-evenly;
}
.stat-border-line{
    width: 60%;
    
    margin-bottom: 4%;
}
.panel-desc{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
}
.info-panel{
    position: absolute;
    padding: 1vh;
    bottom: 2vh;
    right: 2vh;
    height: 20%;
    width: 20%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.14);

}
</style>