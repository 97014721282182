import { computed } from "vue";
import { am4geodata_worldLow } from "@/geodata/4worldLow";

export default function useCountryPropsHelper(props) {
  const country = computed(() => {
    const found = am4geodata_worldLow.features
      .map((item) => item.properties)
      .find((item) => item.id === props.countryCode);
    return found ? found.name : props.countryCode;
  });
  return { country };
}
