<template>
  <div class="table-w position-relative" style="min-height: 300px">
    <div class="screen-center" v-if="loading">
      <data-loader></data-loader>
    </div>

    <table class="table table-borderless report-table position-relative">
      <country-analytics-report-table-header />
      <template v-if="!loading">
        <country-analytics-report-table-row
          v-for="row in reportList"
          :row="row"
          :key="row.id"
        />
      </template>
    </table>
  </div>
</template>

<script>
import CountryAnalyticsReportTableHeader from "@/components/MapPage/CountryAnalyticsView/CountryAnalyticsReportTableHeader";
import CountryAnalyticsReportTableRow from "@/components/MapPage/CountryAnalyticsView/CountryAnalyticsReportTableRow";
import DataLoader from "@/components/MapPage/shared/DataLoader";
import { computed } from "vue";

export default {
  name: "CountryAnalyticsReportTable",
  props: ["dataSource", "loading", "currentFilter"],
  components: {
    DataLoader,
    CountryAnalyticsReportTableRow,
    CountryAnalyticsReportTableHeader,
  },
  setup(props) {
    const setSortRule = (a, b) => {
      if (props.currentFilter.id === 1) {
        return a + b;
      } else if (props.currentFilter.id === 2) {
        return a - b;
      }
    };
    const reportList = computed(() => {
      if (props.dataSource !== null) {
        const result = props.dataSource.toSorted((a, b) => {
          const firstDate = new Date(a.createdAt.split("T")[0]);
          const secondDate = new Date(b.createdAt.split("T")[0]);
          return setSortRule(firstDate, secondDate);
        });
        return result;
      }
      return props.dataSource;
    });
    return { reportList };
  },
};
</script>

<style lang="scss" scoped>
.table-w {
  overflow: auto;
}
</style>
