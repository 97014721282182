<template>
  <div class="w-100 d-flex gap-2 leader-row">
    <img :src="row.avatar_uri" />
    <div class="d-inline-block h-100 w-100">
      {{ personName }}
      <div class="d-flex gap-1">
        <social-network-link
          v-for="link in row.links"
          :key="link.name"
          :text="link.name"
          :href="link.href"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SocialNetworkLink from "@/components/MapPage/shared/SocialNetworkLink";
import { computed } from "vue";

export default {
  name: "CountryOpinionLeaderRow",
  components: { SocialNetworkLink },
  props: ["row"],
  setup(props) {
    const personName = computed(() => {
      return `${props.row.first_name || ""}  ${props.row.second_name || ""} ${
        props.row.last_name || ""
      }`;
    });
    return { personName };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars.scss";

.leader-row {
  font-family: $font-roboto-regular;
  font-size: 14px;

  img {
    border-radius: 8px;
    width: 48px;
    height: 48px;
  }
}
</style>
