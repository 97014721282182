import axios from "axios";
import { useLK } from "@/controllerLK/LKstore";

export default class CountryData {
  constructor() {
    this.state = {};
    this.getters = {};
    this.actions = {
      async loadCountryData({
        requestType,
        countryCode,
        params,
        clear,
        usePost,
        body,
      }) {
        const LKStore = useLK();
        if (this.cancelTokenSource) {
          this.cancelTokenSource.cancel();
        }
        this.cancelTokenSource = axios.CancelToken.source();
        this.startLoading();
        try {
          console.log("Country data Loading", countryCode, requestType);
          let url = this.endpoint;
          if (requestType) {
            url = url.replace(":requestType", requestType);
          }
          if (countryCode) {
            url = url.replace(":countryCode", LKStore.project.countryId);
          }

          url = url.replace(":projectId", LKStore.project.id);

          const items = (
            usePost
              ? await axios.post(url, body, {
                  cancelToken: this.cancelTokenSource.token,
                  params,
                })
              : await axios.get(url, {
                  cancelToken: this.cancelTokenSource.token,
                  params,
                })
          ).data;

          this.addItems(items, clear);
          this.clearLoading();
          console.log(
            "Country data Loaded",
            countryCode,
            requestType,
            this.items
          );
        } catch (error) {
          if (axios.isCancel(error)) {
            return;
          }
          this.setError(error);
        }
      },
    };
  }
}
