<script setup>
// import SmallIcon from "@/components/Chunks/SmallIcon.vue";
import { defineProps } from "vue";

const props = defineProps({
  data: String,
  active: {
    type: Boolean,
    default: false,
  },
});
</script>
<template>
  <li
    @click="$emit('select', $el.target)"
    class="source__item item-source cursor"
    :class="{ active: props.active }"
  >
    <p class="item-source__text">{{ props.data }}</p>
    <slot />
  </li>
</template>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";


.item-source {
  min-height: 38px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  position: relative;

  &:hover {
    background: $blue-light;
    border-radius: 8px;
    border: none;
  }

  &__text {
    margin: 0 0 0 10px;
    flex: 1 1 auto;
    overflow-wrap: break-word;
    max-width: 100%;
  }
}
</style>
