<template>
  <div class="country-opinion-leaders-list">
    <div class="leaders-title d-flex pt-3 align-items-baseline gap-1">
      &nbsp; &nbsp;Лидеры мнений <span class="counter">{{ personCount }}</span>
    </div>
    <ul class="leaders-list">
      <li
        v-for="item in leaderList"
        :key="item.id"
        @click="selectPerson(item.id)"
        :class="{ selected: selectedPersonId === item.id }"
      >
        <country-opinion-leader-row :row="item" />
      </li>
    </ul>
  </div>
</template>

<script>
import { computed } from "vue";
import CountryOpinionLeaderRow from "@/components/MapPage/CountryOpinionLeadersView/CountryOpinionLeaderRow";
import { useCountryPersonsStore } from "@/store/country-persons";

export default {
  name: "CountryOpinionLeadersList",
  components: { CountryOpinionLeaderRow },
  setup() {
    const countryPersonsStore = useCountryPersonsStore();
    const leaderList = computed(() => {
      return countryPersonsStore.items;
    });
    const personCount = computed(() => {
      return countryPersonsStore.items?.length || 0;
    });
    const selectedPersonId = computed(() => {
      return countryPersonsStore.selectedPersonId;
    });

    function selectPerson(personId) {
      countryPersonsStore.setSelectedPersonId(personId);
    }

    return {
      leaderList,
      personCount,
      selectPerson,
      selectedPersonId,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars.scss";

.leaders-list {
  text-align: left;
  list-style-type: none;
  padding: 10px 5px;

  li {
    font-family: $font-roboto-regular;
    font-size: 14px;
    padding: 10px 10px;
    color: black;
  }

  .selected {
    background: rgba(45, 122, 237, 0.2) !important;
    border-radius: 6px;
    color: black;
  }
}

.country-opinion-leaders-list {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  margin-top: 10px;
}

.leaders-title {
  font-family: $font-roboto-bold;
  font-size: 14px;
  color: black;
}

.counter {
  font-family: $font-roboto-regular;
  font-size: 10px;
  color: #8c8c8c;
}
</style>
