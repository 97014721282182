<template>
    <VueDatePicker
      range
      locale="ru"
      cancelText="отмена"
      selectText="выбрать"
      :model-value="props.modelValue"
      @update:model-value="setDate"
    >
        <template #trigger>
          <div class="icon-w">
            <div class="icon icon-calendar"></div>
          </div>
        </template>
    </VueDatePicker>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const props = defineProps({
  modelValue: {
    required: true,
  }
})

const emit = defineEmits(['update:modelValue'])

const setDate = (value) => {
  emit('update:modelValue', value)
}
</script>

<style lang="scss" scoped>
.icon-w {
  background: #F7F8FD;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: .2s;
  &:hover {
    background: #EEF1FF;
    color: #1A64D4;
  }
  // &:global(.dp__arrow_top) {
  //   display: none !important;
  // }
}
.icon {
    font-size: 22px;
}
</style>