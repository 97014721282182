<template>
    <div  class="label-cont"  @click="$emit('set-group', item)">
        <div :style="'background-color:'+item.color+';'" class="first-circle">
            <div class="in-circle" :style="Store.activeViewGroupColor === item.color ? 'background-color:'+item.color+';' : ''" />
        </div>
        <p>{{ `${item.min} ${item.max ? '-' : '' } ${item.max}` }}  </p>
    </div>
</template>

<script>
import { useCountryStatDemographyStore } from "@/store/country-stat-demography";
export default {
    props:['item'],
    data(){
        return{
            Store: useCountryStatDemographyStore(),
        }
       
    },
}

</script>

<style>
.first-circle{
    height: 50%;
    width: 60%;
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

}
.in-circle{
    height: 60%;
    width: 60%;
    border-radius: 50%;
    background-color: white;
}
.label-cont{
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-around;

}
</style>