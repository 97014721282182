<template>
  <section class="dashboard-layout">
    <div class="first">
      <div class="first__title">
        Мир /
        <span> {{ LK.project.name }}</span>
      </div>
      <div class="top-box position-relative">
        <country-map-view :country-code="countryCode" />
      </div>
    </div>
    <div class="second" ref="secondBlockRef">
      <div class="top-news top-box dashboard-part">
        <country-dashboard-news-block
          :country-code="countryCode"
          :quantity-news="quantityNews"
        />
      </div>
      <div class="bottom-news__action">
        <div class="bottom-news dashboard-part">
          <div class="header1">Источники</div>
          <pie-chart-view :data-source="newsSourcesSource" height="170px" />
        </div>
        <div
          class="dashboard-primary-button"
          @click="$router.push({ name: 'Country.Analytics.Default' })"
        >
          Перейти ко всей аналитике
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CountryMapView from "@/components/MapPage/CountryMapView";
import { useCountryTradeStore } from "@/store/country-trade";
import { useCountryMilitaryTradeStore } from "@/store/country-military-trade";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useCountryNewsSourcesStore } from "@/store/country-news-sources";
// import LinesChartView from "@/components/LinesChartView";
import { useCountryNewsStore } from "@/store/country-news";
import CountryDashboardNewsBlock from "@/components/MapPage/CountryDashboardView/CountryDashboardNewsBlock";
import PieChartView from "@/components/MapPage/PieChartView";
import useCountryPropsHelper from "@/composition/country-props-helper";
// import CountryTradeBar from "@/components/MapPage/CountryDashboardView/CountryTradeBar.vue";
// import BreadCrumbs from "@/components/MapPage/shared/BreadCrumbs";
import { useCountryMapNewsStore } from "@/store/country-map-news";
import { useCountryNewsBlocksStore } from "@/store/country-news-blocks";
import { useLK } from "@/controllerLK/LKstore.js";
import { useCountryNewsRubricsStore } from "@/store/country-news-rubrics";

export default {
  name: "CountryDashboardView",
  props: ["countryCode"],
  components: {
    // BreadCrumbs,
    PieChartView,
    CountryDashboardNewsBlock,
    CountryMapView,
  },
  setup(props) {
    const LK = useLK();
    const tradeStore = useCountryTradeStore();
    const militaryTradeStore = useCountryMilitaryTradeStore();
    const newsStore = useCountryNewsStore();
    const newsSourcesStore = useCountryNewsSourcesStore();
    const countryPropsHelper = useCountryPropsHelper(props);
    const mapNewsStore = useCountryMapNewsStore();
    const countryNewsBlocksStore = useCountryNewsBlocksStore();
    const rubricsStore = useCountryNewsRubricsStore();
    const tradeDataSource = computed(() => {
      return tradeStore.items;
    });
    const militaryTradeDataSource = computed(() => {
      return militaryTradeStore.items;
    });
    const newsSourcesSource = computed(() => {
      return newsSourcesStore.items;
    });

    const showBarChart = computed(() => {
      return (
        militaryTradeDataSource.value != null &&
        militaryTradeDataSource.value != undefined &&
        militaryTradeDataSource.value.length > 0
      );
    });

    const secondBlockRef = ref(null);

    let maxItems = ref(0);

    const displayNews = () => {
      const vh = window.innerHeight / 100;
      const viewportHeight = window.innerHeight - 7 * vh;
      const secondBlockHeight = secondBlockRef.value.clientHeight;

      const remainingHeight = viewportHeight - secondBlockHeight;
      const itemHeight = 60;
      maxItems.value =
        Math.floor(remainingHeight / itemHeight) > 4
          ? 4
          : Math.floor(remainingHeight / itemHeight);
    };

    const getDashboardNews = async (quantityNews) => {
      if (!rubricsStore.items.length) return [];

      if (rubricsStore.items.length < quantityNews) {
        const rubric_ids = rubricsStore.items[0].id;

        await newsStore.loadCountryNews({
          params: {
            rubric_ids,
            skip: 0,
            take: quantityNews,
            dashboardNews: true,
          },
        });
      } else {
        for (let i = 0; i < quantityNews; i++) {
          const rubric_ids = rubricsStore.items[i].id;

          await newsStore.loadCountryNews({
            params: { rubric_ids, skip: 0, take: 1, dashboardNews: true },
          });
        }
      }
    };

    watch(
      () => LK.project.id,
      async () => {
        await rubricsStore.loadCountryNewsRubrics({
          countryCode: props.countryCode,
        });
        countryNewsBlocksStore.resetFilter();
        await countryNewsBlocksStore.loadCountryNewsBlocks();

        newsStore.clearDashBoardItems();
        getDashboardNews(maxItems.value);
      }
    );

    onMounted(async () => {
      displayNews();
      await tradeStore.loadTrade({ countryCode: props.countryCode });
      await militaryTradeStore.loadTrade({ countryCode: props.countryCode });
      if (
        countryNewsBlocksStore.items?.length ||
        countryNewsBlocksStore.currentTypeFilter.id === null
      ) {
        countryNewsBlocksStore.resetFilter();
      }
      await countryNewsBlocksStore.loadCountryNewsBlocks();
      await rubricsStore.loadCountryNewsRubrics({
        countryCode: props.countryCode,
      });

      newsStore.clearDashBoardItems();
      getDashboardNews(maxItems.value);

      await newsSourcesStore.loadCountryNewsSources({
        countryCode: props.countryCode,
      });
    });

    onUnmounted(() => {
      newsStore.clearDashBoardItems();
    });

    return {
      LK,
      tradeDataSource,
      militaryTradeDataSource,
      newsSourcesSource,
      ...countryPropsHelper,
      showBarChart,
      mapNewsStore,
      secondBlockRef,
      displayNews,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars.scss";

.dashboard-layout {
  display: grid;
  grid-template-columns: minmax(350px, 100%) 345px;
  min-height: calc(100vh - 7.1vh);

  .dashboard-part {
    margin: 20px 5px 10px;
    background-color: white;
    //box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border: 1px solid #e7e7e7;
    border-radius: 6px;
  }
}

.bottom-chart {
  height: 260px;
  margin-top: 0px !important;
  flex-basis: 100%;

  &.expanded {
    flex-basis: 55%;
  }
}

.bottom-news {
  height: 210px;
}

.first {
  display: flex;
  flex-direction: column;

  &__title {
    text-align: start;
    padding-top: 20px;
    font-weight: 400;
    font-size: 1.5vh;
    line-height: 2vh;
    color: rgba(0, 0, 0, 0.45);
    padding-left: calc(2vh - 10px);

    & span {
      color: rgba(0, 0, 0, 1);
    }
  }
}

.second {
  height: fit-content;
}

.top-box {
  // height: calc(100vh - 260px - 84px - 40px);
  flex: 1 1 auto;
  margin: 20px 5px 10px;
  border: 1px solid #eef1f4;
}

.dashboard-primary-button {
  color: white;
  background-color: #1a64d4;
  margin: 10px 5px 10px;
  border: 1px solid silver;
  border-radius: 10px;
  height: 40px;
  padding: 8px 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  cursor: pointer;
}

.country-trade-bar {
  height: 260px;
  flex-basis: 45%;
  margin-right: 20px;
  margin-top: 0px !important;
}

.bottom-charts {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 10px;
}

.header1 {
  font-family: $font-news-cycle-bold;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  margin: 20px 0 0 20px;
}
</style>
