<template>
  <div class="country_map_view">
    <div class="chart-container">
      <CountryMapViewContainer
        :onNewsClick="onNewsClick"
        :onNewsClose="onNewsClose"
        :key="mapContainer"
      />
    </div>
    <country-map-single-news-view
      :selectedNews="selectedNews"
      @newsSingleClose="onNewsClose"
      :onNewsClick="onNewsClick"
    />
  </div>
</template>

<script>
import { useCountryCitiesStore } from "@/store/country-cities";
// import { useCountryMapNewsStore } from "@/store/country-map-news";
import { useCountryNewsSingleStore } from "@/store/country-news-single";
import CountryMapSingleNewsView from "@/components/MapPage/CountryMap/CountryMapSingleNewsView";
import CountryMapViewContainer from "@/components/MapPage/CountryMapView/CountryMapViewContainer.vue";
import { useLK } from "@/controllerLK/LKstore.js";

export default {
  name: "CountryMapView",

  props: ["countryCode"],

  components: { CountryMapSingleNewsView, CountryMapViewContainer },

  data() {
    return {
      //loading: false,
      selectedNews: null,
      LK: useLK(),
      mapContainer: 0,
    };
  },
  watch: {
    "LK.project.id"(val) {
      if (val) {
        this.mapContainer++;
      }
    },
  },
  async mounted() {
    const citiesStore = useCountryCitiesStore();
    // const mapNewsStore = useCountryMapNewsStore();
    await citiesStore.loadCities({ countryCode: this.countryCode });
    // await mapNewsStore.load({ countryCode: this.countryCode });
    // countryMap(this.countryCode, this.onNewsClick);
  },

  methods: {
    async onNewsClick(newsId) {
      const newsSingleStore = useCountryNewsSingleStore();
      await newsSingleStore.load({
        countryCode: this.countryCode,
        newsId: newsId,
      });
      this.selectedNews = newsSingleStore.getRecord;
    },

    onNewsClose() {
      this.selectedNews = null;
    },
  },
};
//========================================================================================================================================================
</script>

<style lang="scss">
@import "src/assets/styles/vars";

.country_map_view {
  position: relative;
  height: 100%;
}

.chart-container {
  height: 100%;
}
</style>
