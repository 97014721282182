<script setup>
import { importantStyleList } from "@/tools/UI";
import { useNewsFeedStore } from "@/store/NewsFeed/newsFeed";

const newsFeedStore = useNewsFeedStore();

const setNews = async (imp) => {
  if (!isNaN(imp)) {
    newsFeedStore.currentImportance = imp;
    newsFeedStore.isMarked = false;
  } else {
    newsFeedStore.currentImportance = 0;
    newsFeedStore.isMarked = true;
  }
  newsFeedStore.clearNewsFeed();
  await newsFeedStore.getRubricNews();
};

function nextPage() {
  newsFeedStore.getRubricNews();
}

function prevPage() {
  if (newsFeedStore.$state.offset > 40) {
    newsFeedStore.$state.offset = newsFeedStore.$state.offset - 40
    newsFeedStore.getRubricNews();
  } else {
    newsFeedStore.$state.offset = 0
    newsFeedStore.getRubricNews();
  }
}

</script>
<template>
  <div class="container-header-news">
    <div class="container-header-news__btn-wrapper">
      <button
        v-for="btn in importantStyleList.toReversed()"
        :key="btn.id"
        class="container-header-news__btn"
        @click.prevent="setNews(btn.importance)"
        :style="[
          newsFeedStore.currentImportance === btn.importance
            ? { ...btn.style }
            : null,
          btn.importance === 0 ? { order: -1 } : null,
        ]"
      >
        {{ btn.text }}
      </button>
    </div>
    <div class="container-header-news__info-wrapper">
      <div class="container-header-news__pagination pagination-header-news">
        <button
          class="pagination-header-news__arrow pre icon-new-arrow"
          :disabled="newsFeedStore.$state.offset <= newsFeedStore.$state.take"
          @click="prevPage"
        ></button>
        <span class="pagination-header-news__current">{{ newsFeedStore.$state.offset ? (newsFeedStore.$state.offset /
          newsFeedStore.$state.take) : 1 }}</span>
        <span class="pagination-header-news__all-page">140</span>
        <button
          class="pagination-header-news__arrow next icon-new-arrow"
          @click="nextPage"
        ></button>
        <span class="pagination-header-news__all-news">Всего: 12093 новости</span>
      </div>
      <button class="container-header-news__filter-button filter-button">
        <span class="icon-new-check"></span>
        Отмеченные
      </button>
      <button class="container-header-news__filter-button filter-button">
        <span class="icon-bookmark"></span>
        Сохраненные
      </button>
    </div>
  </div>
</template>
<style
  lang="scss"
  scoped
>
@import "@/assets/style/variables.scss";

.container-header-news {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__btn-wrapper {
    display: flex;
    min-height: 38px;
  }

  &__info-wrapper {
    min-height: 38px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btn {
    min-height: 31px;
    padding: 0 14px;
    border-radius: 22px;
    cursor: pointer;
    background: $white;
    color: $black;
    min-width: fit-content;
    margin: 0 10px 0 0;
  }

  &__pagination {
    flex: 1 1 auto;
  }

  &__filter-button {
    &:last-child {
      margin: 0 0 0 10px;
    }
  }
}

.pagination-header-news {
  display: flex;
  align-items: center;
  color: $dirty;

  .icon-new-arrow:before {
    font-size: 7px;
  }

  &__arrow {
    font-size: 10px;
    flex: 0 0 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px 0 0;
    transform-origin: center;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.3s;

    &:hover {
      background: $white;
    }

    &.pre {
      transform: rotate(90deg);
      //rotate: 90deg;
    }

    &.next {
      rotate: -90deg;
    }
  }

  &__current {
    flex: 0 0 31px;
    height: 31px;
    width: 31px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $dirty;
    color: $white;
    //line-height: 0;
    margin: 0 6px 0 0;
  }

  &__all-page {
    margin: 0 10px 0 0;

    &::before {
      content: "/";
      margin: 0 6px 0 0;
    }
  }

  &__all-news {}
}

.filter-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f7f9;
  min-height: 31px;
  border-radius: 15px;
  color: $blue-dark;
  padding: 0 14px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: $white;
  }

  &>span {
    margin: 0 9px 0 0;
  }
}
</style>
