<template>
  <div class="news-detail position-relative" @click="$emit('clickNewsItem')">
    <h4 class="text-start news-card-title">{{ newsItemTitle }}</h4>
    <div class="news-type-buttons-panel">
      <news-type-button :importance="newsItem.importance" />
      <button>События</button>
      <span class="position-absolute news-time"> {{ newsItemTime }}</span>
    </div>
    <div class="news-text">
      <div v-html="newsItemText"></div>
    </div>

    <div class="news-source">
      <div class="news-source-wrapper">
        <i class="news-source-icon source-icon" :title="newsItem.source"></i>
        Источник:
        <a :href="newsItem.link" target="_blank"> {{ newsItem.source }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import NewsTypeButton from "@/components/MapPage/shared/NewsTypeButton";
import { computed } from "vue";
import { getTimeAgo } from "@/tools/date-time-tools";
import useTextHelper from "@/composition/text-helper";

export default {
  name: "NewsMasterCard",
  components: { NewsTypeButton },
  props: ["newsItem"],
  emits: ["clickNewsItem"],
  setup(props) {
    const textHelper = useTextHelper();
    const newsItemTime = computed(() => {
      return getTimeAgo(props.newsItem.date);
    });
    const newsItemTitle = computed(() => {
      return textHelper.cutTags(props.newsItem.title);
    });
    const newsItemText = computed(() => {
      return textHelper.cutTags(props.newsItem.text);
    });
    return {
      newsItemTime,
      newsItemTitle,
      newsItemText,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars.scss";

.news-type-buttons-panel {
  display: flex;
  justify-content: left;
  gap: 5px;
  position: relative;

  button {
    border-radius: 16px;
    font-family: $font-roboto-regular;
    font-size: 12px;
    background-color: white;
    width: 100px;
    border: 1px solid silver;
    height: 22px;
  }
}

.news-detail {
  position: relative;
  display: inline-block;
  // box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 8px 0px #00000024;

  width: 100%;
  height: 213px;
  background-color: white;
  border-radius: 10px;
  margin: 0 0 12px 0;
  padding: 16px 20px;
}

.news-card-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  overflow-x: hidden;
}

.news-text {
  font-family: $font-roboto-regular;
  font-size: 13px;
  color: black;
  text-align: left;
  margin: 10px 0;
  max-height: 100px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.news-time {
  right: 0;
  top: 0;
  position: absolute;
  font-family: $font-roboto-regular;
  font-size: 12px;
  color: #808080;
}

.source-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
}

.news-source {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  bottom: 0;
  width: 92%;
  font-family: $font-roboto-regular;
  font-size: 12px;
  text-align: left;
  padding: 10px 0;

  .news-source-wrapper {
    display: flex;
    overflow-x: hidden;
    gap: 5px;
  }

  a,
  .link {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    width: 100%;
    display: block;
  }
}
</style>
