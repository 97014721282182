import { ref } from 'vue';
import useCommonChart from '@/mixins/apexChart/commonChart'

export default function useAreaChart({noDataText, titleText}, categories, series) {

  const chartOptions = ref({
    chart: {
      type: 'area',
      zoom: {
        enabled: false
      },
    },
    title: {
      text: titleText ? titleText : 'График',
      align: 'left',
      margin: 0,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize:  '14px',
        fontWeight:  'bold',
        color:  '#000'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6
      }
    },
    xaxis: {
      type: 'datetime',
      categories: categories
    },
    grid: {
      borderColor: '#f1f1f1',
    },
    noData: {
      text: noDataText ? noDataText : 'Загрузка...'
    }
  })

  useCommonChart(chartOptions, categories, series)

  return {
    chartOptions,
  };
}