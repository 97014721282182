import { defineStore } from "pinia";
import Crud from "@/store/NewsFeed/crud";
import environment from "@/environment";
import { useLK } from "@/controllerLK/LKstore.js";
import axios from "axios";

const crud = new Crud(
  environment.appConfig.baseApiUrl + `Country`,
  "id",
  "",
  {}
);

export const useCountriesStore = defineStore({
  id: "countries",
  state: () => ({
    ...crud.state,
    citiesList: [],
    citiesListLoading: false,
    countryInfo: null,
  }),
  getters: {
    ...crud.getters,
  },
  actions: {
    ...crud.actions,
    async getCitiesList() {
      if (this.citiesList.length !== 0) return this.citiesList;
      this.citiesListLoading = true;
      const LKstore = useLK();
      const url = `${this.endpoint}/amcharts/${LKstore.project.countryId}`;
      const { data } = await axios(url);
      this.citiesList = data;
      this.citiesListLoading = false;
      return this.citiesList;
    },
    async getCountryInfo(countryCode) {
      if (this.countryInfo) return this.countryInfo;
      const LKstore = useLK();
      const url = countryCode ?? LKstore.project.countryId;
      const { data } = await axios(this.endpoint + "/" + url);
      this.countryInfo = data;
      return data;
    },
  },
});
