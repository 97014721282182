export const IMPORTANCE = [
  {
    id: 0,
    importance: 0,
    color: "silver",
    buttonColor: "#1A64D4",
    name: "Нейтрально",
    background: '#FFFFFF'
  },
  {
    id: 1,
    importance: 1,
    color: "#98E589",
    buttonColor: "#C4F487",
    name: "Не критично",
    background: '#97BF76'
  },
  {
    id: 2,
    importance: 1.5,
    color: "#FFE34E",
    buttonColor: "#FFE767",
    name: "Умеренно",
    background: '#F8ECA9'
  },
  {
    id: 4,
    importance: 2,
    color: "#FEFEFE",
    buttonColor: "#FF784E",
    name: "Важно",
    background: '#FBA77F'
  },
  {
    id: 3,
    importance: 3,
    color: "#FFAFA7",
    buttonColor: "#FA665C",
    name: "Критично",
    background: '#E56D65'
  },
];

// export function getNewsImportance(importance) {
//   let found = IMPORTANCE[0];
//   for (let i = 1; i < IMPORTANCE.length; i++) {
//     if (importance >= IMPORTANCE[i].id) {
//       found = IMPORTANCE[i];
//     }
//   }
//   console.log(" =====>>>> ", importance, found);
//   return found;
// }
// Переписал эту дичь что сверху была

export function getNewsImportance(importance = 0) {
  return IMPORTANCE.find((el) => el.importance === +importance) || {};
}
export function getImportanceColor(importance) {
  const found = getNewsImportance(importance);
  return found.color;
}
export function getImportanceBackground(importance = 0) {
  const found = getNewsImportance(importance);
  return found.background;
}
export function getImportanceButtonColor(importance) {
  const found = getNewsImportance(importance);
  return found.buttonColor;
}

export function calcViewport(size, container = 1440) {
  const result = (window.innerWidth * ((size / container) * 100)) / 100;
  return result < size ? size : result;
}
