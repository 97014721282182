import { defineStore } from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import CountryData from "@/store/country-data";
import axios from "axios";
import { useLK } from "@/controllerLK/LKstore";
import { useCountryNewsBlocksStore } from "@/store/country-news-blocks";

const crud = new Crud(
  environment.appConfig.baseApiUrl + "news/map/:projectId",
  "id",
  "",
  {}
);

const countryData = new CountryData();

export const useCountryMapNewsStore = defineStore({
  id: "countryMapNews",
  state: () => ({
    ...crud.state,
    ...countryData.state,
    selectedNews: {
      defNews: true,
      lightNews: true,
      mediumNews: true,
      interestNews: true,
      importantNews: true,
    },
    dateFrom: null,
    dateTo: null,
    countryID: null,
    changeDate: false,
  }),
  getters: {
    ...crud.getters,
    ...countryData.getters,
    getFilterPoints(state) {
      if (!state.items?.length) return [];
      const countryNewsBlocksStore = useCountryNewsBlocksStore();
      const random = Math.floor(Math.random() * (state.items.length - 1));
      return state.items.filter((point, index) => {
        if (countryNewsBlocksStore.currentTypeFilter.id) {
          return index === random;
        }
        return this.getImportanceValue(point.importance);
      });
    },
  },
  actions: {
    ...crud.actions,
    ...countryData.actions,
    async load() {
      this.clearItems();
      return await this.loadCountryData({
        params: { dateFrom: this.dateFrom, dateTo: this.dateTo },
      });
    },
    toggleSelectedNews(news) {
      this.selectedNews[news] = !this.selectedNews[news];
    },
    async getCountryRaiting(countryId = "") {
      const LKStore = useLK();
      const url = `/globus/api/Country/regionRating/${LKStore.project.id}/${countryId}`;
      const res = await axios(url, {
        params: { dateFrom: this.dateFrom, dateTo: this.dateTo },
      });
      return res.data;
    },
    getImportanceValue(imp) {
      if (imp === 1) return this.selectedNews.lightNews;
      if (imp === 1.5) return this.selectedNews.mediumNews;
      if (imp === 2) return this.selectedNews.interestNews;
      if (imp === 3) return this.selectedNews.importantNews;
    },
    async clearItems() {
      this.items = [];
    },
  },
});
