import { defineStore } from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import CountryData from "@/store/country-data";
import axios from "axios";
import { useLK } from "@/controllerLK/LKstore";

const crud = new Crud(
  environment.appConfig.baseApiUrl + "Avl/news/pulse/:countryCode",
  "id",
  "",
  {}
);

const countryData = new CountryData();

export const useCountryNewsPulseStore = defineStore({
  id: "countryNewsPulse",
  state: () => ({
    ...crud.state,
    ...countryData.state,
  }),
  getters: {
    ...crud.getters,
    ...countryData.getters,
  },
  actions: {
    ...crud.actions,
    ...countryData.actions,
    async loadCountryNewsPulse() {
      const LKstore = useLK();
      const url = `${environment.appConfig.baseApiUrl}Block/news/pulse/${LKstore.project.id}`;
      const { data } = await axios(url);
      this.items = data;
    },
  },
});
