<template>
  <div class="breadcrumb-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BreadCrumbs"
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars.scss";

.breadcrumb-wrapper {
  border-radius: 8px;
  text-align: left;
  padding: 12px;
  font-family: $font-roboto-regular;
  font-size: 14px;
}
</style>
