<template>
  <div class="group-change-view">
    <div
      :style="
        active === 'Map-stat' ? 'background-color: rgba(245, 245, 245, 1)' : ''
      "
      ref="btn-stat"
      @click="changeActive('Map-stat')"
      class="btn-change-view"
      style="margin-bottom: 10%"
    >
      <img :src="require('@/assets/icons/btn-change-view.svg')" />
    </div>
    <div
      :style="
        active === 'Globus'
          ? 'background-color: background-color: rgba(245, 245, 245, 1);'
          : ''
      "
      ref="btn-old-map"
      @click="changeActive('Globus')"
      class="btn-change-view"
    >
      <img :src="require('@/assets/icons/btn-change-view2.svg')" />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();
let active = ref(route.name);

function changeActive(type) {
  router.push({ name: type });
}

console.log("123123");
</script>

<style lang="scss">
@import "@/assets/styles/dark.scss";
</style>
