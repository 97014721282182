import { defineStore } from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import CountryData from "@/store/country-data";
import { toTimestamp } from '@/tools/date-time-tools'

const crud = new Crud(
  environment.appConfig.baseApiUrl + "Stats/region/sources",
  "id",
  "",
  {}
);
const countryData = new CountryData();

export const useAnalyticsRegionsRubrics = defineStore({
  id: "analyticsRegionsRubrics",
  state: () => ({
    ...crud.state,
    ...countryData.state,
  }),
  getters: {
    ...crud.getters,
    ...countryData.getters,
  },
  actions: {
    ...crud.actions,
    ...countryData.actions,
    async loadStat({rubric, cities, dateFrom, dateTo}) {
      console.log('test1', rubric)
      this.endpoint = environment.appConfig.baseApiUrl + `Stats/region/sources/${rubric}`
      return await this.loadCountryData({
        clear: true,
        body: cities,
        params: {
          dateFrom: toTimestamp(dateFrom),
          dateTo: toTimestamp(dateTo) 
        },
        usePost: true
      });
    },
  },
});
