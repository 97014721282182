<template>
  <div class="total_view"></div>
  <div id="chartdiv"></div>
</template>

<script>
import { total } from "@/components/MapPage/charts/total";

export default {
  name: "TotalView",
  mounted() {
    total();
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/dark.scss";
</style>
