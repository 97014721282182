<template>
    <div ref="STATREF" class="statistick-panel">
        <p class="panel-desc" >Значения показателей 
            <abbr style="cursor: pointer;" :title="text">
                <img style="margin-left:1vh;" :src="require('@/assets/icons/info.svg')" />
            </abbr>
        </p>
        <div class="stat-label-items">
            <StatItemLabel v-for="(item, index) in data" @set-group="(val)=>$emit('set-group',val)" :item="item" :key="index"/>
        </div>
        <div style="display: flex;align-items: center;justify-content: flex-start;">
            <p class="panel-desc">Low</p>
            <div  class="stat-border-line"></div>
            <p class="panel-desc" style="margin-left:0;">High</p>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { SetControl } from './SetControl'
import StatItemLabel from './StatItemLabel.vue';
export default {
    props:['MAP','data','text'],
    components:{
        StatItemLabel,
    },
    setup(){
        const STATREF = ref()
        return {
            STATREF
        }
    },
    mounted(){
        SetControl(this.MAP, this.STATREF)
        console.log(this.data)
    }
}

</script>

<style scoped>
.stat-border-line{
    width: 83%;
    border-bottom:1px dashed gray;
    margin-bottom: 2%;
}
.panel-desc{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    margin-left: 3vh;
}
.stat-label-items{
    display: flex;
    width: 100%;
    height: 70%;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
}
.statistick-panel{
    position: absolute;
    padding: 2vh;
    bottom: 2vh;
    left: 2vh;
    height: 30%;
    width: 40%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.14);

}
</style>