<template>
  <div class="news-detail-wrapper scroll">
    <div class="news-detail" v-if="newsItem?.id">
      <h4 class="text-start">{{ newsItemTitle }}</h4>
      <div class="news-text"></div>

      <div class="news-type-buttons-panel">
        <news-type-button :importance="newsItem.importance" />
        <button>События</button>
        <span class="position-absolute news-time"> {{ newsItemTime }}</span>
      </div>

      <div class="news-source">
        <i class="news-source-icon source-icon"></i>
        Источник:
        <a :href="newsItem.link" target="_blank">{{ newsItem.source }}</a>
      </div>
      <div class="news-text">
        <div v-html="newsItem.raw_text"></div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsTypeButton from "@/components/MapPage/shared/NewsTypeButton";
import useTextHelper from "@/composition/text-helper";
import { computed } from "vue";
import { getTimeAgo } from "@/tools/date-time-tools";

export default {
  name: "NewsItemDetail",
  components: { NewsTypeButton },
  props: ["newsItem"],
  setup(props) {
    const textHelper = useTextHelper();
    const newsItemTime = computed(() => {
      return getTimeAgo(props.newsItem.date);
    });
    const newsItemTitle = computed(() => {
      return textHelper.cutTags(props.newsItem.title);
    });
    return {
      newsItemTime,
      newsItemTitle,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars.scss";

.news-detail-wrapper {
  max-height: calc(100vh - 169px);
  overflow-y: scroll;
  // overflow-x: scroll;
  // max-width: 700px;
  padding: 0px 7px 12px 0px;
  // margin: 0px 0px 20px 0px;
  border-radius: 8px;

  .news-detail {
    box-shadow: none;
  }
}

.news-text {
  font-family: $font-roboto-regular;
  font-size: 13px;
  color: black;
  text-align: left;
  margin: 10px 0;
  text-wrap: normal;
  white-space: normal;
  width: 100%;

  // img,
  // svg {
  //   object-fit: contain !important; // оно тут не работает !!!!
  //   max-width: 100% !important; // оно тут не работает !!!!
  // }
}
</style>
<style lang="scss" scoped>
@import "@/assets/styles/vars.scss";

.news-type-buttons-panel {
  display: flex;
  justify-content: left;
  gap: 5px;
  position: relative;

  button {
    border-radius: 16px;
    font-family: $font-roboto-regular;
    font-size: 12px;
    background-color: white;
    width: 100px;
    border: 1px solid silver;
    height: 22px;
  }
}

.news-detail {
  position: sticky;
  display: inline-block;
  top: 0;
  width: 100%;
  height: fit-content;
  overflow: auto;
  background-color: white;
  border-radius: 10px;
  margin: 0 0;
  min-width: 380px;
  padding: 16px 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.news-time {
  right: 0;
  top: 0;
  position: absolute;
  font-family: $font-roboto-regular;
  font-size: 12px;
  color: #808080;
}

.source-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
}

.news-source {
  display: flex;
  align-items: center;
  gap: 10px;
  bottom: 0;
  width: 100%;
  font-family: $font-roboto-regular;
  font-size: 12px;
  text-align: left;
  padding: 10px 0;
}
</style>
<style lang="scss">
.news-text {
  img,
  svg {
    object-fit: contain !important;
    max-width: 100% !important;
  }

  video {
    width: 100%;
  }
}
</style>
