import { ref } from 'vue';
import useCommonChart from '@/mixins/apexChart/commonChart'

export default function useDonutChart({noDataText, titleText}, labels, series,) {
    
    const chartOptions =  ref({
      chart: {
        type: 'donut',
      },
      title: {
        text: titleText ? titleText : 'График',
        align: 'left',
        margin: 0,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize:  '14px',
          fontWeight:  'bold',
          color:  '#000'
        },
      },
      labels: labels,
      noData: {
        text: noDataText ? noDataText : 'Загрузка...'
      }
    })

    useCommonChart(chartOptions, labels, series)

    return {
        chartOptions,
    };
}