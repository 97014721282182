import {defineStore} from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import axios from "axios";

const crud = new Crud(
    environment.appConfig.baseApiUrl + 'news/:countryCode/one/:id',
    'id',
    '',
    {}
);

export const useCountryNewsSingleStore = defineStore({
    id: 'countryNewsSingle',
    state: () => ({
        ...crud.state,
    }),
    getters: {
        ...crud.getters,
    },
    actions: {
        ...crud.actions,
        async load({countryCode, newsId}) {
            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel();
            }
            this.cancelTokenSource = axios.CancelToken.source();
            this.startLoading();
            try {
                console.log('Load single news ID = ', newsId, countryCode);
                const url = this.endpoint
                    .replace(':id', newsId)
                    .replace(':countryCode', countryCode);
                const item = (
                    await axios.get( url,{ cancelToken: this.cancelTokenSource.token})
                ).data;
                this.setRecord(item);
                this.clearLoading();
                console.log('Single news loaded', this.record);

            } catch (error) {
                if (axios.isCancel(error)) {
                    return;
                }this.setError(error);
            }
        },
    }
});
