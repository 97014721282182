import { createRouter, createWebHistory } from "vue-router";
import { routesGlobus } from "@/router/globus-router";
import NewsPage from "@/pages/NewsPage.vue";
import MapPage from "@/pages/MapPage.vue";
import CountryDataView from "@/components/MapPage/CountryDataView.vue";
import SettingsPage from "@/pages/SettingsPage.vue";
import CountryPulseView from "@/components/MapPage/CountryPulseView";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    redirect: "/news",
  },
  {
    path: "/login",
    name: "login",
  },
  {
    path: "/lk",
    name: "lk",
  },
  {
    path: "/map",
    component: MapPage,
  },
  {
    path: "/pulse",
    component: CountryPulseView,
  },
  {
    path: "/news",
    component: NewsPage,
  },
  {
    path: "/news/:id",
    component: NewsPage,
  },
  {
    path: "/country-data",
    component: CountryDataView,
  },
  {
    path: "/settings",
    component: SettingsPage,
  },
  ...routesGlobus,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to) => {
  document.title = to.meta.title || "Глобус";
});

export default router;