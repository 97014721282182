<template>
  <div class="chart-w">
    <apexchart
      :options="chartOptions" 
      :series="series"
      :height="stylesChart.heightAreaChart"
    />
    <TheCalendar class="calendar"
      v-model="date"
    />
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useCountryStatDynamicRubricsStore } from "@/store/country-stat-dynamic-rubrics";
import { stylesChart } from '@/tools/tooltipChart'
import VueApexCharts from "vue3-apexcharts";
import TheCalendar from "@/components/UI/TheCalendar"
import useFilterDate from '@/mixins/apexChart/filterDate'
import useAreaChart from '@/mixins/apexChart/areaChart'

export default {
  name: "RubricDynamicChart",
  components: { apexchart: VueApexCharts, TheCalendar },
  setup() {

    const statStorage = useCountryStatDynamicRubricsStore();

    const categories = computed(() => {
      const labelsSet = new Set();
      (statStorage.items || []).forEach((item) => {
        item.news.forEach((point) => {
          labelsSet.add(point.date);
        });
      });
      return Array.from(labelsSet).sort((a, b) => new Date(a) - new Date(b));
    });

    const series = computed(() => {
      return (statStorage.items || []).map(item => ({
        name: item.name,
        data: item.news.map((point) => point.count),
      }));
    });

    const { chartOptions } = useAreaChart(
      {
        titleText: 'Динамика по рубрикам',
      },
      categories,
      series
    )
    
    const { date } = useFilterDate(loadData)

    onMounted(async () => {
      loadData()
    });

    async function loadData() {
      await statStorage.loadStat(date.value[0], date.value[1]);
    }

    return { chartOptions, series, stylesChart, date };
  },
};
</script>

<style scoped>
.chart-w {
  position: relative;
}
.calendar {
  position: absolute;
  right: 33px;
  top: 3px;
  width: fit-content;
}
</style>
