import { defineStore } from "pinia";
import { NotificationItem } from "@/tools/objects";

export const useNotificationsStore = defineStore({
  id: "notifications",
  state: () => ({
    items: [],
    counterId: 0,
  }),
  getters: {},
  actions: {
    addItem(type, title, text) {
      ++this.counterId;
      this.items.push(new NotificationItem(this.counterId, type, title, text));
    },
    getNewId() {
      ++this.counterId;
      return this.counterId;
    },
    delItem(id) {
      const i = this.items.indexOf((el) => el.id === id);
      this.items.splice(i, 1);
    },
  },
});
