<script setup>
import { onMounted, defineProps, ref, watch } from "vue";
import VectorLayer from "ol/layer/Vector.js";
import VectorSource from "ol/source/Vector.js";
import View from "ol/View.js";
import Map from "ol/Map.js";
import { fromLonLat } from "ol/proj";
import Overlay from "ol/Overlay.js";
// import Circle from "ol/geom/Circle.js";
import Point from "ol/geom/Point.js";
import Feature from "ol/Feature.js";
import am4geodata_data_countries2 from "@amcharts/amcharts4-geodata/data/countries2";
import { Fill, Stroke, Style } from "ol/style.js";
import CircleStyle from "ol/style/Circle.js";
import MultiPolygon from "ol/geom/MultiPolygon.js";
import Polygon from "ol/geom/Polygon.js";
import axios from "axios";
import { useLK } from "@/controllerLK/LKstore";
import { useCountryMapNewsStore } from "@/store/country-map-news";

const props = defineProps({
  onNewsClick: Function,
  onNewsClose: Function,
});
const LKstore = useLK();
const countryID = LKstore.project.countryId ?? "CU";
const countryMapNewsStore = useCountryMapNewsStore();

const getPolygonData = async () => {
  try {
    const map =
      am4geodata_data_countries2[countryID].maps[1] ||
      am4geodata_data_countries2[countryID].maps[0];
    const url = `https://cdn.amcharts.com/lib/4/geodata/json/${map}.json`;
    return await axios(url).then((res) => res.data.features);
  } catch (err) {
    console.error("ошибка загрузки данных полигона", err);
  }
};

class polygonStyle {
  constructor(imp, norm, hover, click, border = "#FFFFFF") {
    (this.important = +imp),
      (this.normal = norm),
      (this.hover = hover),
      (this.click = click),
      (this.border = border);
  }
}

const colorStyleList = [
  new polygonStyle(0, "#FFFFFF", "#FFFFFF", "#FFFFFF", "#EEEEEE"),
  new polygonStyle(1, "#97BF76", "#BFE59F", "#C4F487"),
  new polygonStyle(1.5, "#F8ECA9", "#FFEB7D", "#FFE767"),
  new polygonStyle(2, "#FBA77F", "#FBB898", "#FFA17E"),
  new polygonStyle(3, "#E56D65", "#ED8079", "#FA665C"),
];
const getStyle = (imp = 0) => colorStyleList.find((i) => i.important === imp);
const getPolygonRaiting = (imp = 0) => {
  return imp;
};
const hoveredPolygon = ref(null);

const setBGStyle = (feature, condition = "normal") => {
  const polygonStyle = getStyle(getPolygonRaiting(feature.values_.importance));
  feature.setStyle(
    new Style({
      stroke: new Stroke({
        color: "#585B5C",
        width: 1,
      }),
      fill: new Fill({
        color: polygonStyle[condition],
      }),
    })
  );
};

watch(
  () => countryMapNewsStore.getFilterPoints,
  () => {
    renderPointsOnMap();
  },
  { deep: true }
);

watch(
  () => countryMapNewsStore.changeDate,
  () => {
    updateMap();
  },
  {}
);
// Создание и настройка источника данных для точек
const vectorSourcePoint = new VectorSource();
// Создание слоя с точками
const vectorLayerPoint = new VectorLayer({
  source: vectorSourcePoint,
});

const renderPointsOnMap = () => {
  vectorSourcePoint.clear();
  countryMapNewsStore.getFilterPoints?.forEach((coord) => {
    const cities = coord.cities
    cities.forEach(city => {
      const feature = new Feature({
        geometry: new Point(fromLonLat([city.longitude, city.latitude])), // Преобразуем координаты в формат карты
        id: coord.external_id,
        geo_name: coord.geo_name,
        title: coord.title,
        importance: coord.importance,
      });
      // Создаем стиль для точек
      let zIndex = coord.importance;

      if (zIndex === 1.5) {
        zIndex = 2;
      } else if (zIndex >= 2) {
        zIndex += 1;
      }

      const pointStyle = new Style({
        image: new CircleStyle({
          radius: 10,
          fill: new Fill({ color: getStyle(coord.importance).click }), // Цвет заливки точки
          stroke: new Stroke({ color: "white", width: 2 }), // Обводка точки
        }),
        zIndex: zIndex,
      });
      feature.setStyle(pointStyle);

      vectorSourcePoint.addFeature(feature);
      vectorLayerPoint.changed();
    });
  });
};

const paintMap = () => {
  polygonData.forEach((el) => {
    const { type } = el.geometry;
    let geometry;
    if (type === "Polygon") {
      geometry = new Polygon(el.geometry.coordinates);
    } else if (type === "MultiPolygon") {
      geometry = new MultiPolygon(el.geometry.coordinates);
    }
    geometry.transform("EPSG:4326", "EPSG:3857");
    const feature = new Feature({
      geometry: geometry,
      importance: countryRaiting[el.id] || 0,
      name: el.properties.name ?? "",
      id: el.id,
    });

    const polygonStyle = getStyle(
      getPolygonRaiting(countryRaiting[el.id] || 0)
    );

    feature.setStyle(
      new Style({
        stroke: new Stroke({
          color: "#585B5C",
          width: 1,
        }),
        fill: new Fill({
          color: polygonStyle.normal,
        }),
      })
    );
    source.addFeature(feature);
  });
};

const getCountryRaiting = async () => {
  return (await countryMapNewsStore.getCountryRaiting(countryID)) || {};
};

const updateMap = async () => {
  source.refresh();
  countryRaiting = await getCountryRaiting();
  paintMap();
};

const mapRef = ref(null);
let mapIsInit = false;

const ro = new ResizeObserver(() => {
  if (!mapIsInit && mapRef.value && mapRef.value.clientWidth) {
    initMap();
  }
});

const source = new VectorSource();
let polygonData = [];
let countryRaiting = {};

const initMap = async () => {
  mapIsInit = true;
  polygonData = await getPolygonData(); //полукчаем координаты полигонов
  if (!polygonData) return null;
  countryRaiting = await getCountryRaiting();
  const today = new Date();

  const threeDaysAgo = new Date(today); // Создаем новую дату на основе сегодняшнего дня
  threeDaysAgo.setDate(today.getDate() - 3); // Устанавливаем дату три дня назад

  // Обнуление времени для точного начала дня (00:00:00)
  today.setHours(0, 0, 0, 0);
  threeDaysAgo.setHours(0, 0, 0, 0);

  countryMapNewsStore.dateFrom = Math.floor(threeDaysAgo.getTime() / 1000); // Unix Timestamp для начала дня три дня назад
  countryMapNewsStore.dateTo = Math.floor(today.getTime() / 1000); // Unix Timestamp для начала сегодняшнего дня

  await countryMapNewsStore.load();
  polygonData.forEach((el) => {
    const { type } = el.geometry;
    let geometry;
    if (type === "Polygon") {
      geometry = new Polygon(el.geometry.coordinates);
    } else if (type === "MultiPolygon") {
      geometry = new MultiPolygon(el.geometry.coordinates);
    }
    geometry.transform("EPSG:4326", "EPSG:3857");
    const feature = new Feature({
      geometry: geometry,
      importance: countryRaiting[el.id] || 0,
      name: el.properties.name ?? "",
      id: el.id,
    });

    const polygonStyle = getStyle(
      getPolygonRaiting(countryRaiting[el.id] || 0)
    );

    feature.setStyle(
      new Style({
        stroke: new Stroke({
          color: "#585B5C",
          width: 1,
        }),
        fill: new Fill({
          color: polygonStyle.normal,
        }),
      })
    );
    source.addFeature(feature);
  });
  //========================================================================================================================================================
  // Создаем и добавляем точки на карту
  renderPointsOnMap();
  //========================================================================================================================================================
  // создание карты
  const map = new Map({
    view: new View({
      zoomControl: false,
    }),
    layers: [
      new VectorLayer({
        source: source,
      }),
      vectorLayerPoint,
    ],
    target: "map",
    controls: [], // Исключаем контролы управления масштабом
  });

  //========================================================================================================================================================
  // popup
  const popup = new Overlay({
    element: document.getElementById("popup"), // HTML-элемент для отображения всплывающего окна
    positioning: "bottom-left", // позиционирование окна относительно точки
    stopEvent: false, // чтобы карту можно было интерактивно использовать под окном
    offset: [-50, -22], //Смещение
  });
  map.addOverlay(popup); // добавляем всплывающее окно на карту
  //========================================================================================================================================================

  map.on("click", function (event) {
    // Получаем объект (фичу), на котором был совершен клик
    const clickedFeature = map.forEachFeatureAtPixel(
      event.pixel,
      (feature) => feature
    );
    // Проверяем, была ли кликнута какая-то фича
    if (clickedFeature) {
      const geometry = clickedFeature.getGeometry();
      if (geometry instanceof Point) {
        // Подгрузка полной новости и появление окна с новостью
        props.onNewsClick(clickedFeature.values_.id);
      } else {
        props.onNewsClose();
      }
    } else {
      props.onNewsClose();
    }
  });

  //========================================================================================================================================================

  // hover на полигоны
  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  // Определяем вашу функцию
  const handlePointerMove = debounce((e) => {
    const hoverFeature = map.forEachFeatureAtPixel(
      e.pixel,
      (feature) => feature
    );
    if (hoverFeature) {
      const geometry = hoverFeature.getGeometry();
      const popupElement = popup.getProperties().element;
      const stylePopup = getStyle(
        getPolygonRaiting(hoverFeature.values_.importance)
      );
      if (geometry instanceof Polygon || geometry instanceof MultiPolygon) {
        popupElement.style.background = stylePopup.normal;
        if (getPolygonRaiting(hoverFeature.values_.importance) === 0) {
          popupElement.style.border = "1px solid black";
          popupElement.children[2].style.borderTop = "18px solid black";
        } else {
          popupElement.style.border = "1px solid white";
          popupElement.children[2].style.borderTop = `18px solid white`;
        }

        popupElement.children[0].innerText = hoverFeature.values_.name;
        popupElement.children[1].style.borderTop = `18px solid ${stylePopup.normal}`;
        if (!hoveredPolygon.value) {
          hoveredPolygon.value = hoverFeature;
          return setBGStyle(hoverFeature, "hover");
        }
        if (hoveredPolygon.value.values_.id !== hoverFeature.values_.id) {
          setBGStyle(hoveredPolygon.value, "normal");
          setBGStyle(hoverFeature, "hover");
          return (hoveredPolygon.value = hoverFeature);
        }
      } else if (geometry instanceof Point) {
        const stylePopup = getStyle(hoverFeature.values_.importance);
        popupElement.style.background = stylePopup.normal;
        popupElement.children[0].innerText = hoverFeature.values_.title;
        popupElement.children[1].style.borderTop = `18px solid ${stylePopup.normal}`;
        popupElement.style.border = "1px solid white";
        popupElement.children[2].style.borderTop = `18px solid white`;
        return popup.setPosition(geometry.flatCoordinates);
      }

      return popup.setPosition(e.coordinate);
    }
    popup.setPosition(null);
  }, 0);

  map.on("pointermove", handlePointerMove);

  //========================================================================================================================================================

  // Получаем границы всех объектов
  const extent = source.getExtent();
  map.getView().fit(extent, { padding: [50, 50, 50, 50] });

  return map;
};

onMounted(async () => {
  ro.observe(mapRef.value);
});
</script>
<template>
  <div id="map" ref="mapRef" />
  <div id="popup">
    <span class="text"></span>
    <span class="triangle"></span>
    <span class="triangle triangle_bg"></span>
  </div>
</template>
<style lang="scss" scoped>
@import "src/assets/styles/vars";

#map {
  height: 100%;
}

#popup {
  border-radius: 6px;
  padding: 10px 8px;
  color: black;
  max-width: 300px;
  min-width: 100px;
  position: relative;
  z-index: 3;
}

.triangle {
  display: block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: 100%;
  left: 39px;
  z-index: 2;

  &_bg {
    transform: scale(1.1);
    transform-origin: top;
    z-index: 1;
  }
}
</style>
