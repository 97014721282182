<template>
  <div class="news-container" :style="{ backgroundColor: backgroundColor }">
    <div class="news-header" :title="newsItemTitle">
      {{ newsItemTitle }}
    </div>
    <div class="news-time">{{ newsTime }}</div>
    <div class="news-text">
      {{ newsItemText }}
    </div>
    <div class="news-source">
      <i class="news-source__icon source-jpeg"></i>
      <span>Источник:</span>
      <a class="source-link" :href="newsItem.url" target="_blank">
        {{ newsItem.url }}
      </a>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { getImportanceBackground } from "@/tools/news-tools";
// import { getNewsTime } from "@/tools/date-time-tools";
import useTextHelper from "@/composition/text-helper";
import { getTimeAgo } from "@/tools/date-time-tools";
export default {
  name: "CountryPulseNewsView",
  props: ["newsItem"],
  setup(props) {
    const textHelper = useTextHelper();
    const backgroundColor = computed(() => {
      return getImportanceBackground(props.newsItem.importance || 0);
    });
    const newsTime = computed(() => {
      return getTimeAgo(props.newsItem.date);
    });
    const newsItemTitle = computed(() => {
      return textHelper.cutTags(props.newsItem.title);
    });
    const newsItemText = computed(() => {
      return textHelper.cutTags(props.newsItem.raw_text);
    });
    return {
      backgroundColor,
      newsTime,
      newsItemTitle,
      newsItemText,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/vars";
.news-container {
  // height: 207px;
  padding: 15px 20px;
  text-align: start;
  flex: 1;
  .news-header {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    // height: 47px;
    font-family: $font-roboto-medium;
    font-size: 16px;
  }
  .news-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    font-family: $font-roboto-regular;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .news-time {
    font-family: $font-roboto-regular;
    font-size: 12px;
    color: #808080;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .news-source {
    display: flex;
    gap: 5px;
    flex-wrap: nowrap;
    align-items: center;
    font-family: $font-roboto-regular;
    font-size: 14px;
    & span,a {
      font-size: 14px;
    }
    &__icon {
      flex: 0 0 21px;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      border: 1px solid #E7E7E7;
    }
  }
  .source-link {
    color: #1a64d4;
    display: block;
    overflow: hidden;
    text-wrap: none;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
