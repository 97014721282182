<template>
  <div :key="renderKey" v-if="showPanel" class="render-panel-wrapper">
    <slot> Render Panel </slot>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
export default {
  name: "RenderPanel",
  props: ["renderInterval", "enabled"],
  setup(props) {
    let handleTimeout = null;

    const renderKey = ref(0);
    const showPanel = ref(true);
    onMounted(() => {
      window.addEventListener("resize", () => {
        if (!props.enabled) {
          return;
        }
        showPanel.value = false;
        clearTimeout(handleTimeout);
        handleTimeout = setTimeout(() => {
          renderKey.value = renderKey.value + 1;
          showPanel.value = true;
        }, props.renderInterval || 1000);
      });
    });

    return { renderKey, showPanel };
  },
};
</script>

<style lang="scss">
.render-panel-wrapper {
  background: #ffffff;
  border-radius: 10px;
  padding: 1.39vw 2.08vw;
}
</style>
