import CountryAnalyticsMain from "@/components/MapPage/CountryAnalyticsView/CountryAnalyticsMain";
import CountryAnalyticsSocial from "@/components/MapPage/CountryAnalyticsView/CountryAnalyticsSocial";
import CountryAnalyticsReport from "@/components/MapPage/CountryAnalyticsView/CountryAnalyticsReport";
import CountryAnalyticsTrading from "@/components/MapPage/CountryAnalyticsView/CountryAnalyticsTrading";
import CountryAnalyticsMilitaryTrading from "@/components/MapPage/CountryAnalyticsView/CountryAnalyticsMilitaryTrading";
import CountryAnalyticsUnVote from "@/components/MapPage/CountryAnalyticsView/CountryAnalyticsUnVote";

export const countryAnalyticsRoutes = [
  {
    name: "Country.Analytics.Default",
    path: "",
    redirect: { name: "Country.Analytics.Main" },
  },
  {
    path: "social",
    name: "Country.Analytics.Social",
    props: true,
    component: CountryAnalyticsSocial,
    meta: {
      title: "Социальные сети",
      renderInMenu: true,
    },
  },
  {
    path: "main",
    name: "Country.Analytics.Main",
    props: true,
    component: CountryAnalyticsMain,
    meta: {
      title: "Все новости",
      renderInMenu: true,
    },
  },
  {
    path: "reports",
    name: "Country.Analytics.Reports",
    props: true,
    component: CountryAnalyticsReport,
    meta: {
      title: "Аналитические отчеты",
      renderInMenu: true,
    },
  },
  {
    path: "trade",
    name: "Country.Analytics.Trade",
    props: true,
    component: CountryAnalyticsTrading,
    meta: {
      title: "Торговый оборот",
      renderInMenu: true,
    },
  },
  {
    path: "military-trade",
    name: "Country.Analytics.MilitaryTrade",
    props: true,
    component: CountryAnalyticsMilitaryTrading,
    meta: {
      title: "Торговля вооружением",
      renderInMenu: true,
    },
  },
  {
    path: "un-votes",
    name: "Country.Analytics.UnVotes",
    props: true,
    component: CountryAnalyticsUnVote,
    meta: {
      title: "Голосование в ООН",
      renderInMenu: true,
    },
  },
];
