import { createApp, h } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import router from "@/router/index";
import { focus, clickOutside } from "@/tools/directives.js";
import { axiosInit } from "@/tools/axios-init.tools";
import environment from "@/environment";
import { useLK } from "@/controllerLK/LKstore.js";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "@/assets/styles/styles.scss";
import "@/assets/style/style.scss";

const app = createApp({
    render: () => h(App),
    mounted() {
        console.log('Приложение загружено!');
        const LKStore = useLK();
        window.initRouterLK(LKStore);
        window.moduleInit(environment.settings, router, LKStore);
    }
});

const pinia = createPinia();

app.use(router);
app.use(pinia);

axiosInit();

app.directive("focus", focus);
app.directive("clickOutside", clickOutside);

app.mount('#app');