<script setup>
import NotificationItem from "@/components/UI/Modal/NotificationModal/NotificationItem.vue";
import { useNotificationsStore } from "@/store/NewsFeed/notifications.js";

const notificationStore = useNotificationsStore();
</script>
<template>
  <Teleport to="body">
    <ol reversed class="notification">
      <NotificationItem
        v-for="(notif, key) in notificationStore.items"
        :key="key"
        :data="notif"
      />
    </ol>
  </Teleport>
</template>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.notification {
  position: fixed;
  right: 1.389vw;
  bottom: 1.389vw;
  z-index: 99999;
}
</style>
