<script setup>
import NewsPageAside from "@/components/NewsPage/NewsPageAside.vue";
import NewsPageNewsFeed from "@/components/NewsPage/NewsPageNewsFeed.vue";
import NewsPageBodyRoof from "@/components/NewsPage/NewsPageBody/NewsPageBodyRoof.vue";
import NewsPageBodyHeader from "@/components/NewsPage/NewsPageBody/NewsPageBodyHeader.vue";
import NewsPageBodyPost from "@/components/NewsPage/NewsPageBody/NewsPageBodyPost.vue";
import { useNewsFeedStore } from "@/store/NewsFeed/newsFeed";

const newsFeedStore = useNewsFeedStore();
</script>
<template>
  <div class="container-news">
    <NewsPageBodyRoof class="container-news__roof" />
    <NewsPageBodyHeader class="container-news__header" />
    <NewsPageAside class="container-news__aside" />
    <NewsPageNewsFeed class="container-news__newsfeed" />
    <NewsPageBodyPost
      v-if="newsFeedStore.currentNews"
      class="container-news__post"
    />
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/style/variables";

.container-news {
  display: grid;
  grid-template-columns: 231px minmax(300px, 538px) minmax(471px, 1fr);
  grid-template-rows: 40px auto 1fr;
  padding: 20px 20px 0;
  height: 93vh;
  overflow: hidden;
  gap: 10px;
  //max-width: 1920px;
  //margin: 0 auto;

  &__roof {
    grid-column: 1/4;
    //margin: 0 0 2.639vw 0;
  }

  &__header {
    grid-column: 1/4;
  }

  &__aside {
    //margin: 0 0.694vw 0 0;
    grid-column: 1/2;
  }

  &__newsfeed {
    grid-column: 2/3;
  }

  &__post {
    grid-column: 3/4;
  }
}
</style>
