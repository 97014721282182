<script setup>
import { defineProps } from "vue";
// const emit = defineEmits([])
const props = defineProps({
  checked: Boolean,
  value: Number,
  name: String,
  modelValue: Number,
});
</script>
<template>
  <div class="wrapper">
    <input
      :checked="checked"
      :name="props.name"
      class="inp-radio"
      type="radio"
      :v-model="modelValue"
      :value="props.value"
      @input="$emit('update:modelValue', props.value)"
    />
    <span class="custom-inp-radio" />
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.wrapper {
  position: relative;
}

.inp-radio {
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
  // display: none;
}

.inp-radio:checked + span {
  border: 1px solid $blue-main;

  &::after {
    content: "";
    height: 50%;
    width: 50%;
    border-radius: 50%;
    background: $blue-main;
  }
}

.custom-inp-radio {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  border-radius: 50%;
  border: 1px solid $grey-dark;
  position: relative;
}
</style>
