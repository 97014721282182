import axios from "axios";
import environment from "@/environment";
import {mainStorage} from "@/tools/main-storage.tools.js";
import {useLK} from "@/controllerLK/LKstore";
import {useNotificationsStore} from "@/store/NewsFeed/notifications.js";

function isMyUrl(url) {
  return url.toLowerCase().indexOf(environment.appConfig.baseApiUrl) === 0
    ? true
    : false;
}

export function axiosInit() {
  axios.interceptors.request.use(async (config) => {
    if (isMyUrl(config.url) && !config.url.includes("/login")) {
      // const token = await LKStore.getToken();
      const token = mainStorage.getItem("access_token");
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  });
  axios.interceptors.response.use(null, (error) => {
    const LKStore = useLK();
    const notificationStore = useNotificationsStore();
    const status = error.response.status;
    if (status === 401 || status === 403 && false) {
      LKStore.goLogin();
    } else {
      notificationStore.addItem(
        "error",
        "Произошла ошибка, повторите попытку",
        error.response?.data || error.response?.statusText,
      );
    }

    return error;
  });
}
