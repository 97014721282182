<template>
  <ul>
    <li
      v-for="option in options"
      :key="option"

      @click="setValue(option)"
    >
      <span>{{ option.label }}</span>
    </li>
  </ul>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  options: {type: Array, required: true},
})

const emits = defineEmits(['update:value'])

function setValue(value){
  emits("update:value", value)
}

</script>

<style scoped lang="scss">
@import "@/assets/styles/vars.scss";
@import "@/assets/styles/classes.scss";


.selector-default{
  border: $selector-list-border;
  border-radius: $default-border-radius;
  width: max-content;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    list-style-type: none;
    position: relative;
    cursor: pointer;
    text-align: start;
    margin: $md-gap;
    padding: $md-padding $bg-padding;
    border-radius: $default-border-radius;
    font-size: $md-font-size;
    font-family: $default-font-family;
  }
  li:hover {
    background-color: $light-blue;
  }
  .disabled {
    cursor: not-allowed;
    color: #ccc;
  }
}

</style>