<template>
  <div class="card-w col-xl-3 col-lg-4 col-md-6 col-6" v-if="topic.cards.length">
    <div class="mt-2 mb-2 ml-1 mr-1 topic-card">
      <div class="topic-header" :title="topic.name">
        {{ topic.name }}
      </div>
      <div style="height: 1px; background: #e7e7e7"></div>
      <country-pulse-news-view
        :newsItem="topic.cards[0]"
        v-if="topic.cards.length > 0"
      />
      <div style="height: 1px; background: #e7e7e7"></div>
      <country-pulse-news-view
        :newsItem="topic.cards[1]"
        v-if="topic.cards?.length > 1"
        style="
          border-top: 1px solid white;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        "
      />
    </div>
  </div>
</template>

<script>
import CountryPulseNewsView from "@/components/MapPage/CountryPulseView/CountryPulseNewsView";

export default {
  name: "CountryPulseTopicCard",
  components: { CountryPulseNewsView },
  props: ["topic"],
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/vars";
.card-w {
  margin-bottom: 20px;
}
.topic-card {
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  // height: 477px;
  .topic-header {
    font-family: $font-roboto-bold;
    font-size: 16px;
    text-align: start;
    padding: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    font-weight: bold;
  }
}
</style>
