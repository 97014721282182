export default function useTextHelper() {
    function cutTags(str) {
        if(!str) {
            return '';
        }
        return str.replace(/( |<([^>]+)>)/ig, ' ');
    }
    return {
        cutTags
    }
}