
async function changeCountryData(country,groups, groupedNum, reverse){
    // console.log(groups)
    const color =  reverse ? ['rgba(118, 221, 164,1)','rgba(196, 244, 135,1)','rgba(255, 231, 103, 1)', 'rgba(255, 144, 103,1)', 'rgba(250, 102, 92,1)'].reverse() : ['rgba(118, 221, 164,1)','rgba(196, 244, 135,1)','rgba(255, 231, 103, 1)', 'rgba(255, 144, 103,1)', 'rgba(250, 102, 92,1)']
    const activeColor = reverse ? ['rgba(38, 209, 115, 1)','rgba(168, 235, 83, 1)','rgba(255, 223, 54, 1)','rgba(254, 125, 77, 1)','rgba(252, 76, 64, 1)'].reverse() : ['rgba(38, 209, 115, 1)','rgba(168, 235, 83, 1)','rgba(255, 223, 54, 1)','rgba(254, 125, 77, 1)','rgba(252, 76, 64, 1)']
    const COUNTRYS = country.features
    const dataGroupColor = {}
    const dataRender = {}
    const arrWorldPosition = []
    
    // console.log(groupedNum)
    await getData(groups, dataGroupColor,dataRender,groupedNum,color, activeColor,arrWorldPosition)
    for(let i = 0;i<COUNTRYS.length;i++){
       
        const country = COUNTRYS[i]
        // console.log(country)
        const id = country.id
        if(dataRender[id]){
            country.properties.COLOR = dataRender[id].color
            dataRender[id].name = country.properties.name
            dataRender[id].position = (arrWorldPosition.findIndex(item => item.id == id)+1)
            // console.log(dataRender[id])
        } else {
            // console.log('================================ no data', country )
            country.properties.COLOR = 'white'
            dataRender[id] = {}
            dataRender[id].color = 'white'
            dataRender[id].activeColor = 'blue'
            dataRender[id].name = country.properties.name
            dataRender[id].position = 'Нет данных'
        }
    }
    return {COUNTRYS, dataGroupColor, dataRender}
}

async function getData(groups, dataGroupColor,dataRender,groupedNum,color, activeColor,arrWorldPosition){
    const keys = Object.keys(groups)
    for(let i=0;i<keys.length;i++){
        dataGroupColor[i] = {
            color: color[[i]],
            min:  i+1 === keys.length ? '>'+ groupedNum[i]  : groupedNum[i],
            max: i+1 === keys.length ?  ''  : groupedNum[i+1],
            countrys:[]
        }

        const group = groups[keys[i]]
        for(let j = 0;j<group.length;j++){
            let id = Object.keys(group[j])
            arrWorldPosition.push({id:id[0], num:group[j][id[0]]})
            dataRender[id[0]] = {
                color: color[i],
                activeColor: activeColor[i],
                num:group[j][id[0]],
            }
            dataGroupColor[i].countrys.push(id[0])

        }
    }
    arrWorldPosition = arrWorldPosition.sort(function(a, b) {
        return b.num - a.num;
    });
    return
}
export {changeCountryData}