<script setup>
import { computed, defineProps } from "vue";
import { designContainer } from "@/tools/data";

const props = defineProps({
  icon: {
    type: String,
    default: null,
  },
  smallSize: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    dafault: null,
  },
  size: {
    type: Number,
    default: null,
  },
});

const getIcon = computed(() => (props.icon ? "icon-" + props.icon : null));
const getButtonSize = computed(() => (props.smallSize ? "small" : null));
const getColour = computed(() => (props.color === "blue" ? props.color : null));
const getSize = computed(() =>
  props.size ? (props.size / designContainer) * 100 + "vw" : 140
);
</script>
<template>
  <button
    class="button-circle"
    :class="[getIcon, getButtonSize, getColour, getButtonSize]"
    :style="{ fontSize: getSize }"
  />
</template>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.button-circle {
  height: 40px; //40
  width: 40px;
  flex: 0 0 40px;
  cursor: pointer;
  border-radius: 50%;
  color: $dirty;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: $grey-light;

  &.blue {
    color: $blue-dark;
  }
}
</style>
