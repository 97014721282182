import { defineStore } from "pinia";
import Crud from "@/store/NewsFeed/crud";
import environment from "@/environment";
import axios from "axios";
import { useLK } from "@/controllerLK/LKstore";
// import { Rubrics } from "@/tools/objects.js";
import "vue-toast-notification/dist/theme-sugar.css";

const crud = new Crud(
  environment.appConfig.baseApiUrl + `Rubrics/`,
  "id",
  "",
  {}
);

export const useRubricsStore = defineStore({
  id: "rubrics",
  state: () => ({
    ...crud.state,
    currentRubric: null,
    record: {},
  }),
  getters: {
    ...crud.getters,
  },
  actions: {
    ...crud.actions,
    async getAllRubrics() {
      const LKStore = useLK();
      if (!LKStore.project.id) return;
      this.items = null;
      const { data } = await axios(
        this.endpoint + "project/" + `${LKStore.project.id}`
      );
      this.items = data;
      return data
    },
    async addNewRubric() {
      const LKStore = useLK();
      this.record.projectId = LKStore.project.id;
      if (!this.record.name) return;
      try {
        await axios.post(this.endpoint, this.record);
        await this.getAllRubrics();
      } catch (err) {
        console.log(err.response.data.errors);

        this.setError(err);
      }
    },
    async delRubric(rubricId) {
      try {
        await axios.delete(this.endpoint + `${rubricId}`);
        await this.getAllRubrics();
      } catch (err) {
        this.setError(err);
      }
    },
    async updateRubric() {
      if (!this.record.name) return;
      try {
        await axios.put(this.endpoint + this.record.id, this.record);
        await this.getAllRubrics();
      } catch (err) {
        this.setError(err);
      }
    },
    async setMonitoring() {
      try {
        const url = `${this.endpoint}${this.record.id}`;
        this.record.isFixed = true;
        await axios.put(url, this.record);
      } catch (err) {
        this.setError(err);
      } finally {
        this.record = {};
      }
    },
  },
});
