import { ref } from 'vue';
import useCommonChart from '@/mixins/apexChart/commonChart'

export default function useBarChart({noDataText, titleText}, categories, series,) {
    
    const chartOptions =  ref({
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
            barHeight: '70%',
          }
        },
        title: {
          text: titleText ? titleText : 'График',
          align: 'left',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '14px',
            fontWeight:  'bold',
            color:  '#000'
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: categories
        },
        noData: {
          text: noDataText ? noDataText : 'Загрузка...'
        }
    })

    useCommonChart(chartOptions, categories, series)

    return {
        chartOptions,
    };
}