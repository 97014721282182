<template>
  <div class="chart-w d-block w-100">
    <apexchart
      :options="chartOptions" 
      :series="series"
      :height="stylesChart.heightBarChart"
    />
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useCountryStatDomainRubricsStore } from "@/store/country-stat-domain-rubrics";
import { stylesChart } from '@/tools/tooltipChart'
import VueApexCharts from "vue3-apexcharts";
import useBarChart from '@/mixins/apexChart/barChart'

export default {
  name: "CommonRubricsStatBar",
  components: { apexchart: VueApexCharts, },
  setup() {
    const statStorage = useCountryStatDomainRubricsStore();

    onMounted(async () => {
      await statStorage.loadStat();
    });

    const categories = computed(() => {
      return (statStorage.items || []).map((item) => item.name);
    });


    const series = computed(() => {
      const data = (statStorage.items || []).map((item) => item.count);
      return [{
        name: name,
        data: data
      }]
    });

    const { chartOptions } = useBarChart(
      {
        titleText: 'Топ источников по рубрикам',
      },
      categories,
      series,
    )

    return { series, chartOptions, stylesChart };
  },
};
</script>

<style scoped></style>
