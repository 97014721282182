<template>
  <span class="link-body d-flex align-items-center text-start">
      {{text }}
        <a :href="href">
          <i class="link-icon d-block"></i>
        </a>
  </span>
</template>

<script>
export default {
  name: "SocialNetworkLink",
  props: ['href', 'text'],
}
</script>

<style lang="scss" scoped>
.link-icon {
  background: no-repeat center center url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'%3E%3Cpath fill='%23000' d='m12.092 1.333.575.575L6.575 8 6 7.425l6.092-6.092Z'/%3E%3Cpath fill='%23000' d='M12.667 5.143h-.762V2.095H8.857v-.762h3.81v3.81ZM10.762 12h-7.62A1.123 1.123 0 0 1 2 10.857v-7.62c0-.647.495-1.142 1.143-1.142h4.19v.762h-4.19c-.229 0-.381.152-.381.38v7.62c0 .228.152.38.38.38h7.62c.229 0 .38-.152.38-.38v-4.19h.763v4.19c0 .647-.495 1.143-1.143 1.143Z'/%3E%3C/svg%3E");
  background-size: contain;
  width: 14px;
  height: 14px;
}

.link-body {
  @import "@/assets/styles/vars.scss";
  font-family: $font-roboto-regular;
  font-size: 12px;
  color: $social-link-color;
}
</style>
