/**
 * --------------------------------------------------------
 * This demo was created using amCharts V4 preview release.
 *
 * V4 is the latest installement in amCharts data viz
 * library family, to be released in the first half of
 * 2018.
 *
 * For more information and documentation visit:
 * https://www.amcharts.com/docs/v4/
 * --------------------------------------------------------
 */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

// Create chart instance
export function showPieNewsSourcesChart(data, elementId) {

    const myData = JSON.parse(JSON.stringify(data));
    let chart = am4core.create(elementId, am4charts.PieChart);
    chart.data = myData;

// Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "cnt";
    pieSeries.dataFields.category = "source";

// Let's cut a hole in our Pie chart the size of 40% the radius
    chart.innerRadius = am4core.percent(40);

// Disable ticks and labels
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

// Disable tooltips
    pieSeries.slices.template.tooltipText = "";

// Add a legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = "right";
}
