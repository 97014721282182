<script setup>
import { reactive, ref } from "vue";
import { useCountryMapNewsStore } from "@/store/country-map-news";
import { useCountryNewsBlocksStore } from "@/store/country-news-blocks";
import { useLK } from "@/controllerLK/LKstore";
import { useRubricsStore } from "@/store/NewsFeed/rubrics";

const newsStore = useCountryMapNewsStore();
const countryNewsBlocksStore = useCountryNewsBlocksStore();
const LKStore = useLK();
const rubricsStore = useRubricsStore();

const isShowDateFilter = ref(false);
const isShowTypeFilter = ref(false);

const dateFilterList = [
  { id: 1, text: "Последний день", value: "last day" },
  { id: 2, text: "Последние три дня", value: "last three days" },
  { id: 3, text: "Последняя неделя", value: "last week" },
  { id: 4, text: "Последний месяц", value: "last month" },
];

const importantFilterList = reactive([
  {
    id: 1,
    color: "#D12D2D",
    text: "Критично",
    value: "importantNews",
    importance: newsStore.selectedNews,
  },
  {
    id: 2,
    color: "#FD815A",
    text: "Важно",
    value: "interestNews",
    importance: newsStore.selectedNews,
  },
  {
    id: 3,
    color: "#FFE34E",
    text: "Умеренно",
    value: "mediumNews",
    importance: newsStore.selectedNews,
  },
  {
    id: 4,
    color: "#519B38",
    text: "Не критично",
    value: "lightNews",
    importance: newsStore.selectedNews,
  },
]);

const currentDataFilter = ref("last three days");

const setCurrentDataFilter = (value) => {
  if (currentDataFilter.value !== value) {
    currentDataFilter.value = value;
  } else if (currentDataFilter.value === value) {
    currentDataFilter.value = null;
  }
};
const toggleImportantNews = (id) => {
  const findElement = importantFilterList.find((el) => el.id === id);
  newsStore.toggleSelectedNews(findElement.value);
};

const selectTimeFilter = async (value) => {
  newsStore.changeDate = !newsStore.changeDate;
  setCurrentDataFilter(value);

  const today = getCustomDate("today");
  const needDate = getCustomDate(value);
  newsStore.resetData();
  if (needDate === newsStore.dateFrom) {
    newsStore.dateFrom = null;
    newsStore.dateTo = null;
    await newsStore.load({ countryCode: LKStore.$state.project.id });
  }
  newsStore.dateFrom = needDate;
  newsStore.dateTo = today;
  // запрос
  await newsStore.load({ countryCode: LKStore.$state.project.id });
};
const setCurrentSelectRubric = (item) => {
  countryNewsBlocksStore.currentTypeFilter = item;
};
const getCustomDate = (type = "today") => {
  const today = new Date();
  let date;
  switch (type) {
    case "today":
      date = new Date(today);
      date.setDate(today.getDate());
      break;
    case "last day":
      date = new Date(today);
      date.setDate(today.getDate() - 1);
      break;
    case "last three days":
      date = new Date(today);
      date.setDate(today.getDate() - 3);
      break;
    case "last week":
      date = new Date(today);
      date.setDate(today.getDate() - 7);
      break;
    case "last month":
      date = new Date(today);
      date.setMonth(today.getMonth() - 1);
      break;
    default:
      date = today;
  }
  return Math.floor(date.getTime() / 1000);
};

// const formatDate = (date) => {
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const day = String(date.getDate()).padStart(2, "0");
//   return `${year}-${month}-${day}`;
// };

const closeMapFilter = () => {
  isShowDateFilter.value = false;
};
const closeRubrickList = () => {
  isShowTypeFilter.value = false;
};
</script>
<template>
  <div class="filters">
    <div v-click-outside="closeMapFilter" class="filters__date date-filters">
      <button
        @click.prevent="isShowDateFilter = !isShowDateFilter"
        class="date-filters__button"
      >
        <span class="icon-filter"></span>
      </button>
      <ul
        v-if="isShowDateFilter"
        class="date-filters__list"
        :class="{ active: isShowDateFilter }"
      >
        <li class="date-filters__title">
          <span class="icon-calendar"></span>
          Период
        </li>
        <li
          v-for="item in dateFilterList"
          :key="item.id"
          class="date-filters__item hover"
          :class="{ active: item.value === currentDataFilter }"
          @click.prevent="
            selectTimeFilter(item.value);
            isShowDateFilter = !isShowDateFilter;
          "
        >
          {{ item.text }}
        </li>
        <li class="date-filters__title">
          <span class="date-filters__icon icon-columns"></span>
          Цвет
        </li>
        <li v-for="item in importantFilterList" :key="item.id">
          <label
            @click.prevent="toggleImportantNews(item.id)"
            class="date-filters__lab date-filters__item"
            :class="{ active: newsStore.selectedNews[item.value] }"
          >
            <input
              :checked="item.importance[item.value]"
              type="checkbox"
              class="date-filters__inp"
            />
            <span class="date-filters__check date-filters__circle">
              <span class="icon-check"></span>
            </span>
            <span
              :style="{ background: item.color }"
              class="date-filters__circle"
            ></span>
            {{ item.text }}
          </label>
        </li>
      </ul>
    </div>
    <div
      v-click-outside="closeRubrickList"
      class="filters__theme theme-filters"
    >
      <button
        class="theme-filters__button"
        :class="{ active: isShowTypeFilter }"
        @click.prevent="isShowTypeFilter = !isShowTypeFilter"
      >
        {{ countryNewsBlocksStore.currentTypeFilter.name }}
        <span class="icon-arrow"></span>
      </button>
      <ul
        v-if="isShowTypeFilter"
        class="theme-filters__list"
        :class="{ active: isShowTypeFilter }"
      >
        <li
          v-if="countryNewsBlocksStore.currentTypeFilter.id !== null"
          class="theme-filters__item"
          @click="
            setCurrentSelectRubric({ id: null, name: 'All' });
            isShowTypeFilter = false;
          "
        >
          All
        </li>
        <template v-for="item in rubricsStore.items" :key="item.id">
          <li
            v-if="countryNewsBlocksStore.currentTypeFilter.id !== item.id"
            class="theme-filters__item"
            @click="
              setCurrentSelectRubric(item);
              isShowTypeFilter = false;
            "
          >
            {{ item.name }}
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.filters {
  display: flex;

  &__date {
    flex: 0 0 auto;
    margin: 0 10px 0 0;
  }

  &__theme {
    flex: 1 1 auto;
  }

  .icon-filter{
    &::before {
      font-size: 14px;
    }
  }
}

.date-filters {
  position: relative;

  &__button {
    width: 40px;
    height: 40px;
    border: #e7e7e7 solid 1px;
    box-shadow: 0 4px 4px 0 #0000000f;
    border-radius: 50%;
    background: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    transition: all 0.3s ease 0s;

    span {
      color: $blue-dark;
      font-size: 20px;
    }

    &:hover {
      border: 1px solid #1890ff;
    }
  }

  &__title {
    display: flex;
    min-height: 30px;
    padding: 0 0 0 14px;
    align-items: center;
    font-size: 14px;

    span {
      font-size: 16px;
      margin: 0 1px 0 0;
      color: #898989;
    }
  }

  &__icon {
    margin: 0 4px 0 0;
    width: 16px;
    height: 16px;
  }

  &__list {
    position: absolute;
    padding: 6px;
    right: calc(100% + 7px);
    top: 10px;
    z-index: 100;
    min-width: 249px;
    background: white;
    box-shadow: 1px 2px 10px 0 #131e2726;
    border-radius: 8px;
    list-style-type: none;
  }

  &__item {
    text-align: left;
    min-height: 30px;
    display: flex;
    align-items: center;
    padding: 0 0 0 31px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 8px;

    &:not(:last-child) {
      margin: 0 0 6px 0;
    }

    &.hover {
      &:hover {
        background: $blue-another;
      }
    }

    &.active {
      color: #1890ff;
    }
  }

  &__lab {
    position: relative;

    &.active {
      color: #1f9aff;
      background: transparent;
    }
  }

  &__item {
  }

  &__inp {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &__inp:checked + span {
    background: #1890ff;
    border: none;

    & > span {
      display: block;
    }
  }

  &__check {
    border: 1px solid #d9d9d9;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    & > span {
      font-size: 8px;
      line-height: 0;
      margin: 2px 0 0 0;
      display: none;
      color: white;
    }
  }

  &__circle {
    flex: 0 0 16px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 0 7px 0 0;
  }
}

.theme-filters {
  position: relative;
  width: 273px;

  &__button {
    background: white;
    outline: none;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 0 0 #0000000b;
    border-radius: 8px;
    min-height: 40px;
    width: 100%;
    padding: 0 15px;
    text-align: left;
    font-size: 16px;
    align-self: auto;
    transition: all 0.3s ease 0s;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      border: 1px solid #62a0ff;
      color: #62a0ff;

      & > span {
        transition: all 0.3s ease 0s;
        color: #62a0ff;
      }
    }

    span {
      color: #c4c4c4;
      font-size: 8px;
    }

    &.active {
      border: 1px solid #62a0ff;
      color: #62a0ff;

      span {
        color: #62a0ff;
      }
    }
  }

  &__arrow {
    width: 14px;
    height: 9px;
  }

  &__list {
    position: absolute;
    width: 100%;
    top: calc(100% + 10px);
    left: 0;
    z-index: 10;
    background: white;
    border-radius: 8px;
    padding: 6px;
    list-style-type: none;
    box-shadow: 1px 2px 10px 0 #131e2726;
  }

  &__item {
    min-height: 30px;
    border-radius: 8px;
    text-align: left;
    padding: 0 0 0 14px;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    color: #373d3f;

    &:not(:last-child) {
      margin: 0 0 4px 0;
    }

    &:hover {
      background: $blue-another;
    }
  }
}
</style>
