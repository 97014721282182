import axios from "axios";

export default class Crud {
  constructor(endpoint, idField = "id", resultField = "", defaultRecord = {}) {
    this.state = {
      endpoint,
      error: "",
      loading: false,
      items: null,
      cancelTokenSource: null,
      record: null,
      idField,
      resultField,
      defaultRecord,
      stateChanged: 0,
      selectedId: null,
    };
    this.getters = {
      getItems: (state) => {
        return state.items;
      },
      getRecord: (state) => {
        return state.record;
      },
      getError: (state) => {
        return state.error;
      },
      getLoading: (state) => {
        return state.loading;
      },
      data: (state) => {
        return state.items;
      },
      getCancelTokenSource: (state) => {
        return state.cancelTokenSource;
      },
      getIdField: (state) => {
        return state.idField;
      },
      getResultField: (state) => {
        return state.resultField;
      },
      isLoading: (state) => {
        return state.loading;
      },
    };

    this.actions = {
      triggerStatedChanged() {
        this.stateChanged++;
      },
      startLoading() {
        this.loading = true;
        this.error = "";
      },

      clearLoading() {
        this.loading = false;
        this.error = "";
      },

      setError(e) {
        console.log("Set Error", e);
        this.error = e;
        if (axios.isCancel(e)) {
          return;
        }
        this.loading = false;
      },

      resetData() {
        this.items = null;
        this.loading = false;
        this.error = "";
      },

      setRecord(record) {
        this.record = JSON.parse(JSON.stringify(record));
      },
      setSelectedId(id) {
        this.selectedId = id;
      },
      newRecord() {
        this.record = JSON.parse(JSON.stringify(defaultRecord));
      },

      removeRecord(id) {
        const idFieldName = this.getIdField;
        this.items = (this.items || []).filter(
          (item) => item[idFieldName] !== id
        );
      },

      clearData() {
        this.resetData();
      },

      updateItem(record) {
        if (!this.items) {
          this.items = [];
        }
        const idFieldName = this.getIdField;
        const found = this.items.findIndex(
          (item) => item[idFieldName] === record[idFieldName]
        );

        if (found < 0) {
          this.items.push(record);
          return;
        }
        this.items[found] = record;
      },

      addItems(items, clear) {

        const idFieldName = this.getIdField;
        console.log("idFieldName", idFieldName);
        if (clear) {
          this.items = []
        }

        this.items = [
          ...(this.items || []).filter(
            (item) =>
              !(items || []).find(
                (item2) => item2[idFieldName] === item[idFieldName]
              )
          ),
          ...items
        ];
        // this.items.forEach(item => {
        //   item.importance === null ? item.importance = 1 : item.importance
        // }),
          console.log('GETETETWET', this.items)
      },

      async baseLoad(payload) {
        console.log("Base Load", this.id);
        if (this.cancelTokenSource) {
          this.cancelTokenSource.cancel();
        }
        this.cancelTokenSource = axios.CancelToken.source();
        const params = { ...(payload || {}) };
        this.startLoading();
        try {
          console.log("loading: ", this.endpoint);
          const response = await axios.get(this.endpoint, {
            params,
            cancelToken: this.cancelTokenSource.token,
          });
          const resultField = this.getResultField;
          // DEBUG console.log('result',response.data );
          const items = resultField
            ? response.data[resultField]
            : response.data;
          this.addItems(items);
          this.clearLoading();
          console.log("loaded");
        } catch (error) {
          this.setError(error);
        }
      },
    };
  }
}
