export class CookieStorage {
  days = 8;
  domain = "";
  constructor(days, domain) {
    if (days) {
      this.days = days;
    }
    if (domain) {
      this.domain = domain;
    }
  }

  setItem(name, value) {
    let expires = "";
    if (this.days) {
      let date = new Date();
      date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = `${name} = ${value || ""} ${expires}; path=/; domain=${
      this.domain
    }`;
  }
  getItem(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  removeItem(name) {
    document.cookie = `${name} =; path=/; domain=${this.domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}
