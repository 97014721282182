<template>
  <div class="timeline_trade_view">
    <div id="chart-div-timeline-trade"></div>
    <ButtonsChangeView />
  </div>
</template>

<script>
import {
  disposeTimelineTrade,
  timelineTrade,
} from "@/components/MapPage/charts/timeline_trade";
import ButtonsChangeView from "@/components/MapPage/common/ButtonsChangeView.vue";

export default {
  name: "TimelineTradeView",
  components: { ButtonsChangeView },
  mounted() {
    timelineTrade("chart-div-timeline-trade");

    // test.html = this.$refs['btn-change-view']
  },
  unmounted() {
    disposeTimelineTrade();
  },
};
</script>

<style>
#chart-div-timeline-trade {
  width: 100%;
  height: calc(100vh - 90px);
}
</style>

<style lang="scss">
@import "@/assets/styles/dark.scss";
</style>
