<template>
    <div class="chart-w">
        <apexchart
            :options="chartOptions" 
            :series="series"
            :height="stylesChart.heightDonutChart"
        />
    </div>
</template>

<script>
import { onMounted, computed } from "vue";
import { useCountryStatRubricsStore } from "@/store/country-stat-regions"
import { useRubricsStore } from "@/store/NewsFeed/rubrics";
import { stylesChart } from '@/tools/tooltipChart'
import VueApexCharts from "vue3-apexcharts";
import useDonutChart from '@/mixins/apexChart/donutChart'

export default {
    name: "RubricStatRegions",
    components: { apexchart: VueApexCharts },
    setup() {
    const statStorage = useCountryStatRubricsStore();
    const rubrics = useRubricsStore()
    
    onMounted(async () => {
        try {
            if(!rubrics.items) {
                await rubrics.getAllRubrics()
            }
            await statStorage.loadStat(rubrics.items[0].id);
        } catch (err) {
            console.error(err)
        }

    });

    const labels = computed(() => {
      return (statStorage.items || []).map((item) => item.name);
    });

    const series = computed(() => {
      return (statStorage.items || []).map((item) => item.count);
    });

    // const title = computed(() => {
    //     if(!rubrics.items?.length) {
    //         return `Статистика по регионам по рубрике:`
    //     } else {
    //         return `Статистика по регионам по рубрике: ${rubrics.items[0].description}`
    //     }
    // })

    const { chartOptions } = useDonutChart(
      {
        titleText: 'Статистика по регионам по рубрике:',
      },
      labels,
      series
    )

    return { chartOptions, series, stylesChart};
  },
};
</script>

<style scoped>
.chart-w {
    height: v-bind(stylesChart.heightDonutChart);
}
</style>
  