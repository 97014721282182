<script setup>
import { useLK } from "@/controllerLK/LKstore.js";
import NotificationContainer from "@/components/UI/Modal/NotificationModal/NotificationContainer.vue"; //компонент уведомления
import { useNotificationsStore } from "@/store/NewsFeed/notifications.js";
import { watch } from "vue";
import { useRubricsStore } from "@/store/NewsFeed/rubrics";
import { useNewsFeedStore } from "@/store/NewsFeed/newsFeed";
import { useSettingsStore } from "@/store/NewsFeed/settings";

const rubricsStore = useRubricsStore();
const newsFeedStore = useNewsFeedStore();
const settingsStore = useSettingsStore();
const LKStore = useLK()
const notificationStore = useNotificationsStore();

settingsStore.loadSettings();

watch(
  () => LKStore.project?.id,
  async (id) => {
    if (!id) return;
    settingsStore.loadSettings();
    await rubricsStore.getAllRubrics();
    newsFeedStore.clearNewsFeed();
    if (rubricsStore.items.length) {
      rubricsStore.currentRubric = rubricsStore.items[0];
      await newsFeedStore.getRubricNews();
    }
  }
);
</script>

<template>
  <div
    v-if="LKStore.loadApp"
    v-show="LKStore.showContent"
    class="main-container"
  >
    <router-view />
    <NotificationContainer v-if="notificationStore.items.length" />
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/variables";

.main-container {
  background: $main-bg;
}
</style>
