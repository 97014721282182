export default {
  appConfig: {
    baseApiUrl: "/globus/api/",
  },
  devScripts: [
    "https://personal-area.globus.guru:7777/js/app.js",
    "https://personal-area.globus.guru:7777/js/chunk-vendors.js",
  ],
  prodScripts: [
    "https://personal-area.globus.guru/js/app.js",
    "https://personal-area.globus.guru/js/chunk-vendors.js",
  ],
  settings: {
    module: "module_globus",
    path: "/news",
    routing: [
      {
        icon: "map",
        route: "/map",
        text_ru: "карта"
      },
      {
        icon: "pulse",
        route: "/pulse",
        text_ru: "пульс"
      },
      {
        icon: "news",
        route: "/news",
        text_ru: "новости"
      },
      {
        icon: "settings",
        route: "/settings",
        text_ru: "настройки"
      },
      {
        icon: "analytics",
        route: "/country-data/analytics/main",
        active: '/country-data',
        text_ru: "аналитика"
      },
    ],
  },
};
