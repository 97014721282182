<template>
  <div
    v-if="selectedNews != null"
    class="selected_news"
  >
    <div class="selected_news_header">
      <article class="news-list-header">Горячие точки</article>
      <div
        class="icon-container close-icon"
        title="Закрыть новость"
        :onclick="onNewsClose"
      />
    </div>
    <!-- $router.push({
        name: 'Country.News',
        query: { id: selectedNews.id, news: 'current' },
      }) -->
    <div
      @click="
      gotoNews(selectedNews.id)
      "
      class="selected_news_title"
    >
      <div class="selected_news_title_text">{{ selectedNewsTitle }}</div>
      <div class="selected_news_info">
        <div class="selected_news_title_cards">
          <!--Важность новости и события-->
          <div
            class="new-box"
            :class="getImportance()"
          >
            {{ getImportanceText() }}
          </div>
          <!--<div class="news_event">
            События
          </div>-->
        </div>
        <div class="selected_news_title_time">
          {{ getTime() }}
        </div>
      </div>
    </div>
    <div class="selected_news_text">
      {{ selectedNewsText }}
    </div>
  </div>
</template>

<script>
import useTextHelper from "@/composition/text-helper";
import { computed } from "vue";
import { getTimeAgo } from "@/tools/functions";
import { useCountryNewsRubricsStore } from "@/store/country-news-rubrics";
import { useRouter } from 'vue-router'
export default {


  name: "CountryMapSingleNewsView",

  props: ["selectedNews"],

  emits: ["newsSingleClose"],

  methods: {
    onNewsClose() {
      this.$emit("newsSingleClose");
    },

    getImportance() {
      if (this.selectedNews.importance == 3) {
        return "news-important";
      }
      if (this.selectedNews.importance == 2) {
        return "news-medium";
      }
      if (this.selectedNews.importance == 1.5) {
        return "news-low";
      }
      return "news-common";
    },

    getImportanceText() {
      if (this.selectedNews.importance == 3) {
        return "Критично";
      }
      if (this.selectedNews.importance == 2) {
        return "Важно";
      }
      if (this.selectedNews.importance == 1.5) {
        return "Умеренно";
      }
      return "Не критично";
    },

    getTime() {
      return getTimeAgo(this.selectedNews.foundAt);
    },
  },
  setup(props) {
    const rubricsStore = useCountryNewsRubricsStore()
    const router = useRouter()

    function gotoNews(newsItem) {
      rubricsStore.selectedId = newsItem.rubricId;
      // router.push({ name: "Country.News", query: { id: newsItem.id } });
      router.push('/news');
    }
    const textHelper = useTextHelper();
    const selectedNewsTitle = computed(() => {
      return textHelper.cutTags(props.selectedNews.title);
    });
    const selectedNewsText = computed(() => {
      return textHelper.cutTags(props.selectedNews.text);
    });
    return { selectedNewsTitle, selectedNewsText, gotoNews };
  },
};
</script>

<style lang="scss">
@import "src/assets/styles/vars";
@import "src/assets/style/variables";

.selected_news {
  /* Auto layout */
  padding: 18px 20px;
  gap: 10px;

  position: absolute;
  bottom: 29px;
  left: 34px;
  width: 356px;
  height: 378px;
  //height: calc(100% - 20px);
  z-index: 99;
  background: $white;
  overflow-y: scroll;
  overflow-x: hidden;

  /* shadow */
  box-shadow: 0 4px 8px 0 #00000024;

  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 7px;
    /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: $blue-another;
    /* color of the scroll thumb */
    border-radius: 30px;
    /* roundness of the scroll thumb */
  }

  &_bg-light {
    &::-webkit-scrollbar-thumb {
      background: $grey-light;
    }
  }
}

.selected_news_title_text {
  /* H5/medium */
  //font-family: $font-roboto-bold;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: left;

  color: rgba(0, 0, 0, 0.85);
}

.selected_news_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}

.selected_news_title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 10px;
  margin-bottom: 12px;
  gap: 6px;

  width: 316px;
  min-height: 50px;

  background: #f0f0f0;
  border-radius: 6px;
  cursor: pointer;
}

.selected_news_title_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 18px;
}

.selected_news_info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.selected_news_title_cards {
  display: flex;
  flex-direction: row;
  padding-right: 8px;
}

.selected_news_title_time {
  padding-left: 8px;
  font-family: $font-roboto-regular;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.news-list-header {
  font-family: $font-news-cycle-bold;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
}

.icon-container {
  width: 15px;
  height: 15px;
}

.new-box {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 10px;

  //width: 90px;
  height: 24px;

  /* Border/card */
  border: 1px solid #e7e7e7;
  border-radius: 22px;
  /* Body 3
    Для основного текста, для бокового меню слайдера, тегов
  */
  font-family: $font-roboto-regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */
  /* Text & Icons/Text-01 (inverse) */
  color: #ffffff;
}

.news-important {
  background: #d12d2d;
}

.news-medium {
  background: #fd815a;
}

.news-low {
  background: #ffe34e;
  color: #363636;
}

.news-common {
  background: #519b38;
}

.news_event {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  margin-left: 3px;
  gap: 10px;

  width: 83px;
  height: 24px;

  /* Button/secondary-bg */

  background: #ffffff;
  /* Icon / secondary */

  border: 1px solid #626262;
  border-radius: 22px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  /* Body 3
    Для основного текста, для бокового меню слайдера, тегов
  */
  font-family: $font-roboto-regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* Text & Icons/text-02 */

  color: #373d3f;
}

.selected_news_text {
  font-family: $font-roboto-regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: left;

  max-width: 316px;
  max-height: 176px;
  /* 7 строк по 22px */
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;

  color: #373d3f;
}
</style>
