<template>
  <render-panel :enabled="renderPanelEnabled">
    <Bar :data="chartData" :options="chartOptions" :plugins="plugins" />
  </render-panel>
</template>

<script>
import { Bar } from "vue-chartjs";
import chartDataLabels from "chartjs-plugin-datalabels";
import { default as autoColors } from "chartjs-plugin-autocolors";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { computed } from "vue";
import RenderPanel from "@/components/MapPage/shared/RenderPanel";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { RenderPanel, Bar },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },
    disableDataLabels: {
      type: Boolean,
      default: false,
    },
    renderPanelEnabled: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const plugins = computed(() => {
      const arr = [];
      arr.push(autoColors);
      if (!props.disableDataLabels) {
        arr.push(chartDataLabels);
      }
      return arr;
    });

    return { plugins };
  },
};
</script>
