import TimelineTradeView from "@/components/MapPage/TimelineTradeView";
import UnVoteView from "@/components/MapPage/UnVoteView";
import MilitaryTradeView from "@/components/MapPage/MilitaryTradeView";
import TotalView from "@/components/MapPage/TotalView";
import CountryDashboardView from "@/components/MapPage/CountryDashboardView";
import CountryDataView from "@/components/MapPage/CountryDataView";
import CountryStat from "@/components/MapPage/CountryStat.vue";
import { countryDataRoutes } from "@/router/country-data";
// import { useAuthStore } from "@/store/auth";
// import LoginView from "@/components/MapPage/LoginView";

export const routesGlobus = [
  // {
  //   path: "/:pathMatch(.*)*",
  //   redirect: "/login",
  // },
  {
    path: "/timeline-trade",
    name: "Globus",
    component: TimelineTradeView,
  },
  {
    path: "/map-stat",
    name: "Map-stat",
    component: CountryStat,
  },
  // {
  //   path: "/login",
  //   name: "login",
  //   meta: {
  //     isPublic: true,
  //   },
  //   component: LoginView,
  // },
  {
    path: "/un-vote",
    name: "UnVote",
    component: UnVoteView,
  },
  {
    path: "/military-trade",
    name: "MilitaryTrade",
    component: MilitaryTradeView,
  },
  {
    path: "/total",
    name: "Total",
    component: TotalView,
  },
  {
    path: "/country-data/",
    name: "CountryDataView",
    component: CountryDataView,
    props: true,
    children: countryDataRoutes,
  },
  {
    path: "/country-map/:countryCode",
    name: "CountryMap",
    component: CountryDashboardView,
    props: true,
  },
];

// router.beforeEach(async (to, from, next) => {
//     const authStore = useAuthStore();
//     console.log('[info] ROUTER BEFORE EACH', authStore.getToken());
//     if (!to.meta.isPublic && !authStore.getToken()) {
//         console.log('goto login', authStore.getToken());
//         await router.push({name: 'login'});
//     }
//     next()
// })
