<template>
  <div>
    <div class="map-container" ref="MAPCONT" @click="checkFeature">
      <ButtonsChangeView />
      <template v-if="COUNTRYS[0] && changeFeature">
        <CountryFeature
          v-for="item in COUNTRYS"
          :data="item"
          :key="item.id"
          :type="typeFeat"
          @set-change-feature="(feat) => setChangeFeat(feat)"
          @set-feature="(feat) => setFeat(feat)"
        />
      </template>
      <template v-if="MAP">
        <div ref="BTNREF" class="zoom-control-cont">
          <div class="zoom-button" @click="changeZoom(+1)">+</div>
          <div class="zoom-button" @click="changeZoom(-1)">-</div>
        </div>
      </template>
      <StatistickPanel
        v-if="MAP"
        @set-group="
          (val) => {
            setGroup(val);
          }
        "
        :MAP="MAP"
        :text="text"
        :data="dataGroupColor"
      />
      <InfoPanel
        v-if="MAP && !useStoreDemography.activeViewGroupColor"
        :MAP="MAP"
        :data="dataRender"
        :id="activeId"
      />
      <InfoPanelGroup
        v-if="MAP && useStoreDemography.activeViewGroupColor && changeFeature"
        @set-active-group-country="(val) => launchSelectedFromGroup(val)"
        :MAP="MAP"
        :data="dataRender"
        :ids="useStoreDemography.activeViewGroup.countrys"
      />
    </div>
  </div>
</template>

<script>
import { initMap, getLayer } from "@/functions/initMap";
import { am4geodata_worldLow } from "@/geodata/4worldLow.js";
import { toRaw, ref } from "vue";
import { useCountryStatDemographyStore } from "@/store/country-stat-demography";
import { changeCountryData } from "@/functions/change-coutry-properties";
import CountryFeature from "@/components/MapPage/CountryStatDemography/CountryFeature.vue";
import InfoPanel from "@/components/MapPage/CountryStatDemography/InfoPanel.vue";
import StatistickPanel from "@/components/MapPage/CountryStatDemography/StatistickPanel.vue";
import { SetControl } from "@/components/MapPage/CountryStatDemography/SetControl";
import ButtonsChangeView from "@/components/MapPage/common/ButtonsChangeView.vue";
import InfoPanelGroup from "./InfoPanelGroup.vue";
import { getCenter } from "ol/extent";
import { getWidth, getHeight } from "ol/extent";

export default {
  components: {
    CountryFeature,
    InfoPanel,
    StatistickPanel,
    ButtonsChangeView,
    InfoPanelGroup,
  },
  props: ["item", "text", "reverse"],
  data() {
    return {
      typeFeat: "init",
      changeFeature: true,
      group: "",
      countryData: "",
      COUNTRYS: "",
      dataGroupColor: "",
      MAP: "",
      VIEW: "",
      LAYER: "",
      selected: "",
      dataRender: "",
    };
  },
  setup() {
    const MAPCONT = ref();
    const BTNREF = ref();
    const activeId = ref("");
    const featuresCount = ref(0);
    const featuresCountChange = ref(0);
    const FEATURES = ref([]);
    const useStoreDemography = useCountryStatDemographyStore();
    return {
      MAPCONT,
      featuresCount,
      FEATURES,
      featuresCountChange,
      useStoreDemography,
      BTNREF,
      activeId,
    };
  },
  async mounted() {
    console.log(this.item);
    this.group = await this.useStoreDemography.getDemographyStat(this.item);
    console.log(this.group);
    this.countryData = await changeCountryData(
      structuredClone(am4geodata_worldLow),
      this.group.groupedCountries,
      this.group.borders,
      this.reverse
    );
    this.COUNTRYS = this.countryData.COUNTRYS;
    this.dataGroupColor = this.countryData.dataGroupColor;
    this.dataRender = this.countryData.dataRender;
  },
  watch: {
    featuresCount(val) {
      if (val === this.COUNTRYS.length) {
        this.createMap();
      }
    },
    featuresCountChange(val) {
      if (val === this.COUNTRYS.length) {
        this.changeMap();
      }
    },
  },
  methods: {
    async createMap() {
      const { map, view, layer } = await initMap(
        this.MAPCONT,
        this.FEATURES,
        this.BTNREF
      );
      this.MAP = map;
      this.VIEW = view;
      this.LAYER = layer;
      SetControl(map, this.BTNREF);
    },
    async changeMap() {
      this.MAP.removeLayer(this.LAYER);
      this.MAP.setLayers([getLayer(this.FEATURES)]);
    },
    setGroup(val) {
      console.log(val);
      console.log(this.useStoreDemography);
      this.featuresCount = 0;
      this.featuresCountChange = 0;
      if (this.useStoreDemography.activeViewGroupColor === val.color) {
        this.useStoreDemography.clearGroup();
      } else {
        this.useStoreDemography.setGroup(val);
      }
      this.changeFeature = false;
      this.typeFeat = "change";
      this.FEATURES = [];
      setTimeout(() => {
        this.changeFeature = true;
      }, 300);
    },
    setFill(color) {
      const style = toRaw(this.selected.getStyle());
      const stroke = style.getFill();
      stroke.setColor(color);
      this.selected.setStyle(style);
    },
    checkFeature(e) {
      try {
        if (!e.srcElement.className) {
          let selected;
          selected = this.MAP.getFeaturesAtPixel(this.MAP.getEventPixel(e));
          if (selected) selected = selected[0];
          if (this.useStoreDemography.activeViewGroup && selected) {
            if (
              !this.useStoreDemography.activeViewGroup.countrys.find(
                (item) => item === selected.values_.name
              )
            )
              return;
          }
          this.choiceFeatureAction(selected);
        }
      } catch (error) {
        console.log(error);
      }
    },
    calculateZoomFromExtent(extent, mapSize) {
      const mapWidth = mapSize[0];
      const mapHeight = mapSize[1];
      const extentWidth = getWidth(extent);
      const extentHeight = getHeight(extent);
      const resolutionX = extentWidth / mapWidth;
      const resolutionY = extentHeight / mapHeight;
      const resolution = Math.max(resolutionX, resolutionY);
      const zoom = this.VIEW.getZoomForResolution(resolution);

      return zoom;
    },
    launchSelectedFromGroup(iso) {
      const selected = this.FEATURES.find((item) => item.values_.name === iso);
      if (selected) this.choiceFeatureAction(selected);
    },
    choiceFeatureAction(selected) {
      console.log("da");
      if (!selected) {
        if (!this.selected) {
          console.log("da1");
          return;
        } else {
          this.clearAndChangeFillSelected();
        }
      }
      if (!selected) {
        console.log("da2");
        this.clearSelected();
        return;
      }
      if (
        selected &&
        this.selected &&
        this.selected.ol_uid === selected.ol_uid
      ) {
        console.log("da3");
        return;
      }
      if (this.selected && this.dataRender[this.selected.values_.name]) {
        console.log("da4");
        this.clearAndChangeFillSelected();
      }
      if (selected) {
        console.log("da5");
        this.setSelected(selected);
      }
    },
    clearSelected() {
      this.selected = "";
      this.activeId = "";
    },
    clearAndChangeFillSelected() {
      this.setFill(this.dataRender[this.selected.values_.name].color);
      this.selected = "";
      this.activeId = "";
    },
    setSelected(selected) {
      this.selected = selected;
      this.activeId = this.selected.values_.name;
      if (this.dataRender[this.selected.values_.name])
        this.setFill(this.dataRender[this.selected.values_.name].activeColor);
      const polygon = selected.getGeometry();
      const extent = polygon.getExtent();
      this.VIEW.setCenter(getCenter(extent));
      // this.VIEW.setZoom(this.calculateZoomFromExtent(extent,this.MAP.getSize()))
      // console.log(this.VIEW.getProjection())
      return;
    },
    setFeat(feat) {
      this.FEATURES.push(feat);
      this.featuresCount++;
    },
    setChangeFeat(feat) {
      this.FEATURES.push(feat);
      this.featuresCountChange++;
      // console.log(feat)
    },
    changeZoom(val) {
      this.VIEW.setZoom(this.VIEW.getZoom() + val);
    },
  },
};
</script>

<style>
.map-container {
  height: 92.6vh;
  width: 100%;
  position: relative;
}

.zoom-control-cont {
  position: absolute;
  z-index: 10;
  bottom: 50%;
  right: 2vh;
  height: 100px;
  width: 50px;
  justify-content: space-between;
  display: flex;
  padding: 5px;
  flex-direction: column;
}

.zoom-button {
  height: 40px;
  width: 40px;
  background-color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}
</style>
