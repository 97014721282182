import {defineStore} from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import CountryData from "@/store/country-data";

const crud = new Crud(
    environment.appConfig.baseApiUrl + 'Stats/socials/',
    'id',
    '',
    {}
);
const countryData = new CountryData();

export const useCountryStatSocialsStore = defineStore({
    id: 'countryStatSocials',
    state: () => ({
        ...crud.state,
        ...countryData.state,
    }),
    getters: {
        ...crud.getters,
        ...countryData.getters,
    },
    actions: {
        ...crud.actions,
        ...countryData.actions,
        async loadStat() {
            return await this.loadCountryData({clear: true});
        },
    }
});
