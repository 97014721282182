import { defineStore } from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import CountryData from "@/store/country-data";
import { useLK } from "@/controllerLK/LKstore";
import { toTimestamp } from '@/tools/date-time-tools'

const crud = new Crud(
  environment.appConfig.baseApiUrl + "Stats/dynamic/rubrics/:projectId",
  "id",
  "",
  {}
);
const countryData = new CountryData();

export const useCountryStatDynamicRubricsStore = defineStore({
  id: "countryStatDynamicRubrics",
  state: () => ({
    ...crud.state,
    ...countryData.state,
  }),
  getters: {
    ...crud.getters,
    ...countryData.getters,
  },
  actions: {
    ...crud.actions,
    ...countryData.actions,
    async loadStat(dateFrom, dateTo) {
      const lk = useLK();
      return await this.loadCountryData({
        countryCode: lk.project.id,
        clear: true,
        params: {
          dateFrom: toTimestamp(dateFrom),
          dateTo: toTimestamp(dateTo) 
        },
      });
    },
  },
});
