<template>
  <line-chart
    :chart-data="chartData"
    :chart-options="chartOptions"
    v-if="loaded"
  />
</template>

<script>
import { computed, onMounted, reactive, ref } from "vue";
import LineChart from "@/components/MapPage/shared/LineChart";
import { useCountryUnVoteStore } from "@/store/country-unvote";
import { calcViewport } from "@/tools/news-tools";
import {
  // tooltipChart,
  // tooltipPosition,
  chartStyle,
} from "@/tools/chartCustomTooltips";
// import { Tooltip } from "chart.js";
export default {
  name: "CountryAnalyticsUnVote",
  components: { LineChart },
  props: ["countryCode"],
  setup(props) {
    const statStorage = useCountryUnVoteStore();
    const loaded = ref(false);

    onMounted(async () => {
      await statStorage.loadUnVote({ countryCode: props.countryCode });
      loaded.value = true;
    });

    function getLegendData(value) {
      return (statStorage.items || []).map((item) => item[value]);
    }

    const labelsArr = computed(() => {
      return getLegendData("year");
    });

    const cnData = computed(() => {
      return getLegendData("cn");
    });

    const usData = computed(() => {
      return getLegendData("us");
    });

    const ruData = computed(() => {
      return getLegendData("ru");
    });

    // Tooltip.positioners.myCustomPositioner = (elements, eventPosition) =>
    //   tooltipPosition(elements, eventPosition);

    const chartOptions = reactive({
      scales: {
        x: {
          display: true,
          grid: {
            display: true,
          },
          ticks: {
            align: "center",
          },
        },
        y: {
          display: true,
          position: "right",
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
      },
      plugins: {
        title: {
          ...chartStyle.title,
          text: "Голосование в ООН",
        },
        legend: {
          display: false,
        },
      },
      elements: {
        point: {
          radius: calcViewport(3),
          hoverRadius: calcViewport(5),
        },
        line: {
          tension: 0.2,
        },
      },
      responsive: true,
    });
    const chartData = reactive({
      labels: labelsArr,
      datasets: [
        {
          label: "Китай",
          data: cnData,
          backgroundColor: "#f9c954",
          borderColor: "#f9c954",
        },
        {
          label: "Россия",
          data: ruData,
          backgroundColor: "#d54141",
          borderColor: "#d54141",
        },
        {
          label: "США",
          data: usData,
          backgroundColor: "#3073d8",
          borderColor: "#3073d8",
        },
      ],
    });
    return { chartOptions, chartData, loaded };
  },
};
</script>

<style scoped></style>
