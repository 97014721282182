<template>
  <div class="leaders-details position-relative">
    <div class="block" v-if="selectedLeader">
      <country-opinion-leader-row :row="selectedLeader" />
      <br />
      <country-opinion-leader-menu />
    </div>
    <div class="screen-center" v-if="loading">
      <data-loader></data-loader>
    </div>
    <template v-else>
      <template v-for="newsItem in newsItems" :key="newsItem.id">
        <news-master-card
          :news-item="newsItem"
          @clickNewsItem="clickNewsItemHandler(newsItem)"
        />
      </template>
    </template>
  </div>
</template>

<script>
import CountryOpinionLeaderRow from "@/components/MapPage/CountryOpinionLeadersView/CountryOpinionLeaderRow";
import { computed, onMounted, watch } from "vue";
import NewsMasterCard from "@/components/MapPage/CountryNewsView/NewsMasterCard";
import CountryOpinionLeaderMenu from "@/components/MapPage/CountryOpinionLeadersView/CountryOpinionLeaderMenu";
import { useCountryPersonsStore } from "@/store/country-persons";
import { useCountryPersonNewsStore } from "@/store/country-person-news";
import DataLoader from "@/components/MapPage/shared/DataLoader";

export default {
  name: "CountryOpinionLeaderDetail",
  components: {
    DataLoader,
    CountryOpinionLeaderMenu,
    NewsMasterCard,
    CountryOpinionLeaderRow,
  },
  setup() {
    const countryPersonStore = useCountryPersonsStore();
    const newsStore = useCountryPersonNewsStore();
    const loading = computed(() => {
      return newsStore.loading;
    });

    const newsItems = computed(() => {
      return newsStore.items;
    });

    const selectedLeader = computed(() => {
      if (!countryPersonStore.selectedPersonId) {
        return null;
      }
      return countryPersonStore.items.find(
        (item) => item.id === countryPersonStore.selectedPersonId
      );
    });

    function clickNewsItemHandler(newsItem) {
      newsStore.selectedNewsId = newsItem.id;
    }

    async function fillData() {
      newsStore.selectedNewsId = null;
      await newsStore.clearData();
      if (!countryPersonStore.selectedPersonId) {
        return;
      }
      await newsStore.loadPersonNews({
        personId: countryPersonStore.selectedPersonId,
      });
      if ((newsStore.items || []).length) {
        newsStore.selectedNewsId = newsStore.items[0].id;
      }
    }

    onMounted(() => {
      fillData();
    });

    watch(
      () => countryPersonStore.selectedPersonId,
      function () {
        fillData();
      }
    );

    return {
      loading,
      newsItems,
      selectedLeader,
      clickNewsItemHandler,
    };
  },
};
</script>

<style lang="scss" scoped>
.leaders-details {
}

.block {
  background-color: white;
  text-align: left;
  list-style-type: none;
  padding: 10px 10px;
  border-radius: 8px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
}
</style>
