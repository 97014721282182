import {defineStore} from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import axios from "axios";
import { useLK } from "@/controllerLK/LKstore";

const crud = new Crud(
    environment.appConfig.baseApiUrl + 'Avl/persons/:countryCode/news/:personId',
    'id',
    '',
    {}
);

export const useCountryPersonNewsStore = defineStore({
    id: 'countryPersonNews',
    state: () => ({
        ...crud.state,
        selectedNewsId: null,
    }),
    getters: {
        ...crud.getters,
    },
    actions: {
        ...crud.actions,

        async loadPersonNews({ personId }) {
            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel();
            }
            this.cancelTokenSource = axios.CancelToken.source();
            this.startLoading();
            try {
                console.log('Person news Loading', personId);
                let url = this.endpoint;
                if (personId) {
                    const lk = useLK()
                    url = url
                        .replace(':personId', personId)
                        .replace(':countryCode', lk.project.id);
                }
                const items = (
                    await axios.get(url, { cancelToken: this.cancelTokenSource.token })
                ).data;

                this.addItems(items);
                this.clearLoading();
                console.log('Person News data Loaded', personId, this.items);

            } catch (error) {
                if (axios.isCancel(error)) {
                    return;
                } this.setError(error);
            }
        },
    }
});
