<template>
  <div class="maps-container">
    <div class="select-type">
      <DropButtonSelector
        v-model:value="statGlobalType"
        :options="optionsGlobalType"
        class="flex-grow-1"
        @label="(val) => changeMapType(val)"
      />
      <div v-if="statGlobalType" style="margin-left: 1vh">
        <DropButtonSelector
          v-model:value="statCurrentValue"
          :options="optionsCurrentType[statGlobalType]"
          class="flex-grow-1"
          @label="(val) => changeMapCurrent(val)"
          @value="(val) => changeMapCurrentVal(val)"
        />
      </div>
      <!-- <div class="description-panel">
          {{ textDescription }}
      </div> -->
    </div>
    <ButtonsChangeView />
    <DemographyMap
      v-if="statCurrentValue"
      :text="textDescription"
      :item="statCurrentValue"
      :reverse="reverseColor"
    />
  </div>
</template>

<script>
import ButtonsChangeView from "@/components/MapPage/common/ButtonsChangeView.vue";
import DemographyMap from "@/components/MapPage/CountryStatDemography/DemographyMap.vue";
import DropButtonSelector from "@/components/MapPage/common/DropButtonSelector.vue";
// import {statType, statTypes} from '@/components/MapPage/common/statSelectConst';
import { useCountryStatDemographyStore } from "@/store/country-stat-demography";
import { createSelectData } from "@/functions/createCountrySelect";

export default {
  components: {
    ButtonsChangeView,
    DemographyMap,
    DropButtonSelector,
  },
  data() {
    return {
      reverseColor: "",
      textDescription: "",
      description: "",
      statCurrentValue: "",
      statGlobalType: "",
      optionsGlobalType: "",
      optionsCurrentType: "",
      statCurrentType: "",
      useStoreDemography: useCountryStatDemographyStore(),
    };
  },
  async created() {
    const testFields = await this.useStoreDemography.getDemographyFields();
    const data = await createSelectData(testFields);
    this.description = await this.useStoreDemography.getDemographyDescription();
    this.optionsGlobalType = data.optionsGlobalType;
    this.optionsCurrentType = data.optionsCurrentType;
    this.statGlobalType = this.optionsGlobalType[0]["label"];
    this.statCurrentType =
      this.optionsCurrentType[this.statGlobalType][0]["value"];
    this.statCurrentValue =
      this.optionsCurrentType[this.statGlobalType][0]["label"];
    this.textDescription = this.description[this.statCurrentValue];
    // console.log('===================================',  this.textDescription, this.description['Плотность населения'], this.statCurrentValue)
    console.log(this.optionsGlobalType, this.optionsCurrentType);
  },
  methods: {
    changeMapType(val) {
      this.statGlobalType = "";
      this.statCurrentValue = "";
      setTimeout(() => {
        this.statGlobalType = val;
        this.statCurrentType = this.optionsCurrentType[val][0]["value"];
        this.statCurrentValue = this.optionsCurrentType[val][0]["label"];
        this.reverseColor = this.optionsCurrentType[val][0]["reverse"];
        this.textDescription = this.description[this.statCurrentValue];
      }, 100);
    },
    changeMapCurrentVal(val) {
      this.statCurrentValue = "";
      setTimeout(() => {
        this.statCurrentValue = val;
        this.textDescription = this.description[val];
        console.log(this.description);
      }, 100);
    },
    changeMapCurrent(val) {
      console.log(val);
      // this.statCurrentType = ''
      // setTimeout(()=>{
      //     this.statCurrentType = val
      // },100)
    },
  },
};
</script>

<style>
.description-panel {
  color: #212327;
  white-space: nowrap;
  display: flex;
  align-items: center;
  border: solid 1px #dedede;
  gap: 4px;
  border-radius: 10px;
  background-color: white;

  margin-left: 1vh;

  justify-content: space-between;
  padding: 8px;
}

.select-type {
  z-index: 100;
  position: absolute;
  top: 2vh;
  left: 2vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.maps-container {
  height: 92.6vh;
  width: 100%;
  position: relative;
}

.zoom-button {
  height: 40px;
  width: 40px;
  background-color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}
</style>
