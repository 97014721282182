import {defineStore} from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import axios from "axios";

const crud = new Crud(
    environment.appConfig.baseApiUrl,
    'id',
    '',
    {}
);


export const useCountryStatDemographyStore = defineStore({
    id: 'countryStatDemography',
    state: () => ({
        ...crud.state,
        activeViewGroup:'',
        activeViewGroupColor:'',
    }),
    getters: {
        ...crud.getters,
    },

    actions: {
        ...crud.actions,
        setGroup(group){
            this.activeViewGroup = group
            this.activeViewGroupColor = group.color
        },
        clearGroup(){
            this.activeViewGroup = ''
            this.activeViewGroupColor = ''
        },
        async getDemographyStat(type){
            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel();
            }
            this.cancelTokenSource = axios.CancelToken.source();
            this.startLoading();
            let url = this.endpoint + `Country/CountryGroupsUsingJenksAlgorithm/${type}?numberOfGroups=5`
            try{
                const result =  await axios.get(url, { cancelToken: this.cancelTokenSource.token })
                this.clearLoading();
                console.log(result.data)
                return result.data
            } catch  (error) {
                if (axios.isCancel(error)) {
                    return;
                } this.setError(error);
            }
        },
        async getDemographyFields(){
            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel();
            }
            this.cancelTokenSource = axios.CancelToken.source();
            this.startLoading();
            let url = this.endpoint + 'Country/FieldsForGrupping'
            try{
                const result =  await axios.get(url, { cancelToken: this.cancelTokenSource.token })
                this.clearLoading();
                console.log(result.data)
                return result.data
            } catch  (error) {
                if (axios.isCancel(error)) {
                    return;
                } this.setError(error);
            } 
        },
        async getDemographyDescription(){
            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel();
            }
            this.cancelTokenSource = axios.CancelToken.source();
            this.startLoading();
            let url = this.endpoint + 'Country/FieldsWithUnits'
            try{
                const result =  await axios.get(url, { cancelToken: this.cancelTokenSource.token })
                this.clearLoading();
                console.log(result.data)
                return result.data
            } catch  (error) {
                if (axios.isCancel(error)) {
                    return;
                } this.setError(error);
            } 
        },

    }
});
