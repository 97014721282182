<template>
  <div class="position-relative">
    <div class="screen-center">
      <data-loader v-if="personsLoading" />
    </div>
    <section class="opinion-leaders-grid">
      <country-opinion-leaders-nav />
      <template v-if="selectedPersonId">
        <country-opinion-leader-detail :country-code="countryCode" />
        <country-opinion-leader-news />
      </template>
    </section>
  </div>
</template>

<script>
import CountryOpinionLeadersNav from "@/components/MapPage/CountryOpinionLeadersView/CountryOpinionLeadersNav";
import CountryOpinionLeaderDetail from "@/components/MapPage/CountryOpinionLeadersView/CountryOpinionLeaderDetail";
import CountryOpinionLeaderNews from "@/components/MapPage/CountryOpinionLeadersView/CountryOpinionLeaderNews";
import { useCountryPersonsStore } from "@/store/country-persons";
import { computed, onMounted } from "vue";
import DataLoader from "@/components/MapPage/shared/DataLoader";

export default {
  name: "CountryOpinionLeadersView",
  components: {
    DataLoader,
    CountryOpinionLeaderNews,
    CountryOpinionLeaderDetail,
    CountryOpinionLeadersNav,
  },
  setup() {
    const personStore = useCountryPersonsStore();
    const selectedPersonId = computed(() => {
      return personStore.selectedPersonId;
    });
    const personsLoading = computed(() => {
      return personStore.loading;
    });
    onMounted(() => {
      personStore.loadCountryPersons();
    });
    return {
      selectedPersonId,
      personsLoading,
    };
  },
};
</script>

<style scoped>
.opinion-leaders-grid {
  display: grid;
  grid-template-columns: 335px 453px 1fr;
  column-gap: 10px;
  padding: 14px 0;
}
</style>
