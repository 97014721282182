<script setup></script>
<template>
  <p class="newsfeed-empty br">
    По вашему запросу ничего не найдено. Можно попробовать изменить поисковый
    запрос или почитать
    <a href="" class="newsfeed-empty__link">все новости. </a>
  </p>
</template>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.newsfeed-empty {
  background: $white;
  padding: 30px 20px;
  line-height: 140%;
  text-align: center;

  &__link {
    color: $blue-main;
    text-decoration: underline;
  }
}
</style>
