<script setup>
import ButtonCircle from "@/components/UI/ButtonCircle.vue";
import { onMounted, ref } from "vue";
import { useNewsFeedStore } from "@/store/NewsFeed/newsFeed.js";
import { getImportanceStyle } from "@/tools/UI";
import Swiper from "swiper";
import "swiper/css";
import { Pagination } from "swiper/modules";
// import { getTime } from "@/tools/functions";
import { convertTimestampToData } from "@/tools/functions.js";
import NewsPageBodyImpList from "@/components/NewsPage/NewsPageBody/Items/NewsPageBodyImpList.vue";
import { useLK } from "@/controllerLK/LKstore";

const newsFeedStore = useNewsFeedStore();
const LKstore = useLK();

const testArr = [
  {
    id: 1,
    src: "https://www.hollywoodreporter.com/wp-content/uploads/2023/08/230822_GOTRPG_GTM_WebsiteKeyArt_NoLogo_1080x1080-copy.jpg?w=1296",
  },
  {
    id: 2,
    src: "https://www.comboinfinito.com.br/principal/wp-content/uploads/2020/02/Game-of-Thrones-2.jpg",
  },
  {
    id: 3,
    src: "https://www.comboinfinito.com.br/principal/wp-content/uploads/2020/02/Game-of-Thrones-2.jpg",
  },
];

const isBigText = ref(false);
const isShowFullText = ref(false);
const isShowImportantList = ref(false);

const closeImpModal = () => {
  isShowImportantList.value = false;
};
onMounted(() => {
  return new Swiper(".swiper", {
    modules: [Pagination],
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    loop: true,
    spaceBetween: 10,
  });
});
</script>
<template>
  <div class="container-post scroll">
    <div class="container-post__wrapper br">
      <div class="container-post__header header-post">
        <div
          v-if="false"
          class="header-post__avatar avatar img"
        >
          <img
            src="https://png.pngtree.com/thumb_back/fw800/background/20230610/pngtree-picture-of-a-blue-bird-on-a-black-background-image_2937385.jpg"
            alt=""
          />
        </div>
        <div class="header-post__wrapper">
          <h3 class="header-post__title">
            {{ newsFeedStore.currentNews.title }}
          </h3>
          <div
            v-if="newsFeedStore.currentNews.domain"
            class="header-post__source"
            v-text="newsFeedStore.currentNews.domain"
          />
        </div>
        <div class="header-post__button-container">
          <ButtonCircle
            :color="isShowImportantList ? 'blue' : null"
            @click.prevent="isShowImportantList = !isShowImportantList"
            icon="location"
            class="header-post__button"
          />
          <ButtonCircle
            :color="isShowImportantList ? 'blue' : null"
            @click.prevent="isShowImportantList = !isShowImportantList"
            icon="world-web"
            class="header-post__button"
          />
          <div
            v-if="LKstore.user.roleName === 'аналитик'"
            v-click-outside="closeImpModal"
            class="header-post__button header-post__button_edit"
          >
            <ButtonCircle
              :color="isShowImportantList ? 'blue' : null"
              @click.prevent="isShowImportantList = !isShowImportantList"
              icon="edit"
            />
            <div
              v-if="isShowImportantList"
              class="header-post__list"
            >
              <NewsPageBodyImpList @close="isShowImportantList = false" />
            </div>
          </div>
          <div class="header-post__button">
            <ButtonCircle
              icon="bookmark"
              class="header-post__button"
            />
          </div>
        </div>
      </div>
      <div class="container-post__body body-post">
        <div
          v-html="newsFeedStore.currentNews.raw_text"
          class="body-post__text"
          :class="{ active: isShowFullText }"
        />
        <span
          class="body-post__text-shadow"
          v-if="!isShowFullText && isBigText"
        ></span>
        <span
          v-if="isBigText"
          @click="isShowFullText = !isShowFullText"
          class="body-post__more cursor"
        ><template v-if="!isShowFullText">Читать полностью</template>
          <template v-else>Свернуть</template>
        </span>
        <div
          v-if="false"
          class="body-post__images"
        >
          <div class="swiper">
            <div class="swiper-wrapper">
              <div
                v-for="img in testArr"
                :key="img.id"
                class="swiper-slide img br"
              >
                <img
                  :src="img.src"
                  alt=""
                />
              </div>
            </div>

            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
      <div class="container-post__footer footer-post">
        <div class="footer-post__tags tags-post">
          <div class="tags-post__wrapper">
            <!-- Отрисовка тэгов -->
            <button
              v-if="newsFeedStore.currentNews.importance"
              :style="{
            ...getImportanceStyle(newsFeedStore.currentNews.importance)
              .style,
          }"
              class="tags-post__button tag"
            >
              {{
            getImportanceStyle(newsFeedStore.currentNews.importance).text
          }}
            </button>
            <button
              v-if="newsFeedStore.currentNews.city"
              class="tags-post__button tag"
            >
              {{ newsFeedStore.currentNews.city }}
            </button>
          </div>
          <span
            class="tags-post__time"
            v-text="convertTimestampToData(+newsFeedStore.currentNews.date)"
          />
        </div>
        <div class="footer-post__source source-post">
          <div
            v-if="false"
            class="source-post__avatar img bdr"
          >
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSb_N4umbFejqkrOVdFJX9rV6DZHsm9oyzJsQ9vXqgI6Q&s"
              alt=""
            />
          </div>
          <p class="source-post__text">
            Источник:
            <a
              target="_blank"
              :href="newsFeedStore.currentNews.url"
              v-text="newsFeedStore.currentNews.url"
            />
          </p>
        </div>
        <div class="newsfeed-item__chunk footer-newsfeed">
          <div class="footer-newsfeed__item">
            <span class="footer-newsfeed__name">Feed url: </span>
            <a
              :href="newsFeedStore.currentNews.url"
              target="_blank"
              class="footer-newsfeed__value"
            >{{ newsFeedStore.currentNews.domain }}</a>
          </div>
          <div class="footer-newsfeed__item">
            <span class="footer-newsfeed__name">id: </span>
            <span class="footer-newsfeed__value">{{ newsFeedStore.currentNews.id }}</span>
          </div>
          <!-- <div class="footer-newsfeed__item">
            <span class="footer-newsfeed__name">title: </span>
            <span
              class="footer-newsfeed__value"
              v-for="(item, i) in newsFeedStore.currentNews.cities"
              :key="i"
            >{{ item.city }}{{ i === newsFeedStore.currentNews.cities.length - 1 ? '' : ', ' }}</span>
          </div> -->
        </div>
        <!-- <div class="footer-post__info info-post">
          <div
            v-for="icon in socialIsonList"
            :key="icon.id"
            class="info-post__item"
          >
            <SocialRateCounter :icon="icon.value" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<style
  lang="scss"
  scoped
>
@import "@/assets/style/variables.scss";

.container-post {
  height: 100%;
  padding: 0 10px 10px 0;
  color: $black-light;
  &__wrapper {
    background: $white;
    padding: 0 30px 30px;
  }

  &__header {
    padding: 30px 0 20px;
    position: sticky;
    top: 0;
    z-index: 2;
    background: #fff;
    gap: 20px;
    justify-content: space-between;
  }
}

.header-post {
  display: flex;
  align-items: flex-start;

  &__avatar {
    width: 38px;
    height: 38px;
    margin-right: 8px;
  }

  &__wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__button-container {
    display: flex;
    gap: 10px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    overflow-wrap: break-word;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    overflow: hidden;
    // -webkit-line-clamp: 2;
  }

  &__source {
    color: $dirty;
    line-height: 18px;
  }

  &__button {
    &:hover {
      color: $blue-main;
    }

    &>button {
      background: $grey-light;
    }

    &_edit {
      position: relative;

      &>button {
        transition: color 0.3s ease 0s;

        &:hover {
          color: $blue-main;
        }
      }
    }
  }

  &__list {
    z-index: 3;
    position: absolute;
    top: 0;
    right: 3.09vw;
  }
}

.body-post {
  &__text {
    width: 100%;
    // max-height: 30.181vw;
    // overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &.active {
      max-height: fit-content;
      overflow: auto;
    }

    img {
      width: 100%;
    }
  }

  &__source {}

  &__text-shadow {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.7) 50%,
        rgb(255, 255, 255) 100%);
  }

  &__more {
    display: inline-block;
    margin: 0px 0px 20px 0px;
    font-size: 16px;
    color: $blue-main;
  }

  // &__images {
  //   max-width: 50.5vw;
  // }
}

.footer-post {
  color: $dirty;

  &__tags {}

  &__source {}

  &__info {}
}

.tags-post {
  display: flex;
  margin-top: 20px;
  align-items: flex-end;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
  }

  &__button {
    width: max-content;
    padding: 2px 12px;
    background: $grey-light;
    border-radius: 22px;
    cursor: pointer;
    min-height: auto;
    height: 24px;

    &:not(:last-child) {
      margin: 0px 0.694vw 1.389vw 0px;
    }
  }

  &__time {
    font-size: 12px;
  }
}

.source-post {
  display: flex;
  align-items: center;
  margin-top: 20px;

  &__avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;
  }

  &__text {
    color: $grey-dark;
    display: flex;
    gap: 5px;

    &>a {
      display: block;
      color: $blue-main;
      cursor: pointer;
      max-width: 21.667vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.info-post {
  display: flex;
  color: $dirty;
  align-items: center;

  &__item {
    margin: 0px 1.389vw 0px 0px;
    cursor: pointer;
  }
}


.footer-newsfeed {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;


  &__value {
    color: $blue-dark;
    text-decoration: underline;
  }
}
</style>

<style lang="scss">
@import "@/assets/style/variables.scss";

.swiper-slide {
  height: 21.458vw;
  overflow: hidden;
  // margin: 0px 1.389vw 0px 0px;
}

.swiper-pagination {
  margin: 1.389vw 0px 0px 0px;
  margin: 0.347vw 0px;
  text-align: center;
}

.swiper-pagination-bullet {
  display: inline-block;
  width: 0.556vw;
  height: 0.556vw;
  background: $main-bg;
  border-radius: 50%;

  &:not(:last-child) {
    margin: 0px 0.347vw 0px 0px;
  }
}

.swiper-pagination-bullet-active {
  background: $blue-main;
}

.body-post__text {
  * {
    font-size: 14px;
    line-height: 18px;
  }

  img {
    width: 100%;
    border-radius: 8px;
  }

  video {
    max-width: 100%;
  }
}
</style>
