import { defineStore } from "pinia";
import environment from "@/environment";
import Crud from "@/store/crud";
import CountryData from "@/store/country-data";
import { useLK } from "@/controllerLK/LKstore";

const crud = new Crud(
  environment.appConfig.baseApiUrl + "News/sources/:countryCode",
  "id",
  "",
  {}
);
const countryData = new CountryData();
export const useCountryNewsSourcesStore = defineStore({
  id: "countryNewsSources",
  state: () => ({
    ...crud.state,
    ...countryData.state,
  }),
  getters: {
    ...crud.getters,
    ...countryData.getters,
  },
  actions: {
    ...crud.actions,
    ...countryData.actions,
    async loadCountryNewsSources() {
      const LKStore = useLK();
      return await this.loadCountryData({
        countryCode: LKStore.project.countryId,
      });
    },
  },
});
