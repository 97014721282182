import {defineStore} from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import CountryData from "@/store/country-data";

const countryCrud = new Crud(
    environment.appConfig.baseApiUrl
    + ':requestType/byCountry/:countryCode',
    'id',
    '',
    {}
);

const  countryData = new CountryData();

export const useCountryUnVoteStore = defineStore({
    id: 'countryUnVote',
    state: () => ({
        ...countryCrud.state,
        ...countryData.state,
    }),
    getters: {
        ...countryCrud.getters,
        ...countryData.getters,
    },
    actions: {
        ...countryCrud.actions,
        ...countryData.actions,
        async loadUnVote({countryCode}) {
            return await this.loadCountryData({requestType: 'Unvote', countryCode})
        }
    }
});
