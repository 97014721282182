<template>
  <div class="military_trade_view"></div>
  <div id="chartdiv"></div>
</template>

<script>
import { timelineMilitaryTrade } from "@/components/MapPage/charts/militarytrade";

export default {
  name: "MilitaryTradeView",
  mounted() {
    timelineMilitaryTrade();
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/dark.scss";
</style>
