import { defineStore } from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import CountryData from "@/store/country-data";
import { useLK } from "@/controllerLK/LKstore";

const crud = new Crud(
  environment.appConfig.baseApiUrl + "Stats/common/rubrics/:projectId",
  "id",
  "",
  {}
);
const countryData = new CountryData();

export const useCountryStatCommonRubricsStore = defineStore({
  id: "countryStatCommonRubrics",
  state: () => ({
    ...crud.state,
    ...countryData.state,
  }),
  getters: {
    ...crud.getters,
    ...countryData.getters,
  },
  actions: {
    ...crud.actions,
    ...countryData.actions,
    async loadStat() {
      const lk = useLK();
      if (!lk.project.id) return;
      return await this.loadCountryData({
        countryCode: lk.project.id,
        clear: true,
      });
    },
  },
});
