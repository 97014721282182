<template>
  <bar-chart
    :chart-data="chartData"
    :chart-options="chartOptions"
    :disableDataLabels="true"
    v-if="loaded"
    :render-panel-enabled="false"
  />
</template>

<script>
import BarChart from "@/components/MapPage/shared/BarChart";
import { computed, onMounted, ref } from "vue";
import { useCountryTradeStore } from "@/store/country-trade";
import { calcViewport } from "@/tools/news-tools";
import {
  tooltipChart,
  tooltipPosition,
  chartStyle,
} from "@/tools/chartCustomTooltips";
import { Tooltip } from "chart.js";

export default {
  name: "CountryAnalyticsTrading",
  components: { BarChart },
  props: ["countryCode"],
  setup(props) {
    const statStorage = useCountryTradeStore();
    const loaded = ref(false);

    onMounted(async () => {
      await statStorage.loadTrade({ countryCode: props.countryCode });
      loaded.value = true;
    });

    const labelsArr = computed(() => {
      return getLegendData("year");
    });

    function getLegendData(value) {
      return (statStorage.items || []).map((item) => item[value]);
    }

    const cnData = computed(() => {
      return getLegendData("cn");
    });

    const usData = computed(() => {
      return getLegendData("us");
    });

    const ruData = computed(() => {
      return getLegendData("ru");
    });

    Tooltip.positioners.myCustomPositioner = (elements, eventPosition) =>
      tooltipPosition(elements, eventPosition);

    const chartOptions = {
      indexAxis: "x",
      maintainAspectRatio: false,

      plugins: {
        dataLabels: {
          display: false,
        },
        title: {
          ...chartStyle.title,
          text: "Торговый оборот",
        },
        legend: {
          ...chartStyle.legendBottomBox,
        },
        tooltip: {
          enabled: false,
          position: "myCustomPositioner",
          external: tooltipChart,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: false,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: false,
          border: {
            display: false,
          },
        },
      },
    };

    const chartData = ref({
      labels: labelsArr,
      datasets: [
        {
          label: "Россия",
          data: ruData,
          backgroundColor: "#D12D2D",
          borderRadius: calcViewport(8),
          hoverBackgroundColor: "#D30F0F80",
        },
        {
          label: "США",
          data: usData,
          backgroundColor: "#1A64D4",
          borderRadius: calcViewport(8),
          hoverBackgroundColor: "#1a64d480",
        },
        {
          label: "Китай",
          data: cnData,
          backgroundColor: "#F9C442",
          borderRadius: calcViewport(8),
          hoverBackgroundColor: "#f9c44280",
        },
      ],
    });

    return { chartOptions, chartData, loaded };
  },
};
</script>

<style lang="scss"></style>
