<template>
  <div class="pie-chart-view">
    <div
      :id="chartElementId"
      class="chart-div"
      :style="`height: ${height}`"
    ></div>
  </div>
</template>

<script>
import { computed, onMounted, watch } from "vue";
import { getUID } from "bootstrap/js/src/util";
import { showPieNewsSourcesChart } from "@/components/MapPage/charts/pie-news-sources-chart";

export default {
  name: "PieChartView",
  props: ["dataSource", "height"],

  setup(props) {
    const uid = getUID("pie_chart");
    const chartElementId = computed(() => {
      return `chart_${uid}`;
    });

    function fillData() {
      if (!props.dataSource?.length) {
        return;
      }
      showPieNewsSourcesChart(props.dataSource, chartElementId.value);
    }

    onMounted(() => {
      fillData();
    });
    watch(
      () => props.dataSource,
      function () {
        fillData();
      }
    );
    return { chartElementId };
  },
};
</script>

<style lang="scss">
.lines-chart-view {
  .chart-div {
    height: 100%;
    width: 100%;
  }
}
</style>
