<script setup>
import { defineProps } from "vue";
import ButtonCircle from "@/components/UI/ButtonCircle.vue";
import { getImportanceStyle } from "@/tools/UI.js";
import { useNewsFeedStore } from "@/store/NewsFeed/newsFeed";
import { convertTimestampToData } from "@/tools/functions.js";

const props = defineProps({
  data: Object,
});
const newsFeedStore = useNewsFeedStore();
const addToFavorite = () => {
  console.log("addToFavorite", props.data.id);
};
const setCurrentNews = () => {
  console.log("setCurrentNews", props.data.id);
  newsFeedStore.currentNews = props.data;
};

const getActiveClass = () => {
  return (
    newsFeedStore.currentNews?.id === props.data.id &&
    !!newsFeedStore.currentNews?.id
  );
};
</script>
<template>
  <div
    @click="setCurrentNews"
    class="newsfeed-item br"
    :class="{
      active: getActiveClass(),
    }"
  >
    <div class="newsfeed-item__chunk header-newsfeed">
      <div v-if="data.image" class="header-newsfeed__img img avatar">
        <img :src="data.image" alt="news picture" />
      </div>
      <h4 v-text="props.data.title" class="header-newsfeed__title" />

      <ButtonCircle
        class="header-newsfeed__button"
        @click="addToFavorite"
        icon="bookmark"
      />
    </div>
    <p v-text="props.data.text" class="newsfeed-item__chunk body-newsfeed" />
    <div class="newsfeed-item__chunk info-newsfeed">
      <div class="info-newsfeed__wrapper">
        <button
          v-if="props.data.importance"
          class="info-newsfeed__button"
          :style="getImportanceStyle(+props.data.importance).style"
        >
          {{ getImportanceStyle(+props.data.importance).text }}
        </button>
        <button
          v-if="props.data.city"
          class="info-newsfeed__button info-newsfeed__button_event"
        >
          {{ props.data.city }}
        </button>
      </div>
      <span class="info-newsfeed__time">
        {{ convertTimestampToData(props.data.date) }}
      </span>
      <span class="info-newsfeed__counter icon-heart">4.7к</span>
      <span class="info-newsfeed__counter icon-message-circle">2.7к</span>
    </div>
    <div class="newsfeed-item__chunk footer-newsfeed">
      <div class="footer-newsfeed__item">
        <span class="footer-newsfeed__name">Feed url: </span>
        <a :href="props.data.url" target="_blank" class="footer-newsfeed__value">{{ props.data.domain }}</a>
      </div>
      <div class="footer-newsfeed__item">
        <span class="footer-newsfeed__name">id: </span>
        <span class="footer-newsfeed__value">{{props.data.id}}</span>
      </div>
      <div class="footer-newsfeed__item" v-if="props.data.cities.length">
        <span class="footer-newsfeed__name">title: </span>
        <span class="footer-newsfeed__value" v-for="(item, i) in props.data.cities" :key="i">{{ item.city }}{{ i === props.data.cities.length - 1 ? '' : ', ' }}</span>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.newsfeed-item {
  background: $white;
  padding: 20px;
  cursor: default;

  &:hover {
    background: $blue-light;
  }

  &__chunk {
    &:not(:last-child) {
      margin: 0 0 20px 0;
    }
  }

  &.active {
    background: $dirty;
    color: $white;

    .info-newsfeed__time {
      color: $white;
    }

    .footer-newsfeed {
      color: $white;
    }

    .info-newsfeed__counter {
      color: $white;
    }

    .footer-newsfeed__value {
      color: $white;
    }
  }
}

.header-newsfeed {
  display: flex;
  align-items: center;

  &__img {
    margin: 0 8px 0 0;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    flex: 1 1 auto;
    align-self: center;
    overflow-wrap: break-word;
    margin: 0 8px 0 0;
    max-width: 422px;
    line-height: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
  }

  &__button {
    font-size: 12px;
  }
}

.body-newsfeed {
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.info-newsfeed {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0 10px 0 0;
  }

  &__button {
    min-height: 24px;
    border-radius: 22px;
    padding: 0 12px;
    cursor: pointer;
    flex: 0 0 auto;

    &_event {
      background: $grey-light;
      color: $dirty;
    }
  }

  &__time {
    flex: 1 1 auto;
    min-width: fit-content;
    text-align: right;
    color: $dirty;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 10px 0 0;
  }

  &__counter {
    font-size: 12px;
    display: flex;
    align-items: center;
    color: $dirty;

    &:before {
      margin: 0 5px 0 0;
      font-size: 14px;
    }

    &:last-child {
      margin: 0 0 0 10px;
    }
  }
}

.footer-newsfeed {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  &__item {
  }

  &__name {
  }

  &__value {
    color: $blue-dark;
    text-decoration: underline;
  }
}
</style>
