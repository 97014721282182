<template>
  <div class="chart-w d-block w-100">
    <apexchart
      :options="chartOptions" 
      :series="series"
      :height="stylesChart.heightBarChart"
    />
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useCountryStatCommonBlocksStore } from "@/store/country-stat-common-blocks";
import { stylesChart } from '@/tools/tooltipChart'
import VueApexCharts from "vue3-apexcharts";
import useBarChart from '@/mixins/apexChart/barChart'

export default {
  name: "CommonBlocksStatBar",
  components: { apexchart: VueApexCharts, },
  setup() {
    const statStorage = useCountryStatCommonBlocksStore();
    onMounted(async () => {
      await statStorage.loadStat();
    });

    const categories = computed(() => {
      return (statStorage.items || []).map((item) =>item.name);
    });

    const series = computed(() => {
      const data = (statStorage.items || []).map((item) => item.count);
      return [{
        name: '',
        data: data
      }]
    });


    const { chartOptions } = useBarChart(
      {
        titleText: 'Общая статистика по темам',
      },
      categories,
      series,
    )

    return { chartOptions, series, stylesChart};
  },
};
</script>

<style scoped>
</style>
