export function formatDocDate(n) {
    if (!n) return '';
    const dt =new Date(n);
    return dt.toLocaleDateString('ru-RU');
}

export function formatTime(n) {
    if(!n) return '';
    const dt = new Date(n);
    return  dt.toLocaleTimeString('ru-RU');
}

export function formatDateTime(n) {
    if(!n) return '';
    return formatDocDate(n) + ' ' + formatTime(n)
}

export function getNewsTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - date.getTime();

    let minutesDifference = Math.floor(timeDifference / 1000 / 60);
    if(minutesDifference < 0) { minutesDifference = 0; }
    const hoursDifference = Math.floor(timeDifference / 1000 / 60 / 60);


    if(hoursDifference > 12) {
        return  formatDateTime(dateTimeString);
    }
    if (hoursDifference > 0) {
        return `${hoursDifference} ч назад`;
    }
    return `${minutesDifference} мин назад`;
}


export function getHoursAgo(dateTimeString) {
    // Создаем объект Date из строки с датой и временем
    const dateTime = new Date(dateTimeString);
  
    // Получаем текущую дату и время
    const now = new Date();
  
    // Вычисляем разницу в миллисекундах между текущим временем и заданным временем
    const timeDiff = now - dateTime;
  
    // Вычисляем количество часов, разделив разницу на количество миллисекунд в одном часе
    const hoursAgo = Math.floor(timeDiff / (1000 * 60 * 60));
  
    return hoursAgo;
  }

export function convertDesignToApi(s) {

    try {
        if(!s) {return null}
        const arr = (s || '').split('.');
        if (arr.length >= 3) {
            return `${arr[2]}-${arr[1]}-${arr[0]}`;
        }
    }
        // eslint-disable-next-line no-empty
    catch (s) {}
    return  s;

}

export function  convertApiToDesign(s) {
    try {
        const arr = (s || '').split('-');
        if (arr.length >= 3) {
            return `${arr[2]}.${arr[1]}.${arr[0]}`;
        }
        // eslint-disable-next-line no-empty
    }catch (e) {}

    return s;

}

export function getTimeAgo(dateTimeString) {
  if (typeof dateTimeString === "number") {
    return formatTimeDifference(dateTimeString);
  }
  const now = new Date();
  const pastDate = new Date(dateTimeString);
  const diffMs = now - pastDate;
  const diffSeconds = Math.floor(diffMs / 1000);

  if (diffSeconds < 60) {
    return "меньше минуты назад";
  } else if (diffSeconds < 3600) {
    const diffMinutes = Math.floor(diffSeconds / 60);
    return `${diffMinutes} ${getNoun(
      diffMinutes,
      "минуту",
      "минуты",
      "минут"
    )} назад`;
  } else if (diffSeconds < 86400) {
    const diffHours = Math.floor(diffSeconds / 3600);
    return `${diffHours} ${getNoun(diffHours, "час", "часа", "часов")} назад`;
  } else if (diffSeconds < 2592000) {
    const diffDays = Math.floor(diffSeconds / 86400);
    return `${diffDays} ${getNoun(diffDays, "день", "дня", "дней")} назад`;
  } else if (diffSeconds < 31104000) {
    const diffMonths = Math.floor(diffSeconds / 2592000);
    return `${diffMonths} ${getNoun(
      diffMonths,
      "месяц",
      "месяца",
      "месяцев"
    )} назад`;
  } else {
    const diffYears = Math.floor(diffSeconds / 31104000);
    return `${diffYears} ${getNoun(diffYears, "год", "года", "лет")} назад`;
  }
}
function getNoun(number, one, few, many) {
  const absNumber = Math.abs(number);
  if (absNumber % 10 === 1 && absNumber % 100 !== 11) {
    return one;
  } else if (
    absNumber % 10 >= 2 &&
    absNumber % 10 <= 4 &&
    (absNumber % 100 < 10 || absNumber % 100 >= 20)
  ) {
    return few;
  } else {
    return many;
  }
}
function formatTimeDifference(timestamp) {
  const now = Math.floor(Date.now() / 1000); // текущее время в секундах
  const timeDifference = now - timestamp;

  const minute = 60;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day;
  const year = 365 * day;

  if (timeDifference < minute) {
    return "только что";
  } else if (timeDifference < hour) {
    const minutes = Math.floor(timeDifference / minute);
    return `${minutes} ${getEnding(
      minutes,
      "минуту",
      "минуты",
      "минут"
    )} назад`;
  } else if (timeDifference < day) {
    const hours = Math.floor(timeDifference / hour);
    return `${hours} ${getEnding(hours, "час", "часа", "часов")} назад`;
  } else if (timeDifference < week) {
    const days = Math.floor(timeDifference / day);
    return `${days} ${getEnding(days, "день", "дня", "дней")} назад`;
  } else if (timeDifference < month) {
    const weeks = Math.floor(timeDifference / week);
    return `${weeks} ${getEnding(weeks, "неделю", "недели", "недель")} назад`;
  } else if (timeDifference < year) {
    const months = Math.floor(timeDifference / month);
    return `${months} ${getEnding(months, "месяц", "месяца", "месяцев")} назад`;
  } else {
    const years = Math.floor(timeDifference / year);
    return `${years} ${getEnding(years, "год", "года", "лет")} назад`;
  }
}

function getEnding(number, one, two, many) {
  const absNumber = Math.abs(number);
  if (absNumber % 10 === 1 && absNumber % 100 !== 11) {
    return one;
  } else if (
    [2, 3, 4].includes(absNumber % 10) &&
    ![12, 13, 14].includes(absNumber % 100)
  ) {
    return two;
  } else {
    return many;
  }
}

export function getTime(date) {
  if (typeof date === "number") {
    return convertUnixTimestampToTime(date);
  }
  // Создаем объект Date из строки
  const dateTimeObj = new Date(date);

  // Извлекаем часы и минуты из объекта Date
  const hours = dateTimeObj.getHours();
  const minutes = dateTimeObj.getMinutes();

  // Форматируем часы и минуты в нужный вид
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  // Получаем строку времени в нужном формате (часы:минуты)
  return `${formattedHours}:${formattedMinutes}`;
}

export function convertUnixTimestampToTime(unixTimestamp) {
  // Создаем новый объект Date, используя Unix-метку времени
  const date = new Date(unixTimestamp * 1000);

  // Получаем часы, минуты и секунды из объекта Date
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Собираем время в формате "часы:минуты"
  const time = `${hours}:${minutes}`;

  return time;
}

export function toTimestamp(date) {
  if (!(date instanceof Date)) {
    throw new TypeError("Invalid date object");
  }
  return Math.floor(date.getTime() / 1000);
}