<template>
  <div class="menu d-flex align-items-center justify-content-start">
    <template v-for="menuItem in menuItems"  :key="menuItem.id">
      <router-link
          class="menu-item"
          :to="{name: menuItem.id}"
          :class="{'router-link-active': menuItem.active}"
      >{{ menuItem.name }}
      </router-link>
    </template>
  </div>
</template>

<script>
import {computed} from "vue";
import {countryDataRoutes} from "@/router/country-data";
import {useRoute} from "vue-router/dist/vue-router";


export default {
  name: "CountryMenu",
  setup() {
    const activeRoute = useRoute();
    const menuItems = computed( () => {
      return countryDataRoutes
          .filter( route => route.meta?.renderInMenu )
          .map ( route => ({ id: route.name, name: route.meta.title, active: route.name === activeRoute.name }));
    });
    return {menuItems};
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars.scss";
.menu-item {
  color: black;
  text-decoration: none;
  font-family: $font-roboto-regular;
  font-size: 14px;
  line-height: 24px;
  transition: .2s;
  position: relative;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -2px;
    transition: .2s;
  }
}

.router-link-active {
  color: #1890FF !important;
  &::after {
    background: #1890FF;
  }
}

.menu {
  gap: 25px;
}
</style>
