<template>
  <div class="analytics-grid">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "CountryAnalyticsView",
  props: ["countryCode"],
};
</script>

<style lang="scss" scoped>
.analytics-grid {
  padding: 14px 0;
}

.analytics-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}
</style>
