<template>
  <div class="country-pulse-view">
    <div class="topic-panel row mt-2">
      <template v-for="topic in topics" :key="topic.id">
        <country-pulse-topic-card :topic="topic" />
      </template>
    </div>
  </div>
</template>

<script>
import { useLK } from "@/controllerLK/LKstore.js";
import { computed, onMounted, watch } from "vue";
import { useCountryNewsPulseStore } from "@/store/country-news-pulse";
import CountryPulseTopicCard from "@/components/MapPage/CountryPulseView/CountryPulseTopicCard";

export default {
  name: "CountryPulseView",
  components: { CountryPulseTopicCard },
  props: ["countryCode"],
  setup() {
    const LKStore = useLK();
    const pulseStore = useCountryNewsPulseStore();

    const topics = computed(() => {
      return pulseStore.items;
    });

    const loadData = async () => {
      try {
        await pulseStore.loadCountryNewsPulse();
      } catch(err) {
        console.error(err)
      }
    }

    watch(
      () => LKStore.project?.id,
      async () => {
        loadData()
      }
    )

    onMounted(async () => {
      loadData()
    });


    return { topics };
  },
};
</script>

<style lang="scss" scoped>
.topic-panel {
  font-family: "Roboto", serif;
  font-size: 14px;
}

.row {
  --bs-gutter-x: 1.3rem;
}
</style>
