<template>
  <div class="charts-sections country-analytics-social" :key="LK.project.id">
    <section class="news-tone row-section">
      <div class="rubrics-tone half-panel">
        <messengers-stat-bar />
      </div>

      <div class="main-tone half-panel">
        <socials-stat-bar />
      </div>
    </section>
  </div>
</template>

<script>
import MessengersStatBar from "@/components/MapPage/CountryAnalyticsView/MessengersStatBar";
import SocialsStatBar from "@/components/MapPage/CountryAnalyticsView/SocialsStatBar";
import { useLK } from "@/controllerLK/LKstore.js";

export default {
  name: "CountryAnalyticsSocial",
  components: {
    SocialsStatBar,
    MessengersStatBar,
  },
  setup() {
    const LK = useLK();

    return{
      LK
    }
  }
};
</script>

<style lang="scss" scoped>

</style>