<script setup>
import NewsPageBodyNewsfeedItem from "@/components/NewsPage/NewsPageBody/NewsPageBodyNewsfeedItem.vue";
import NewsPageBodyEmptyNews from "@/components/NewsPage/NewsPageBody/NewsPageBodyEmptyNews.vue";
import LocalLoader from "@/components/UI/LocalLoader";
import { useNewsFeedStore } from "@/store/NewsFeed/newsFeed.js";
// import { useRubricsStore } from "@/store/rubrics";
import { ref } from "vue";

const newsFeedStore = useNewsFeedStore();
// const rubricsStore = useRubricsStore();

const newsFeed = ref(null);

// function checkFilter(array1, array2) {
//   return array1 ? array1.some(element => array2.includes(element.city)) || !array2.length : true
// }

            // v-show="checkFilter(post.cities, newsFeedStore.$state.filter[0].filterList)"

// const lazyLoad = async () => {
//   if (newsFeedStore.loading) return;
//   const scrollTop = newsFeed.value.scrollTop;
//   const scrollHeight = newsFeed.value.scrollHeight;
//   const clientHeight = newsFeed.value.clientHeight;
//   if (scrollTop + clientHeight >= scrollHeight - 200) {
//     await newsFeedStore.getRubricNews();
//   }
// };
</script>
<template>
  <div class="container-main">
    <div class="container-main__newsfeed newsfeed">
      <template v-if="newsFeedStore.getFiltredItems?.length">

        <!-- @scroll="lazyLoad" -->
        <div
          ref="newsFeed"
          class="newsfeed__wrapper scroll"
        >
          <div
            class="newsfeed__item"
            v-for="(post, key) in newsFeedStore.getFiltredItems || []"
            :key="key"
          >
            <NewsPageBodyNewsfeedItem :data="post" />
          </div>
          <div
            v-if="newsFeedStore.loading"
            class="newsfeed__loader"
          >
            <LocalLoader />
          </div>
        </div>
      </template>
      <div
        v-else-if="newsFeedStore.loading"
        class="newsfeed__loader"
      >
        <LocalLoader />
      </div>
      <NewsPageBodyEmptyNews v-else />
    </div>
  </div>
</template>
<style
  lang="scss"
  scoped
>
@import "@/assets/style/variables.scss";

.container-main {
  display: flex;
  height: 100%;

  &__newsfeed {
    width: 100%;
  }

  &__post {
    flex: 1 1 auto;
    max-height: calc(100vh - 7vh - 20px - 2.917vw - 0.694vw - 2.639vw - 0.486vw);
  }

  &__post-wrapper {
    padding: 0px 0.347vw 0px 0px;
  }
}

.newsfeed {
  &__wrapper {
    max-height: 51.042vw;
    max-height: calc(100vh - 7vh - 20px - 2.917vw - 0.694vw - 2.639vw - 0.486vw);
    overflow-y: scroll;
    padding: 0px 0.347vw 10px 0px;
  }

  &__item {
    &:not(:last-child) {
      margin: 0px 0px 5px 0px;
    }
  }

  &__loader {
    display: flex;
    justify-content: center;
    padding: 1.389vw 0px;
  }
}
</style>
