<template>
  <div>
    <search-input />
    <country-opinion-leaders-list />
  </div>
</template>

<script>
import SearchInput from "@/components/MapPage/shared/SearchInput";
import CountryOpinionLeadersList from "@/components/MapPage/CountryOpinionLeadersView/CountryOpinionLeadersList";

export default {
  name: "CountryOpinionLeadersNav",
  components: { CountryOpinionLeadersList, SearchInput },
};
</script>

<style scoped></style>
