<template>
  <tr class="header-row">
    <th >Название отчета</th>
    <th>Дата размещения</th>
    <th>Действие</th>
  </tr>
</template>

<script>
export default {
  name: "CountryAnalyticsReportTableHeader"
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/vars";
.header-row {
  background-color: $table-header-background;
  th:first-child {
    width: max-content;
  }
  th {
    width: 179px;
    padding: 10px;
    font-family: $font-roboto-medium;
    font-size: 14px;
    border-bottom: 1px solid $button-border-color;
  }
  th:last-child {
    width: 124px;
  }
}
</style>
