<template>
  <div class="chart-w d-block w-100">
    <apexchart
      :options="chartOptions" 
      :series="series"
      :height="stylesChart.heightBarChart"
    />
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useCountryStatSocialsStore } from "@/store/country-stat-socials";
import { stylesChart } from '@/tools/tooltipChart'
import VueApexCharts from "vue3-apexcharts";
import useBarChart from '@/mixins/apexChart/barChart'

export default {
  name: "SocialsStatBar",
  components: { apexchart: VueApexCharts},
  setup() {
    const statStorage = useCountryStatSocialsStore();

    onMounted(async () => {
      await statStorage.loadStat();
    });

    const categories = computed(() => {
      if (!statStorage.items) return [];
      const obj = statStorage.items[0];
      const res = [];
      for (let key in obj) {
        res.push(key);
      }
      return res;
    });

    const series = computed(() => {
      if (!statStorage.items) return [];
      const obj = statStorage.items[0];
      const res = [];
      for (let key in obj) {
        res.push(obj[key].total);
      }
      return [{
        name: '',
        data: res
      }]
    });

    const { chartOptions } = useBarChart(
      {
        titleText: 'Статистика по социальным сетям',
      },
      categories,
      series,
    )

    return { chartOptions, series, stylesChart};
  },
};
</script>

<style scoped></style>
