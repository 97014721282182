<template>
    <div ref="INFOREFGROUP" class="info-panel-group">
        <div v-for="item in sortData" :key="item.id" class="info-item" @click="setActiveCountry(item.id)">
                <p :style="`color:${active !== item.id ? 'black' : item.color}`" class="panel-desc">{{item.position + '.' + item.name}}</p>
                <div :style="`border-bottom: 1px dashed ${active !== item.id ? 'black' : item.color};`" class="stat-border-line"></div>
                <p :style="`color:${active !== item.id ? 'black' :item.color}`" class="panel-desc" style="margin-left:0;">{{item.num}}</p>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { SetControl, delControl } from './SetControl'
export default {
    props:['MAP','data', 'ids'],
    setup(){
        const INFOREFGROUP = ref()

        const CONTROL = ref(null)
        return {
            INFOREFGROUP,
            CONTROL
        }
    },
    data(){
        return{
            active:'',
            sortData:'',
        }
    },
    mounted(){
        console.log(this.data, this.ids)
        const data = structuredClone(this.data)
        let newData = []
        for(let i = 0;i < this.ids.length;i++){
            data[this.ids[i]].id = this.ids[i]
            newData.push(data[this.ids[i]])
        }
        this.sortData = newData.sort(function(a, b) {
            return b.num - a.num;
        });
        console.log(newData)
        this.CONTROL = SetControl(this.MAP, this.INFOREFGROUP)
    },
    methods:{
        setActiveCountry(item){
            console.log(item)
            this.active = item
            this.$emit('set-active-group-country', item)
        }
    },
    unmounted(){
        delControl(this.MAP, this.CONTROL)
    }
}
</script>

<style>
.info-item{
    cursor: pointer;
    width:100%;
    height:3vh;
    display:flex;
    align-items: center;
    justify-content: space-between;
    
}
.info-panel-group{
    overflow-y: auto;
    z-index: 100;
    position: absolute;
    padding: 1vh;
    bottom: 2vh;
    right: 2vh;
    height: 45%;
    width: 20%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.14);

}
</style>