<template>
  <div class="country-analytics-report">
    <section class="header">
      <span class="header__title"> Аналитические отчеты</span>
      <!-- <button class="load-button" >
              +&nbsp; Загрузить новый отчет
          </button> -->
      <div class="wrapper">
        <label class="button search">
          <input
            type="text"
            class="button-search"
            placeholder="Поиск по отчетам"
          />
          <img
            class="icon-search cursor"
            src="@/assets/icon-fonts/icons/search.svg"
            alt="icon search"
          />
        </label>
        <FilterDropdawn
          :list="filterList"
          class="button"
          @setFilterRule="(id) => setFilterRule(id)"
          :currentFilter="currentFilter"
        />

        <label class="button cursor load" v-if="true">
          <input
            class="load-button-inp"
            type="file"
            accept="application/pdf"
            ref="inputFile"
            @input="uplodFile"
          />
          <img src="@/assets/icons/plus.svg" alt="plus icon" />
          <span class="load-button-text">Загрузить новый отчет</span>
        </label>
      </div>
    </section>

    <country-analytics-report-table
      :currentFilter="currentFilter"
      :data-source="dataSource"
      :loading="loading"
    />
  </div>
</template>

<script>
import CountryAnalyticsReportTable from "@/components/MapPage/CountryAnalyticsView/CountryAnalyticsReportTable";
import { useCountryReportFilesStore } from "@/store/country-report-files";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useLK } from "@/controllerLK/LKstore";
import FilterDropdawn from "@/components/MapPage/common/FilterDropdawn.vue";

export default {
  name: "CountryAnalyticsReport",
  props: ["countryCode"],
  components: { CountryAnalyticsReportTable, FilterDropdawn },
  setup() {
    const inputFile = ref(null);
    const reportFilesStore = useCountryReportFilesStore();
    const LK = useLK();

    const setFilterRule = (id) => {
      const result = filterList.find((el) => el.id === id);
      return (currentFilter.value = result);
    };
    const uplodFile = async () => {
      const formData = new FormData();
      formData.append("files", inputFile.value.files[0]);
      await reportFilesStore.uploadFile("Reports", LK.project.id, formData);
      return await reportFilesStore.loadCountryReportFiles({
        projectId: LK.project.id,
      });
    };

    const dataSource = computed(() => {
      return reportFilesStore.items;
    });

    const loading = computed(() => {
      return reportFilesStore.loading;
    });
    const filterList = reactive([
      {
        id: 1,
        name: "Сначала новые",
      },
      {
        id: 2,
        name: "Сначала старые",
      },
    ]);
    const currentFilter = ref(filterList[0]);

    const loadData = () => {
      reportFilesStore.loadCountryReportFiles({
        projectId: LK.project.id,
      });
    }

    watch(
      () => LK.project?.id,
      async () => {
        loadData()
      }
    )

    onMounted(() => {
      loadData()
    });

    return {
      dataSource,
      loading,
      uplodFile,
      currentFilter,
      filterList,
      setFilterRule,
      inputFile,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/vars";

.country-analytics-report {
  background-color: white;
  text-align: left;
  border-radius: 8px;
  min-height: 400px;
  margin-top: 20px;
  padding: 20px;

  .header {
    display: flex;
    align-items: flex-start;

    flex-direction: column;
    padding: 10px;
    @media (min-width: 1200px) {
      flex-direction: row;
      align-items: center;
    }

    &__title {
      flex: 0 0 auto;
      font-size: 16px;
      font-family: $font-roboto-medium;
      margin: 0px 0px 16px 0px;
      @media (min-width: 1024px) {
        margin: 0px 20px 0px 0px;
      }
    }
  }

  .wrapper {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .button {
    flex: 0 0 auto;
    font-family: $font-roboto-regular;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid $button-border-color;
    background-color: white;
    position: relative;
    padding: 0 16.5px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    outline: none;

    &:not(:last-child) {
      margin: 0px 16px 0px 0px;
    }
  }

  .button.search {
  }

  .button.load {
    padding: 0px 12px;

    & > img {
      margin: 0px 2px 0px 0px;
    }
  }

  .button.filter {
    flex: 0 0 220px;
  }

  .form {
    flex: 0 0 auto;
    @media (min-width: 1024px) {
      flex: 0 0 198px;
    }
  }

  .load-button-inp {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: -1;
    box-sizing: border-box;
  }

  .load-button-text {
    line-height: 0;
    color: $font-dark-color;
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }
  }

  .button-search {
    border: 0;
    height: 100%;
    width: 100%;
    outline: none;
    color: $font-dark-color;
    font-size: $sm-font-size;
    font-weight: $default-font-weight;

    &::placeholder {
      color: $gray-placeholder;
    }
  }

  .cursor {
    cursor: pointer;
  }

  .icon-search {
    position: absolute;
    right: 15px;
    top: 10px;
    width: 14px;
    height: 14px;
  }
}
</style>
