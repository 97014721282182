import { ref, watch, onMounted } from 'vue';

export default function useFilterDate(loadData) {
    let date = ref([])

    const initDate = () => {
        const currentDate = new Date();
        const startDate = new Date(currentDate.setDate(currentDate.getDate() - 31));
        const endDate = new Date();
  
        date.value = [startDate, endDate];
    }

    watch(
        () => date,
        () => {
            loadData()
        },
        {deep: true}
    );

    onMounted(async () => {
        initDate()
    });

    return {
        date,
        initDate
    };
}