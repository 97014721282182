<template>
  <div class="search-wrapper">
    <div class="form-control d-flex search-input w-100">
      <input
        placeholder="Поиск"
        class="w-100"
        v-model="value"
        @keydown.enter="$emit('searchSubmit')"
      />
      <button
        class="btn btn-primary d-flex align-items-center justify-content-center"
        @click="$emit('searchSubmit')"
      >
        <i
          class="search-icon"
          style="width: 20px; height: 20px; display: inline-block"
        ></i>
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "SearchInput",
  props: ["modelValue"],
  emits: ["update:modelValue", "searchSubmit"],
  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: (v) => emit("update:modelValue", v),
    });
    return { value };
  },
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  padding: 0px 14px 0px 7px;
}
.search-input {
  border-radius: 10px;
  border-bottom: 1px silver solid;
  position: relative;

  // margin: 0px 0px 0px 20px;
  height: 42px;
  input {
    outline: none;
    border: none;
    :focus,
    :focus-visible {
      border: none;
    }
  }
  button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 54px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
