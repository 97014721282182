export function checkIfValidIP(str) {
  // Regular expression to check if string is a IP address
  const regexExp =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
  return regexExp.test(str);
}

export function getDomain(hostname, level) {
  const domains = hostname.split(".");
  if (domains.length <= level || checkIfValidIP(hostname)) {
    return hostname;
  }
  let res = "";
  for (let i = 1; i <= level; i++) {
    if (res) {
      res = "." + res;
    }
    res = domains[domains.length - i] + res;
  }
  return res;
}

export function getCookieDomain(domain) {
  if (domain === "localhost") {
    return "";
  }
  return domain;
}
