import { defineStore } from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import CountryData from "@/store/country-data";
import { useLK } from "@/controllerLK/LKstore";

const crud = new Crud(
  environment.appConfig.baseApiUrl + "Block/:projectId",
  "id",
  "",
  {}
);
const countryData = new CountryData();

export const useCountryNewsBlocksStore = defineStore({
  id: "countryNewsBlocks",
  state: () => ({
    ...crud.state,
    ...countryData.state,
    currentTypeFilter: { id: null, name: "All" },
  }),
  getters: {
    ...crud.getters,
    ...countryData.getters,
  },
  actions: {
    ...crud.actions,
    ...countryData.actions,
    async loadCountryNewsBlocks() {
      const LKstore = useLK();
      const countryCode = LKstore.project.id;
      return await this.loadCountryData({ countryCode });
    },
    resetFilter() {
      this.clearData();
      this.currentTypeFilter = {
        id: null,
        name: "All",
      };
    },
  },
});
