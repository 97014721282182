<template>
  <div class="chart-w d-block w-100"
  >
    <apexchart
      :options="chartOptions"
      :series="series"
      :height="stylesChart.heightBarChart"
    />
    <!-- <div v-else
      :style="{height: stylesChart.heightBarChart}"
    >

    </div> -->
    <div class="chart__filter"
      :class="{'draw-stats': categories.length}"
    >
      <TheCalendar class="calendar"
        v-model="date"
      />
      <dropdown-base
        v-model="checkedCities"
        v-model:isActive="citiesDropdownIsActive"
        :items="citiesDropdown"
        :activeItems="citiesForResponse"
        title="Регионы"
      />
      <dropdown-base
        v-model="rubricToResponse"
        v-model:isActive="rubricsDropdownIsActive"
        :items="rubricsDropdown"
        :activeItem="rubricToResponse"
        title="Рубрика"
        type="single"
      />
      <button class="search-btn button button_icon-left"
        :disabled="searchBtnIsDisabled"
        @click="search()"
      >
        <!-- <span class="button__icon icon-search"></span> -->
        Найти
      </button>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useAnalyticsRegionsRubrics } from "@/store/analytics-regions-rubrics";
import { stylesChart } from '@/tools/tooltipChart'
import { useLK } from "@/controllerLK/LKstore";
import { useRubricsStore } from "@/store/NewsFeed/rubrics";
import VueApexCharts from "vue3-apexcharts";
import axios from "axios";
import environment from "@/environment";
import dropdownBase from '@/components/UI/DropdownBase'
import TheCalendar from "@/components/UI/TheCalendar"
import useFilterDate from '@/mixins/apexChart/filterDate'
import useBarChart from '@/mixins/apexChart/barChart'
import useCommonChart from '@/mixins/apexChart/commonChart'

export default {
  name: "CommonRubricsStatBar",
  components: { apexchart: VueApexCharts, dropdownBase, TheCalendar },
  setup() {
    const { date } = useFilterDate(search)
    const statStorage = useAnalyticsRegionsRubrics();
    const rubricsStore = useRubricsStore()
    const lk = useLK()

    let cities = ref([])
    let checkedCities = ref([])
    let citiesDropdownIsActive = ref(false)

    let rubrics = ref([])
    let rubricsDropdownIsActive = ref(false)
    let rubricToResponse = ref(null)

    const getCities = async () => {
      try {
        const url = environment.appConfig.baseApiUrl + `Cities/Cities/${lk.project.countryId}`
        const response = await axios(url)
        cities.value = [...response.data]
      } catch(err) {
        console.error(err)
      }
    }

    const getRubrics = async () => {
      try {
        const response = await rubricsStore.getAllRubrics()
        rubrics.value = [...response]
      } catch(err) {
        console.error(err)
      }
    }

    async function search()  {
      if(searchBtnIsDisabled.value) return false
      try {
        await statStorage.loadStat(
          {
            rubric: rubricToResponse.value,
            cities: citiesForResponse.value,
            dateFrom: date.value[0],
            dateTo: date.value[1]
          }
        )
        if(!categories.value.length) {
          updateOptions({noData: {text:"По вашему запросу ничего не найдено"}})        
        }
      } catch(err) {
        console.error(err)
      }
    }

    const citiesDropdown = computed(() => {
      return cities.value.map(el => ({
        value: el.id,
        text: el.city,
      }));
    });

    const rubricsDropdown = computed(() => {
      return rubrics.value.map(el => ({
        value: el.id,
        text: el.name,
      }));
    });

    let citiesForResponse = computed(() => {
      const cities = []
      checkedCities.value.forEach(el => {
        cities.push(el.text)
      });
      return cities
    })

    const categories = computed(() => {
      return (statStorage.items || []).map((item) => item.name);
    });

    const series = computed(() => {
      const data = (statStorage.items || []).map((item) => item.count);
      return [{
        name: name,
        data: data
      }]
    });

    const searchBtnIsDisabled = computed(() => {
      return !( citiesForResponse.value.length && rubricToResponse.value )
    })

    const { chartOptions } = useBarChart(
      {
        titleText: 'Топ источников по рубрикам',
        noDataText: 'Выберите регион и рубрику'
      },
      categories,
      series,
    )
    const { updateOptions } = useCommonChart(chartOptions)

    onMounted(async () => {
      try {
        if(!rubricsStore.items) {
            await getRubrics()
        } else {
          rubrics.value = [ ...rubricsStore.items ]
        }
        await getCities()
      } catch (err) {
          console.error(err)
      }
    });

    return { search, date, searchBtnIsDisabled, categories, rubricToResponse, series, chartOptions, stylesChart, citiesDropdown, rubricsDropdown, checkedCities, citiesForResponse, citiesDropdownIsActive, rubricsDropdownIsActive };
  },
};
</script>

<style scoped>
.list {
  height: 200px;
  overflow: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.list-item {
  display: flex;
  gap: 10px;
  align-items: center;
}

.search-btn {
  min-height: auto;
  font-size: 16px;
  max-height: 45px;
}
</style>
