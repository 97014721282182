export const importantStyleList = [
  {
    id: 1,
    text: "Все",
    importance: 0,
    style: {
      background: "#1A64D4",
      color: "#FFFFFF",
    },
  },
  {
    id: 2,
    text: "Не критично",
    importance: 1,
    style: {
      background: "#C4F487",
      color: "#373D3F",
    },
  },
  {
    id: 3,
    text: "Умеренно",
    importance: 1.5,
    style: {
      background: "#FFE767",
      color: "#373D3F",
    },
  },
  {
    id: 4,
    text: "Важно",

    importance: 2,
    style: {
      background: "#FF9067",
      color: "#FFFFFF",
    },
  },
  {
    id: 5,
    text: "Критично",
    importance: 3,
    style: {
      background: "#FA665C",
      color: "#FFFFFF",
    },
  },
];

export function getImportanceStyle(importance = 0) {
  if (typeof importance !== "number") {
    return importantStyleList.find((el) => el.importance === 0);
  }
  return importantStyleList.find((el) => el.importance === importance);
}

export const socialIsonList = [
  {
    id: 1,
    value: "like",
  },
  {
    id: 2,
    value: "dislike",
  },
  {
    id: 3,
    value: "message-circle",
  },
  {
    id: 4,
    value: "eye",
  },
];
