<template>
  <ul class="left-nav">
    <li
      v-for="(item, key) in dataSource"
      v-show="checkComment(item)"
      :key="item.path"
      :class="{ selected: router.currentRoute.value.fullPath.includes(item.path) }"
      @click="
        handleMenuItemClick(item.id, key);
        newsStore.newsPage = 0;
      "
    >
      {{ item.name }}
    </li>
  </ul>
</template>

<script>
import { useCountryNewsStore } from "@/store/country-news";
import { useCountryNewsRubricsStore } from "@/store/country-news-rubrics";
import { onMounted, ref } from "vue";
import router from '@/router/index'

export default {
  name: "LeftNavView",
  props: ["dataSource", "selectedId", "block"],
  emits: ["itemClick"],
  setup(props, { emit }) {
    const newsStore = useCountryNewsStore();
    const rubricsStore = useCountryNewsRubricsStore();
    let currentItemKey = ref(0);

    async function handleMenuItemClick(itemId, key) {
      emit("itemClick", itemId);
      currentItemKey.value = key;
    }

    function checkComment(item) {
      const noRen = [
        "Country.Analytics.Trade",
        "Country.Analytics.MilitaryTrade",
        "Country.Analytics.UnVotes",
      ];
      const res = noRen.find((it) => it === item.id);
      if (res) return false;
      return true;
    }

    function setCurrentItemKey() {
      for (let i = 0; i < props.dataSource.length; i++) {
        if (props.dataSource[i].id === rubricsStore.selectedId) {
          currentItemKey.value = i;
          break;
        }
      }
    }

    onMounted(() => {
      if (props.block === "news") {
        setCurrentItemKey();
      }
    });
    return {
      checkComment,
      handleMenuItemClick,
      newsStore,
      currentItemKey,
      router,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars.scss";

.left-nav {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding-left: 0px;
  margin-top: 10px;
  gap: 25px;
  li {
    font-family: $font-roboto-regular;
    font-size: 14px;
    padding: 0px 0px;
    color: black;
    cursor: pointer;
  }

  .selected {
    color: rgba(31, 154, 255, 1);
  }
}

.right-nav-header {
}
</style>
