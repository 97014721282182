import { watch } from 'vue'
export default function useCommonChart(chartOptions, categories, series) {

  function updateOptions(customOptions) {
    chartOptions.value = {
      ...chartOptions.value,
      ...customOptions
    }
  }

  watch(
    () => [categories, series],
    () => {
      if(!categories.value.length) {
        updateOptions({noData: {text:"По вашему запросу ничего не найдено"}})        
      } else {
        updateOptions()        
      }
    },
    {deep: true}
  )
  
  return {
    updateOptions
  };
}