<script setup>
import { importantStyleList } from "@/tools/UI";
import CustomRadio from "@/components/Chunks/CustomRadio.vue";
import { useNewsFeedStore } from "@/store/NewsFeed/newsFeed";
import { useCardsStore } from "@/store/NewsFeed/cards";
import { defineEmits, ref } from "vue";

const newsFeedStore = useNewsFeedStore();
const cardsStore = useCardsStore();

const emit = defineEmits(["close"]);
const selectedImportance = ref(newsFeedStore.currentNews.importance ?? 0);

const updateImportance = async () => {
  const response = await cardsStore.updateImportance(selectedImportance.value);
  if (response.status === 200) {
    newsFeedStore.currentNews.importance = selectedImportance.value;
  }
  emit("close");
};
const setImportance = (imp) => {
  if (selectedImportance.value === imp) {
    return (selectedImportance.value = 0);
  }
  selectedImportance.value = imp;
};
</script>
<template>
  <section class="list br">
    <h4 class="list__title">
      <span class="icon-edit" />
      Критичность
    </h4>
    <template v-for="item in importantStyleList" :key="item.id">
      <label
        @click.prevent="setImportance(item.importance)"
        v-if="item.importance !== 0"
        class="list__item cursor"
      >
        <span class="list__space">
          <CustomRadio
            :checked="item.importance === selectedImportance"
            :value="item.importance"
            name="news_importance"
            v-model="selectedImportance"
          />
        </span>
        <span :style="item.style" class="list__space list__circle" />
        {{ item.text }}
      </label>
    </template>
    <button @click.prevent="updateImportance" class="list__btn button">
      Сохранить изменения
    </button>
  </section>
</template>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.list {
  background: $white;
  min-width: 230px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-shadow: 0px 9px 28px 8px #0000001a;
  color: $black;

  &__title {
    padding: 0 0 0 14px;
    min-height: 30px;
    display: flex;
    align-items: center;

    & > span {
      display: inline-block;
      margin: 0 4px 0 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    flex: 0 0 32px;
    padding: 0 0 0 32px;
  }

  &__space {
    margin: 0 7px 0 0;
    line-height: 0;
  }

  &__circle {
    flex: 0 0 16px;
    height: 16px;
    border-radius: 50%;
  }

  &__btn {
    width: 100%;
    min-height: 40px;
  }
}
</style>
