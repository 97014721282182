<template>
  <div class="d-flex gap-4 align-items-center justify-content-start pt-1">
    <template v-for="menuItem in menuItems"  :key="menuItem.id">
      <a
          href="#"
          class="menu-item"
          :class="{'router-link-active': selectedId === menuItem.id}"
          @click.prevent="selectedId = menuItem.id"
      >{{ menuItem.name }}
      </a>
    </template>
  </div>
</template>

<script>
import {reactive, ref} from "vue";

export default {
  name: "CountryOpinionLeaderMenu",
  setup() {
    const menuItems = reactive([
      { id: 1, name: 'Информация', page: 'info'},
      { id: 2, name: 'Статьи', page: 'news'},
    ]);
    const selectedId = ref(2);
    return {menuItems, selectedId};
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars.scss";
.router-link-active {
  color: #1890FF !important;
  border-bottom: 2px #1890FF solid;
}

.menu-item {
  color: black;
  text-decoration: none;
  font-family: $font-roboto-regular;
  font-size: 14px;
  line-height: 24px;
}
</style>
