<template>
  <div class="un_vote_view"></div>
  <div id="chartdiv"></div>
</template>

<script>
import { timelineUnVote } from "@/components/MapPage/charts/timeline_unvote";

export default {
  name: "UnVoteView",
  mounted() {
    timelineUnVote();
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/dark.scss";
</style>
