// import CountryNewsView from "@/components/MapPage/CountryNewsView";
import CountryAnalyticsView from "@/components/MapPage/CountryAnalyticsView";
import CountryOpinionLeadersView from "@/components/MapPage/CountryOpinionLeadersView";
// import CountryPulseView from "@/components/MapPage/CountryPulseView";
import { countryAnalyticsRoutes } from "@/router/country-analytics";

export const countryDataRoutes = [
  // {
  //   path: "news",
  //   name: "Country.News",
  //   component: CountryNewsView,
  //   props: true,
  //   meta: {
  //     title: "Новости",
  //     renderInMenu: true,
  //   },
  // },
  {
    path: "analytics",
    name: "Country.Analytics",
    component: CountryAnalyticsView,
    props: true,
    meta: {
      title: "Аналитика",
      renderInMenu: true,
    },
    children: countryAnalyticsRoutes,
    redirect: { name: "Country.Analytics.Default" },
  },
  {
    path: "opinion-leaders",
    name: "Country.OpinionLeaders",
    component: CountryOpinionLeadersView,
    props: true,
    meta: {
      title: "Лидеры мнений",
      renderInMenu: true,
    },
  },
  // {
  //   path: "pulse",
  //   name: "Country.Pulse",
  //   component: CountryPulseView,
  //   props: true,
  //   meta: {
  //     title: "Пульс",
  //     renderInMenu: true,
  //   },
  // },
];
