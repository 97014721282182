<template>
  <DropdownSlot v-model:is-open="isOpen">
    <template #trigger>
      <DropButton
        :is-open="isOpen"
        :value="value"
        :water-mark="waterMark"
        class="dropdown-btn-md"
      >
      </DropButton>
    </template>
    <SelectorList
      class="selector-default"
      :options="options"
      @update:value="setValue"
    />
  </DropdownSlot>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import SelectorList from "@/components/MapPage/common/SelectorList";
import DropdownSlot from "@/components/MapPage/common/DropdownSlot";
import DropButton from "@/components/MapPage/common/DropButton";

defineProps({
  options: { type: Array, required: true },
  value: { type: String, required: true },
  waterMark: { type: String, required: false, default: "Выбрать" },
});

const emits = defineEmits(["update:value", "label", "value"]);

function setValue(value) {
  emits("update:value", value.label);
  emits("label", value.value);
  emits("value", value.label);
  openClose();
}

const isOpen = ref(false);

function openClose() {
  isOpen.value = !isOpen.value;
}
</script>

<style scoped lang="scss"></style>
