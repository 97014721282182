import { defineStore } from "pinia";
import Crud from "@/store/NewsFeed/crud";
import environment from "@/environment";
import { useNewsFeedStore } from "@/store/NewsFeed/newsFeed.js";
import axios from "axios";

const crud = new Crud(environment.appConfig.baseApiUrl + `Card`, "id", "", {});

export const useCardsStore = defineStore({
  id: "cards",
  state: () => ({
    ...crud.state,
  }),
  getters: {
    ...crud.getters,
  },
  actions: {
    ...crud.actions,

    async updateImportance(imp) {
      const newsFeedStore = useNewsFeedStore();
      const url = `${this.endpoint}/importance/${newsFeedStore.currentNews.id}/${imp}`;
      return await axios.patch(url);
    },
  },
});
