import { calcViewport } from "@/tools/news-tools";

export const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");
  // console.log(tooltipEl);
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.classList.add("custom-tooltip");
    const table = document.createElement("table");
    table.classList.add("custom-tooltip-table");
    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  return tooltipEl;
};

export const tooltipChart = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.dataPoints;
    const tableHead = document.createElement("thead");
    tableHead.classList.add("custom-tooltip-thead");
    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 1;

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    tableBody.classList.add("custom-tooltip-tableBody");

    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];
      const span = document.createElement("span");
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.classList.add("custom-tooltip-box");

      const spanText = document.createElement("span");
      spanText.classList.add("custom-tooltip-text");
      if (body.dataset.label !== "") {
        spanText.innerText = body.dataset.label;
      } else {
        spanText.innerText = body.label;
      }
      const spanValue = document.createElement("span");
      spanValue.innerText = body.formattedValue;
      spanValue.classList.add("custom-tooltip-value");

      tableBody.appendChild(span);
      tableBody.appendChild(spanText);
      tableBody.appendChild(spanValue);
    });
    // const footerText = document.createElement("span");
    // footerText.classList.add("custom-tooltip-text");
    // footerText.innerText = `Общий оборот`;
    // tableBody.appendChild(footerText);

    // const footerValue = document.createElement("span");
    // footerValue.classList.add("custom-tooltip-value");
    // footerValue.innerText = "$ 999";
    // tableBody.appendChild(footerValue);

    const tableRoot = tooltipEl.querySelector("table");
    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }
    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
};

export function tooltipPosition(elements, eventPosition) {
  const tooltipWidth =
    document.getElementsByClassName("custom-tooltip")[0]?.offsetWidth;
  const tooltipHeight =
    document.getElementsByClassName("custom-tooltip")[0]?.offsetHeight;
  return {
    x: eventPosition.x + tooltipWidth / 2 - 11,
    y: eventPosition.y - tooltipHeight - 18,
  };
}

export const chartStyle = {
  title: {
    display: true,
    position: "top",
    align: "start",
    // padding: {
    //   bottom: calcViewport(30),
    // },
    color: "#000000",
    font: {
      weight: 700,
      size: calcViewport(16),
    },
  },
  barDoubleAxes: {
    x: {
      display: false,
    },
    y: {
      display: true,
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      tick: {
        color: "#000000",
      },
    },
  },
  barY1Axes: {
    display: true,
    grid: {
      display: false,
    },
    position: "right",
    border: {
      display: false,
    },
  },
  legendBottomBox: {
    position: "bottom",
    labels: {
      boxWidth: calcViewport(10),
      boxHeight: calcViewport(10),
      padding: calcViewport(8),
      useBorderRadius: true,
      borderRadius: calcViewport(2),
      color: "#373D3F",
      font: {
        size: calcViewport(10),
        family: "Roboto",
      },
    },
  },
};
