<template>
  <div class="chart-w d-block w-100">
    <apexchart
      :options="chartOptions" 
      :series="series" 
      :height="stylesChart.heightBarChart"
    />
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useCountryStatMessengersStore } from "@/store/country-stas-messendgers";
import { stylesChart } from '@/tools/tooltipChart'
import VueApexCharts from "vue3-apexcharts";
import useBarChart from '@/mixins/apexChart/barChart'

export default {
  name: "MessengersStatBar",
  components: { apexchart: VueApexCharts, },
  setup() {
    const statStorage = useCountryStatMessengersStore();

    onMounted(async () => {
      await statStorage.loadStat();
    });

    const categories = computed(() => {
      if (!statStorage.items) return [];
      const obj = statStorage.items[0];
      const res = [];
      for (let key in obj) {
        res.push(key);
      }
      return res;
    });

    const series = computed(() => {
      if (!statStorage.items) return [];
      const obj = statStorage.items[0];
      const res = [];
      for (let key in obj) {
        res.push(obj[key].total);
      }
      return [{
        name: '',
        data: res
      }];
    });

    const { chartOptions } = useBarChart(
      {
        titleText: 'Статистика по мессенджерам',
      },
      categories,
      series,
    )

    return { chartOptions, series, stylesChart};
  },
};
</script>

<style scoped></style>
