<script setup>
import { useNotificationsStore } from "@/store/NewsFeed/notifications.js";
import { defineProps } from "vue";

const props = defineProps({
  data: Object,
});

const notificationStore = useNotificationsStore();

const deleteItem = () => {
  notificationStore.delItem(props.id);
  clearTimeout(timerId);
};

const timerId = setTimeout(() => {
  notificationStore.delItem(props.id);
}, 3000);
</script>
<template>
  <li class="notification__item shadow br">
    <div class="notification__header">
      <h3 class="notification__title" :class="props.data.type">
        {{ props.data.title }}
      </h3>
      <span @click="deleteItem" class="notification__close icon-cross cursor" />
    </div>
    <p v-if="props.data.text" class="notification__text">
      {{ props.data.text }}
    </p>
  </li>
</template>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.notification {
  &__item {
    background: $white;
    padding: 1.389vw;
    width: 20.486vw;
    max-height: 200px;
    overflow: hidden;

    &:not(:last-child) {
      margin: 0px 0px 1.389vw 0px;
    }
  }

  &__header {
    display: flex;
    margin: 0px 0px 1.25vw 0px;
  }

  &__title {
    flex: 1 1 auto;
    font-size: 1.111vw;
    font-weight: 500;
    line-height: 1.597vw;

    &.error {
      color: $orange;
    }

    &.info {
      color: $blue-dark;
    }

    &.success {
      color: green;
    }

    &.warning {
      color: rgb(167, 167, 52);
    }
  }

  &__close {
    font-size: 1.111vw;
    flex: 0 0 3.472vw;
    color: $grey;
    text-align: right;
    align-self: flex-start;
  }

  &__text {
    color: $grey-dark;
    line-height: 120%;
  }
}
</style>
