<script setup>
// import InputSearch from "@/components/Chunks/InputSearch.vue";
// import CustomCheckbox from "@/components/Chunks/CustomCheckbox.vue";
import { onMounted, ref } from "vue";
import { useNewsFeedStore } from '@/store/NewsFeed/newsFeed'
import { defineProps } from "vue";

const props = defineProps([
  'item',
  'index'
]);

function checkString(string, substring) {
  return string.toLowerCase().includes(substring.toLowerCase())
}

const isOpenList = ref(false);

const store = useNewsFeedStore()

function checkInput() {
  // store.$state.filter[props.index].inputValue = ''
  store.clearNewsFeed()
  store.getRubricNews()
  console.log(store.$state.filter[0].filterList)
}

onMounted(() => {
  console.log(props.item)
})
</script>

<template>
  <div class="settings-item">
    <div
      @click="isOpenList = !isOpenList"
      class="settings-item__header header-settings"
      :class="{ active: isOpenList }"
    >
      <h2 class="header-settings__title">{{ props.item.title }}</h2>
      <span
        :class="{ active: isOpenList }"
        class="header-settings__icon icon-new-arrow"
      />
    </div>
    <label
      class="search"
      v-if="isOpenList"
    >
      <input
        type="text"
        class="search__inp br"
        placeholder="Поиск"
        v-model="store.$state.filter[props.index].inputValue"
      />
      <span class="search__icon icon-search"></span>
    </label>
    <ul
      v-if="isOpenList"
      class="settings-item__list scroll scroll_bg-light"
    >
      <li
        class="settings-item__item"
        v-for="(listItem, i) in props.item.list"
        :key="i"
        v-show="checkString(listItem, store.$state.filter[props.index].inputValue)"
      >
        <input
          @change="checkInput"
          type="checkbox"
          class="inp"
          :id="listItem"
          :name="listItem"
          :value="listItem"
          v-model="store.$state.filter[props.index].filterList"
        />
        <label :for="listItem">
          <span class="checkbox"></span>
          <p>{{ listItem }}</p>
        </label>
      </li>
    </ul>
  </div>
</template>

<style
  scoped
  lang="scss"
>
@import "@/assets/style/variables.scss";

.settings-item {
  &__header {
    cursor: pointer;

    &.active {
      color: $blue-main;
    }
  }

  &__search {
    height: 36px;
    margin: 10px 0;
  }

  &__list {
    max-height: 152px; //check
    padding-right: 10px;
    border-bottom: 1px solid $grey-light;
    scrollbar-gutter: stable;
    margin-top: 10px;
  }

  &__item {
    &:deep(.item-source__text) {
      order: 1;
    }

    .inp {
      display: none;
    }



    label {
      display: flex;
      gap: 10px;
      align-items: center;
      min-height: 38px;
      padding-left: 14px;
      position: relative;
      cursor: pointer;
      transition: all .2s ease-in-out;

      &:hover {
        background: $blue-light;
        border-radius: 8px;
        border: none;
      }

    }

    input:checked+label {
      span {
        border: none;
        color: $white;
        background: $blue-main;
        font-size: 7px;

        &::after {
          font-family: "icomoon", sans-serif !important;
          content: "\e903";
          line-height: 0;
          position: relative;
        }
      }

      p {
        color: $blue-main;
      }

    }

    .checkbox {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      border: 1px solid #d9d9d9;
      border-radius: 50%;
      background: $white;
      flex: 0 0 16px;
    }
  }
}

.header-settings {
  display: flex;
  align-items: center;
  color: $dirty;
  min-height: 30px;

  &__title {
    flex: 1 1 auto;
    font-weight: 500;
  }

  &__icon {
    font-size: 5px;
    flex: 0 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease 0s;

    &.active {
      transform-origin: center;
      transform: rotate(180deg);
    }
  }
}

.search {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;

  &__inp {
    height: 100%;
    width: 100%;
    background: inherit;
    padding: 0 44px 0 10px;
    background: $grey-light;
    outline: 1px solid transparent;
    transition: outline 0.3s ease 0s;

    &:focus {
      outline: 1px solid $blue-main;
      background: $white;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    font-size: 14px;
    color: $grey;
    cursor: pointer;
  }
}
</style>
