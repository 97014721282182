import { defineStore } from "pinia";

import environment from "@/environment";
import Crud from "@/store/crud";
import CountryData from "@/store/country-data";
import { useLK } from "@/controllerLK/LKstore";

const crud = new Crud(
  environment.appConfig.baseApiUrl + "Avl/persons/:projectId",
  "id",
  "",
  {}
);
const countryData = new CountryData();

export const useCountryPersonsStore = defineStore({
  id: "countryPersons",
  state: () => ({
    ...crud.state,
    ...countryData.state,
    selectedPersonId: null,
  }),
  getters: {
    ...crud.getters,
    ...countryData.getters,
  },
  actions: {
    ...crud.actions,
    ...countryData.actions,
    async loadCountryPersons() {
      this.selectedPersonId = null;
      const lk = useLK();
      const res = await this.loadCountryData({ countryCode: lk.project.id });
      await this.setSelectedPersonId(
        this.items?.length ? this.items[0].id : null
      );
      return res;
    },
    async setSelectedPersonId(personId) {
      console.log("setSelectedPersonId", personId);
      this.selectedPersonId = personId;
    },
  },
});
