<script setup>
import SettingsItem from "@/components/NewsPage/NewsPageAside/NewsPageSettings/SettingsItem.vue";
import { useNewsFeedStore } from '@/store/NewsFeed/newsFeed'
import { onMounted } from "vue";

const store = useNewsFeedStore()
onMounted(() => {
  store.getCities()
})
</script>
<template>
  <aside class="container-aside scroll">
    <div class="container-aside__wrapper br">
      <SettingsItem
        class="container-aside__item"
        v-for="(item, i) in store.$state.filter"
        :key="i"
        :item="item"
        :index="i"
      />
    </div>
  </aside>
</template>
<style
  lang="scss"
  scoped
>
@import "@/assets/style/variables.scss";

.container-aside {
  padding: 0 5px 0 0;
  scrollbar-gutter: stable;

  &__wrapper {
    background: $white;
    padding: 20px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    height: 100%;
  }
}
</style>
