<template>
  <div class="country-data">
    <section class="breadcrumb-panel">
      <bread-crumbs>
        <country-menu />
        <country-analytics-nav
          :selected-id="selectedId"
          v-if="showAnalyticsNav"
        />
      </bread-crumbs>
    </section>
    <section>
      <div class="text-center">
        <router-view></router-view>
      </div>
    </section>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/MapPage/shared/BreadCrumbs";
import CountryMenu from "@/components/MapPage/shared/CountryMenu";
import useCountryPropsHelper from "@/composition/country-props-helper";
import CountryAnalyticsNav from "@/components/MapPage/CountryAnalyticsView/CountryAnalyticsNav";
import { computed, ref } from "vue";
import { useLK } from "@/controllerLK/LKstore";
import router from "@/router/index";

export default {
  name: "CountryDataView",
  components: { CountryMenu, BreadCrumbs, CountryAnalyticsNav },
  setup() {
    const LKStore = useLK();
    const countryCode = LKStore.project.countryId;
    const countryPropsHelper = useCountryPropsHelper({
      countryCode: countryCode,
    });
    const selectedId = ref(1);
    const showAnalyticsNav = computed(() => {
      return router.currentRoute.value.fullPath.includes(
        "country-data/analytics"
      );
    });
    return {
      ...countryPropsHelper,
      countryCode,
      selectedId,
      router,
      showAnalyticsNav,
    };
  },
};
</script>

<style lang="scss" scoped>
.country-data {
  background-color: #eef1f4;
  min-height: calc(100vh - 80px);
  height: 100%;
  padding: 18px 8px 0 8px;
}

.breadcrumb-panel {
  background: #fff;
  border-radius: 8px;
}
</style>
