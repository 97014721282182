<template>
  <left-nav-view
    :data-source="dataSource"
    :selectedId="selectedId"
    @item-click="itemClickHandler"
  />
</template>

<script>
import LeftNavView from "@/components/MapPage/shared/LeftNavView";
import { computed } from "vue";
import { countryAnalyticsRoutes } from "@/router/country-analytics";
import { useRoute, useRouter } from "vue-router/dist/vue-router";

export default {
  name: "CountryAnalyticsNav",
  props: ["selectedId"],
  components: { LeftNavView },

  setup() {
    const router = useRouter();
    const activeRoute = useRoute();

    const dataSource = computed(() => {
      return countryAnalyticsRoutes
        .filter((route) => route.meta?.renderInMenu)
        .map((route) => ({
          id: route.name,
          path: route.path,
          name: route.meta.title,
          active: route.name === activeRoute.name,
        }));
    });

    function itemClickHandler(routeName) {
      console.log("item clicked", routeName);
      router.push({ name: routeName });
    }

    return { dataSource, itemClickHandler };
  },
};
</script>

<style scoped></style>
