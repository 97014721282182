import { defineStore } from "pinia";
import Crud from "@/store/NewsFeed/crud";
import environment from "@/environment";
import axios from "axios";
import { useRubricsStore } from "@/store/NewsFeed/rubrics";
import { useSettingsStore } from "@/store/NewsFeed/settings";
// import { useCountriesStore } from "@/store/NewsFeed/countries";
import { useLK } from "@/controllerLK/LKstore.js";
const crud = new Crud(
  environment.appConfig.baseApiUrl + "Rubrics",
  "id",
  "",
  {}
);



export const useNewsFeedStore = defineStore({
  id: "newsFeed",
  state: () => ({
    ...crud.state,
    currentNews: null,
    offset: 0,
    take: 20,
    currentImportance: 0,
    isLastNews: false,
    isMarked: false,
    filter: [
      {
        title: 'Регионы',
        inputValue: '',
        list: [

        ],
        filterList: [

        ]
      },
      {
        title: 'Тип источника',
        inputValue: '',
        list: [
          'Социальные сети',
          'СМИ',
          'Мессенджеры',
          'Видео',

        ],
        filterList: [

        ]
      },

    ]
    // cityTagsList: [],
  }),
  getters: {
    ...crud.getters,
    getFiltredItems(state) {
      // return state.items;
      if (!state.items?.length) return;
      if (state.currentImportance === 0) {
        return state.items;
      }
      return (state.items || []).filter(
        (el) => el.importance === state.currentImportance
      );
    },
  },
  actions: {
    ...crud.actions,
    async getRubricNews() {
      if (this.isLastNews) return;
      this.loading = true;
      this.currentNews = null;
      try {
        this.items = []
        const { data } = await this.getDataForNews();
        if (!Array.isArray(this.items)) this.items = [];        
        this.items.push(...data);
        if (this.filter[0].list.length === 0) {
          this.getCities()
        }
        this.currentNews = data[0];
        this.offset += this.take;
        if (data.length < this.take) {
          this.isLastNews = true;
        }
      } catch (err) {
        this.setError(err);
      } finally {
        this.loading = false;
      }
    },
    async getCities() {
      const LKstore = useLK()

      await axios.get(environment.appConfig.baseApiUrl + `Country/cities/${LKstore.project.countryId}`).then(res => {
        this.filter[0].list = res.data
      })
    },
    clearNewsFeed() {
      this.isLastNews = false;
      this.items = [];
      this.currentNews = null;
      this.offset = 0;
    },
    async getDataForNews() {
      const settingsStore = useSettingsStore();
      const rubricsStore = useRubricsStore();
      const rubricID = rubricsStore.currentRubric.id;
      const mainURL = "/globus/api/Rubrics/news";
      const url = `${mainURL}/${rubricID}/${this.offset}/${this.take}/${this.currentImportance}`;
      return await axios.post(url, this.filter[0].filterList, {
        params: {
          is_cities: settingsStore.isAllCities,
          is_marked: this.isMarked,

        },
      });
    },
  },
});
//
