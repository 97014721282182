import {defineStore} from "pinia";
import {useNewsFeedStore} from "@/store/NewsFeed/newsFeed";
import {useLK} from "@/controllerLK/LKstore";
import {storageSetItem, storageGetItem} from "@/tools/functions";

export const useSettingsStore = defineStore({
  id: "settings",
  state: () => ({
    cities: [],
    isAllCities: false,
  }),
  getters: {},
  actions: {
    async saveSettings() {
      const LKStore = useLK();
      const newsFeedStore = useNewsFeedStore();
      storageSetItem('settings=' + LKStore.project.id, this.$state)
      newsFeedStore.clearNewsFeed();
      await newsFeedStore.getRubricNews();
    },
    loadSettings() {
      const LKStore = useLK();
      if (storageGetItem('settings=' + LKStore.project.id)) {
        this.$state = storageGetItem('settings=' + LKStore.project.id);
      } else {
        this.cleanLocalSettings()
      }
    },
    cleanLocalSettings() {
      this.cities = [];
      this.isAllCities = false;
    }
  },
});
