<template>
  <news-item-detail :news-item="newsItem" />
</template>

<script>
import { useCountryPersonNewsStore } from "@/store/country-person-news";
import { computed } from "vue";
import NewsItemDetail from "@/components/MapPage/shared/NewsItemDetail";

export default {
  name: "CountryOpinionLeaderNews",
  components: { NewsItemDetail },
  setup() {
    const newsStore = useCountryPersonNewsStore();
    const newsItem = computed(() => {
      const selectedId = newsStore.selectedNewsId;
      if (!selectedId || !newsStore.items) {
        return null;
      }
      return newsStore.items.find((item) => item.id === selectedId);
    });

    return {
      newsItem,
    };
  },
};
</script>

<style lang="scss" scoped></style>
