<template>
  <button
    class="importance-button"
    :style="{ backgroundColor: importanceColor, color: colorText }"
  >
    {{ importanceText }}
  </button>
</template>

<script>
import { computed } from "vue";
import {
  getImportanceButtonColor,
  getNewsImportance,
} from "@/tools/news-tools";

export default {
  name: "NewsTypeButton",
  props: ["importance"],
  setup(props) {
    const importanceColor = computed(() => {
      return getImportanceButtonColor(props.importance);
    });
    const importanceText = computed(() => {
      return getNewsImportance(props.importance).name;
    });
    const colorText = computed(() => {
      return props.importance === 1 || props.importance === 1.5
        ? "black"
        : null;
    });
    return { importanceColor, importanceText, colorText };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars.scss";
.importance-button {
  color: white;
  border-radius: 16px;
  font-family: $font-roboto-regular;
  font-size: 12px;
  background-color: white;
  width: 100px;
  border: 1px solid silver;
  height: 22px;
}
</style>
